import React, { Component } from 'react'
import goal_blue from '../../svg/goal_blue.svg'
import goal_orange from '../../svg/goal_orange.svg'
import chore_blue from '../../svg/chore_blue.svg'
import chore_orange from '../../svg/chore_orange.svg'
import budget_blue from '../../svg/budget_blue.svg'
import budget_orange from '../../svg/budget_orange.svg'
import transfer from '../../svg/transfer.svg'
import profile_pic from '../../svg/profile_pic.svg'
import chore from '../../svg/chore.svg'
import goal from '../../svg/goal.svg'
import ChildCard from '../dashboard/child-card'
import TransferChild from '../modals/parent/transfer-child'
import Chores from '../modals/parent/chores/chores'
import Goals from '../modals/parent/goals/goals'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Auth } from 'aws-amplify'

class ChildrenCards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showChildCard: false,
            transferToChildModal: false,
            showChoresModal: false,
            showGoalsModal: false,
            childInfo: {},
            childrenList: [],
            name: ""
        }
    }

    componentDidMount() {
        Auth.currentUserInfo().then((data) => {
            this.setState({
                name: data.attributes.name
            })
        })
    }

    onOpenChildCard = (childInfo) => {
        this.setState({
            childInfo: childInfo,
            showChildCard: true
        })
    }

    // Transfer modal
    onTransferToChild = () => {
        this.setState({ transferToChildModal: true })
    }

    // Chores modal
    onShowChores = () => {
        this.setState({ showChoresModal: true })
    }

    // Goals modal
    onShowGoals = () => {
        this.setState({ showGoalsModal: true })
    }

    handleCloseModal = () => {
        this.setState({
            transferToChildModal: false,
            showChoresModal: false,
            showGoalsModal: false
        })
    }

    render() {
        const { t } = this.props
        const now = new Date()
        const hrs = now.getHours()
        let childrenList = this.props.childrenList

        return (
            <>
                {
                    this.state.transferToChildModal ?
                        <TransferChild
                            childrenList={childrenList}
                            t={t}
                            handleCloseModal={this.handleCloseModal}
                            transferToChildModal={this.state.transferToChildModal}
                        /> : null
                }
                {
                    this.state.showChoresModal ?
                        <Chores
                            childrenList={childrenList}
                            t={t}
                            handleCloseModal={this.handleCloseModal}
                            showChoresModal={this.state.showChoresModal}
                        /> : null
                }
                {
                    this.state.showGoalsModal ?
                        <Goals
                            childrenList={childrenList}
                            t={t}
                            handleCloseModal={this.handleCloseModal}
                            showGoalsModal={this.state.showGoalsModal}
                        /> : null
                }
                {!this.state.showChildCard ?
                    <>
                        <div className="yg-greeting-parent pb-0 mt-2">
                            <div className="yg-greeting-parent-name">
                                {
                                    hrs >= 6 && hrs < 12 ? <span>{t('greeting_morning')}</span> :
                                        hrs >= 12 && hrs < 17 ? <span>{t('greeting_afternoon')}</span> :
                                            hrs >= 17 && hrs < 22 ? <span>{t('greeting_evening')}</span> :
                                                hrs >= 22 ? <span>{t('greeting_night')}</span> :
                                                    <span>{t('greeting_welcome')}</span>
                                }
                                <br />{this.state.name}!
                            </div>
                            <div className="yg-greeting-parent-balance">
                                <span className="yg-greeting-parent-balance-name">{t('total_balance')}</span>
                                <div>
                                    <span className="yg-card-alwn-costs-q">£</span>
                                    <span className="yg-greeting-parent-balance-number">{this.props.bankInfo?.available}</span>
                                </div>
                            </div>
                        </div>
                        <div className="yg-parentdb-buttons">
                            <button type="button" className="btn  yg-parentdb-btn" onClick={this.onTransferToChild}>
                                <img src={transfer} alt="Transfer" />&nbsp;{t('transfer')}
                            </button>
                            <button type="button" className="btn  yg-parentdb-btn" onClick={this.onShowChores}>
                                <img src={chore} alt="Chore" />&nbsp;{t('add_chore')}
                            </button>
                            <button type="button" className="btn  yg-parentdb-btn" onClick={this.onShowGoals}>
                                <img src={goal} alt="Goal" />&nbsp;{t('add_goal')}
                            </button>
                        </div>
                        <div className="yg-cards pt-2">
                            {
                                childrenList?.map((i, k) => {
                                    return (
                                        <>
                                            {
                                                !i.bankConnection ?
                                                    <div key={k} className="col card yg-card-gradient">
                                                        <button onClick={() => this.onOpenChildCard(i)} className="yg-childcard" disabled>
                                                            <div className="yg-card-head-gradient">
                                                                <div>
                                                                    <img src={i.picture === undefined ? profile_pic : i.picture} alt="Avatar" className="yg-card-avatar" />
                                                                    <span className="card-header yg-card-header">{i.name}</span>
                                                                </div>
                                                                <span className="yg-headline-label-light-s-black pt-4">{t('setup_required')}</span>
                                                            </div>
                                                        </button>
                                                    </div> :
                                                    <div key={k} className={i.color === "1" ? "col card yg-card-blue" : "col card yg-card-orange"}>
                                                        <button onClick={() => this.onOpenChildCard(i)} className="yg-childcard">
                                                            <div className="yg-card-head-main">
                                                                <div>
                                                                    <img src={i.picture === undefined ? profile_pic : i.picture} alt="Avatar" className="yg-card-avatar" />
                                                                    <span className="card-header yg-card-header">{i.name}</span>
                                                                </div>
                                                                <div className="yg-balance-header">
                                                                    <span className="yg-headline-label-light-s-black">{t('balance')}</span>
                                                                    <div>
                                                                        <span className="yg-card-alwn-costs-q">£</span>
                                                                        {i.color === "1" ?
                                                                            <span className="yg-card-number-blue">{/*i.balance === undefined ? 0 : i.balance*/}0</span>
                                                                            : <span className="yg-card-number-orange">0</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={i.color === "1" ? "yg-card-allowance-blue" : "yg-card-allowance-orange"}>
                                                                <div className="yg-balance-header-left">
                                                                    <span className="yg-headline-label-light-s-black">{t('next_allowance')}</span>
                                                                    <div>
                                                                        <span className="yg-card-number-small">{i.allowance?.remainingDay}</span>
                                                                        <span className="yg-card-alwn-days">{t('days_later')}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="yg-balance-header">
                                                                    <span className="yg-headline-label-light-s-black">{i.allowance?.period === 1 ? "One Time" : i.allowance?.period === 2 ? "Daily" : "Weekly"}</span>
                                                                    <div>
                                                                        <span className="yg-card-alwn-costs-q-small">£</span>
                                                                        <span className="yg-card-number-small">{i.allowance?.amount}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={i.color === "1" ? "yg-cc-progressbars-blue" : "yg-cc-progressbars-orange"}>
                                                                <div className="yg-cc-pb-icon">
                                                                    {i.color === "1" ? <img src={budget_blue} className="yg-cc-icon" alt="Budget" /> : <img src={budget_orange} className="yg-cc-icon" alt="Budget" />}
                                                                </div>
                                                                <div className="yg-cc-pb">
                                                                    <div className="yg-cc-pb-top">
                                                                        <span className="yg-headline-label-light-s-black">{t('daily_budget')}</span>
                                                                        <div>
                                                                            <span className="yg-card-alwn-costs-q-small">£</span>
                                                                            <span className="yg-card-number-small">{i.dailyBudget?.expensesAmount}/{i.dailyBudget?.maxBudget}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className={i.color === "1" ? "progress yg-pb-blue" : "progress yg-pb-orange"}>
                                                                        <div
                                                                            className={i.color === "1" ? "progress-bar yg-pbb-blue" : "progress-bar yg-pbb-orange"}
                                                                            role="progressbar"
                                                                            style={{ width: ((i.dailyBudget?.expensesAmount) / (i.dailyBudget?.maxBudget)).toString() + "%" }}
                                                                            aria-valuenow="25"
                                                                            aria-valuemin="0"
                                                                            aria-valuemax="100" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={i.color === "1" ? "yg-cc-progressbars-blue" : "yg-cc-progressbars-orange"}>
                                                                <div className="yg-cc-pb-icon">
                                                                    {i.color === "1" ? <img src={chore_blue} className="yg-cc-icon" alt="Chore" /> : <img src={chore_orange} className="yg-cc-icon" alt="Chore" />}
                                                                </div>
                                                                <div className="yg-cc-pb">
                                                                    <div className="yg-cc-pb-top">
                                                                        <span className="yg-headline-label-light-s-black">{t('chores')}</span>
                                                                        <div>
                                                                            <span className="yg-card-alwn-costs-q-small">£</span>
                                                                            <span className="yg-card-number-small">{i.chores?.completedChoresAmount}/{i.chores?.totalChoresAmount}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className={i.color === "1" ? "progress yg-pb-blue" : "progress yg-pb-orange"}>
                                                                        <div
                                                                            className={i.color === "1" ? "progress-bar yg-pbb-blue" : "progress-bar yg-pbb-orange"}
                                                                            role="progressbar"
                                                                            style={{ width: ((i.chores?.completedChoresAmount) / (i.chores?.totalChoresAmount)).toString() + "%" }}
                                                                            aria-valuenow="25"
                                                                            aria-valuemin="0"
                                                                            aria-valuemax="100" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={i.color === "1" ? "yg-cc-progressbars-blue-nb" : "yg-cc-progressbars-orange-nb"}>
                                                                <div className="yg-cc-pb-icon">
                                                                    {i.color === "1" ? <img src={goal_blue} className="yg-cc-icon" alt="Goal" /> : <img src={goal_orange} className="yg-cc-icon" alt="Goal" />}
                                                                </div>
                                                                <div className="yg-cc-pb">
                                                                    <div className="yg-cc-pb-top">
                                                                        <span className="yg-headline-label-light-s-black">{t('goals')}</span>
                                                                        <div>
                                                                            <span className="yg-card-alwn-costs-q-small">£</span>
                                                                            <span className="yg-card-number-small">{i.goals?.totalGoalsBalance}/{i.goals?.totalGoalsTarget}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className={i.color === "1" ? "progress yg-pb-blue" : "progress yg-pb-orange"}>
                                                                        <div
                                                                            className={i.color === "1" ? "progress-bar yg-pbb-blue" : "progress-bar yg-pbb-orange"}
                                                                            role="progressbar"
                                                                            style={{ width: ((i.goals?.totalGoalsBalance) / (i.goals?.totalGoalsTarget)).toString() + "%" }}
                                                                            aria-valuenow="25"
                                                                            aria-valuemin="0"
                                                                            aria-valuemax="100" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </div>
                                            }
                                        </>
                                    )
                                })}
                        </div>
                    </> :
                    <ChildCard
                        t={t}
                        childInfo={this.state.childInfo}
                        data={this.props}
                    />
                }
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        parent: state.parent
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChildrenCards))