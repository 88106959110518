import { call, put } from 'redux-saga/effects';
import BankTypes from '../reducers/BankRedux';
import { Auth } from 'aws-amplify';

const getToken = async () => {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
};

// GET METHODS

export function* connectUrl(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.connectUrl, token);
        if (response.hasOwnProperty('error')) {
            yield put(BankTypes.connectUrlFailure(response))
        } else {
            yield put(BankTypes.connectUrlSuccess(response))
        }
    } catch (error) {
        yield put(BankTypes.connectUrlFailure(error))
    }
}

export function* getAccountBalance(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.getAccountBalance, token);
        if (response.hasOwnProperty('error')) {
            yield put(BankTypes.getAccountBalanceFailure(response))
        } else {
            yield put(BankTypes.getAccountBalanceSuccess(response))
        }
    } catch (error) {
        yield put(BankTypes.getAccountBalanceFailure(error))
    }
}

export function* getAccountsDashboard(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.getAccountsDashboard, token);
        if (response.hasOwnProperty('error')) {
            yield put(BankTypes.getAccountsDashboardFailure(response))
        } else {
            yield put(BankTypes.getAccountsDashboardSuccess(response))
        }
    } catch (error) {
        yield put(BankTypes.getAccountsDashboardFailure(error))
    }
}

// POST METHODS

export function* setAccount(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.setAccount, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(BankTypes.setAccountFailure(response))
        } else {
            yield put(BankTypes.setAccountSuccess(response))
        }
    } catch (error) {
        yield put(BankTypes.setAccountFailure(error))
    }
}

export function* getAccounts(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.getAccounts, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(BankTypes.getAccountsFailure(response))
        } else {
            yield put(BankTypes.getAccountsSuccess(response))
        }
    } catch (error) {
        yield put(BankTypes.getAccountsFailure(error))
    }
}

export function* getAccountTransactions(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.getAccountTransactions, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(BankTypes.getAccountTransactionsFailure(response))
        } else {
            yield put(BankTypes.getAccountTransactionsSuccess(response))
        }
    } catch (error) {
        yield put(BankTypes.getAccountTransactionsFailure(error))
    }
}