import React, { Component } from 'react';
import Modal from 'react-modal';
import cancel from '../../../svg/cancel.svg';
import calendar from '../../../svg/calendar.svg';
import filter_black from '../../../svg/filter_black.svg';

export default class Payments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changeGoalNameValue: false,
            showChildProfile: false,
            childInfo: ""
        }
    }

    onEditGoal = (e) => {
        this.setState({
            editGoalModal: e.target.innerText
        })
    }

    onShowChildProfile = (e) => {
        this.setState({
            showChildProfile: true,
            childInfo: e.target.innerText
        })
        this.props.handleShowChildProfile(true)
    }

    render() {
        const { t } = this.props;
        return (
            <Modal
                isOpen={this.props.showPayments}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                <div className="w-80">
                    <span className="yg-greeting-name">{t('payments')}</span>
                    <div className="yg-btn-form-lets-start pb-4 pt-5">
                        <button
                            type="button"
                            className="btn  yg-btn-thin yg-btn-black mr-5"
                        >
                            <img src={calendar} alt="Calendar" className="expenses-svg-min" />
                            <span className="yg-headline-label-light-xs">6-13 December</span>
                        </button>
                        <button
                            type="button"
                            className="btn  yg-btn-thin yg-btn-white ml-5"
                        >
                            <img src={filter_black} alt="Filter" className="expenses-svg-min" />
                            <span className="yg-headline-label-light-xxs-black">{t('filter')}</span>
                        </button>
                    </div>
                    <div className="pt-5">
                        <span className="yg-card-alwn-costs-q-xs">TODAY</span>
                        {/*
                            this.props.children.map((i, k) => {
                                return (
                                    <div key={k} className="yg-card-head mt-2 py-2">
                                        <div>
                                            <img src={i.avatar} alt="Avatar" className="yg-card-avatar-min" />
                                            <span className="card-header yg-payments-span">{i.name}{t('s_weekly_allowance')}</span>
                                        </div>
                                        <span className="yg-card-number-small-time">09:30</span>
                                        <div>
                                            <span className="yg-card-number-small-time">£</span>
                                            <span className="yg-card-number-min-s">{i.balance}</span>
                                        </div>
                                    </div>
                                )
                            })*/
                        }
                    </div>
                    <div className="pt-4">
                        <span className="yg-card-alwn-costs-q-xs">17 SEPTEMBER</span>
                        {/*
                            this.props.children.map((i, k) => {
                                return (
                                    <div key={k} className="yg-card-head mt-2 py-2">
                                        <div>
                                            <img src={i.avatar} alt="Avatar" className="yg-card-avatar-min" />
                                            <span className="card-header yg-payments-span">{t('extra_allowance_to')}{i.name}</span>
                                        </div>
                                        <span className="yg-card-number-small-time">09:30</span>
                                        <div>
                                            <span className="yg-card-number-small-time">£</span>
                                            <span className="yg-card-number-min-s">{i.balance}</span>
                                        </div>
                                    </div>
                                )
                            })*/
                        }
                    </div>
                </div>
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </Modal>
        );
    }
}