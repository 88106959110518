import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import cancel from '../../../svg/cancel.svg'
import BankActions from '../../../reducers/BankRedux'

class BankAccounts extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getAccountsDashboardRequest()
    }

    render() {
        const { t } = this.props

        return (
            <Modal
                isOpen={this.props.showBankAccounts}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                <div className="w-80">
                    <span className="yg-greeting-name">{t('bank_account')}</span>
                    <div className="yg-btn-form-lets-start pb-4 pt-5">
                        <button
                            type="button"
                            className="btn yg-btn-thin yg-btn-black"
                        >
                            <span className="yg-headline-label-light-xs-white">{t('change_account')}</span>
                        </button>
                    </div>
                    {
                        this.props.bank?.getAccountsDashboardResponse?.data?.data?.accounts.map(i => {
                            return (
                                <div className="yg-gradient bank-accounts">
                                    <span className="yg-headline-label-light-s-black pt-3 pb-5">{i.accountName}</span>
                                    <span className="yg-card-alwn-days">{i.providerName}</span>
                                    <span className="yg-headline-label-light-xs-black pt-2 pb-3">{i.accountIban}</span>
                                </div>
                            )
                        })
                    }
                </div>
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAccountsDashboardRequest: () => dispatch(BankActions.getAccountsDashboardRequest())
    }
}
const mapStateToProps = (state) => {
    return {
        bank: state.bank
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts)