import React, { Component } from 'react'
import { connect } from 'react-redux'
import select_back_black from '../../svg/select_back_black.svg'
import forward from '../../svg/forward.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
const eye = <FontAwesomeIcon icon={faEye} />
const closed_eye = <FontAwesomeIcon icon={faEyeSlash} />

class ParentDashboardNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phone: "",
            password: "",
            passwordShown: false,
            showChangeEmailModal: false
        }
    }

    onNameChange = (event) => {
        this.setState({
            name: event.target.value
        })
    };

    onEmailChange = (event) => {
        this.setState({
            email: event.target.value
        })
    };

    onPhoneChange = (event) => {
        this.setState({
            phone: event.target.value
        })
    };

    handlePasswordChange = (event) => {
        this.setState({
            password: event.target.value
        })
    };

    togglePasswordVisiblity = () => {
        if (this.state.passwordShown) {
            this.setState({
                passwordShown: false
            })
        } else if (!this.state.passwordShown) {
            this.setState({
                passwordShown: true
            })
        }
    };

    onReturnBack = () => {

    }

    render() {
        const { t } = this.props;
        const currentParent = this.props.user?.userData?.attributes
        const avatar = "https://www.whatsappimages.in/wp-content/uploads/2020/12/Cute-Girl-Images-For-Whatsapp-Dp-Free-Download-9.jpg"

        return (
            <div className="yg-row-cards">
                <div className="button-back-div">
                    <button className="yg-select-back-cards" onClick={() => this.onReturnBack()}>
                        <img src={select_back_black} alt="Back" />
                    </button>
                </div>
                <div className="w-40">
                    <span className="yg-greeting-parent-name">{t('profile')}</span>
                    <div className="form-group yg-form-edit-profile pt-4">
                        <div className="edit-profile-form-group">
                            <div>
                                <span className="yg-headline-label-dark">{t('profile_photo')}</span>
                                <div className="image-upload">
                                    <label htmlFor="yg-profile-photo" className="yg-headline-label-dark">
                                        <img src={currentParent.avatar === undefined ? avatar : currentParent.avatar} alt="Profile" className="yg-profile-pic-uploaded" />
                                    </label>
                                    <input
                                        type="file"
                                        ref="file"
                                        className="form-control"
                                        id="yg-profile-photo"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="yg-name" className="yg-headline-label-dark">{t('full_name')}</label>
                                <div className="yg-password-form">
                                    <input
                                        placeholder={t('ellie_smith')}
                                        name="name"
                                        type="text"
                                        className="form-control yg-form-input"
                                        id="yg-name"
                                        value={this.state.name}
                                        onChange={(e) => this.onNameChange(e)}
                                    />
                                    <button className="yg-balance-header goforward-button">
                                        <img src={forward} alt="Forward" className="parent-profile-svg" />
                                    </button>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="yg-email" className="yg-headline-label-dark">{t('email')}</label>
                                <div className="yg-password-form">
                                    <input
                                        placeholder={t('placeholder_email')}
                                        name="email"
                                        type="text"
                                        className="form-control yg-form-input"
                                        id="yg-email"
                                        value={this.state.email}
                                        onChange={(e) => this.onEmailChange(e)}
                                    />
                                    <button className="yg-balance-header goforward-button" onClick={this.onEmailChangeModal}>
                                        <img src={forward} alt="Forward" className="parent-profile-svg" />
                                    </button>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="yg-phone" className="yg-headline-label-dark">{t('phone_number')}</label>
                                <div className="yg-password-form">
                                    <input
                                        placeholder={t('phone_template')}
                                        name="phone"
                                        type="text"
                                        className="form-control yg-form-input"
                                        id="yg-phone"
                                        value={this.state.phone}
                                        onChange={(e) => this.onPhoneChange(e)}
                                    />
                                    <button className="yg-balance-header goforward-button">
                                        <img src={forward} alt="Forward" className="parent-profile-svg" />
                                    </button>
                                </div>
                            </div>
                            <div className="yg-password-form-div">
                                <label htmlFor="yg-password" className="yg-headline-label-dark">{t('password')}</label>
                                <div className="yg-password-form">
                                    <input
                                        placeholder={t('type_your_pass')}
                                        name="password"
                                        type={this.state.passwordShown ? "text" : "password"}
                                        className="form-control yg-form-input"
                                        id="yg-password"
                                        value={this.state.password}
                                        onChange={(e) => this.handlePasswordChange(e)}
                                    />
                                    {
                                        this.state.passwordShown ?
                                            <i onClick={this.togglePasswordVisiblity}>{eye}</i>
                                            : <i onClick={this.togglePasswordVisiblity}>{closed_eye}</i>
                                    }
                                    <button className="yg-balance-header goforward-button pl-3">
                                        <img src={forward} alt="Forward" className="parent-profile-svg" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}
const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ParentDashboardNav)