import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getCodeRequest: ['body'],
    getCodeSuccess: ['data'],
    getCodeFailure: ['error']
});

export const VerificationTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    getCodeResponse: null,
    getCodeError: false,
    getCodeErrorMessage: ''
});

/* ------------- Reducers ------------- */

/* ------------- Get Code ------------- */
export const getCodeRequest = (state, action) => {
    return state.merge({
        getCodeResponse: null,
        getCodeError: false,
        getCodeErrorMessage: ''
    })
}
export const getCodeSuccess = (state, action) => {
    return state.merge({
        getCodeResponse: action.data,
        getCodeError: false,
        getCodeErrorMessage: ''
    })
}
export const getCodeFailure = (state, action) => {
    return state.merge({
        getCodeError: true,
        getCodeErrorMessage: action.error
    })
}

/* ------------- Connection Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_CODE_REQUEST]: getCodeRequest,
    [Types.GET_CODE_SUCCESS]: getCodeSuccess,
    [Types.GET_CODE_FAILURE]: getCodeFailure
})