import React, { Component } from 'react'
import request from '../../svg/request.svg'
import ChildSelfCards from '../dashboard/child-self-cards'
import RequestMoney from '../modals/child/request-money'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import BankActions from '../../reducers/BankRedux'
import ChildActions from '../../reducers/ChildRedux'

class ChildDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            requestMoneyModal: false,
            bankInfo: {}
        }
    }

    onRequestMoney = () => {
        this.setState({
            requestMoneyModal: true
        })
    }

    componentDidMount() {
        this.props.getChildDashboardRequest()
        this.props.getAccountBalanceRequest()
        this.setState({
            bankInfo: this.props.bank.getAccountBalanceResponse?.data?.data?.result,
            childInfo: this.props.child.getChildDashboardResponse?.data?.data
        })
    }

    handleCloseModal = () => {
        this.setState({
            requestMoneyModal: false
        })
    }

    render() {
        const { t } = this.props
        var now = new Date()
        var hrs = now.getHours()
        const currentChild = this.props.user?.userData?.attributes

        return (
            <>
                <RequestMoney
                    child={currentChild}
                    t={t}
                    handleCloseModal={this.handleCloseModal}
                    requestMoneyModal={this.state.requestMoneyModal}
                />
                <div className="yg-row-cards">
                    <div className="yg-greeting-parent">
                        <div className="yg-greeting-parent-name">
                            {
                                hrs >= 6 && hrs < 12 ? <span>{t('greeting_morning')}</span> :
                                    hrs >= 12 && hrs < 17 ? <span>{t('greeting_afternoon')}</span> :
                                        hrs >= 17 && hrs < 22 ? <span>{t('greeting_evening')}</span> :
                                            hrs >= 22 ? <span>{t('greeting_night')}</span> :
                                                <span>{t('greeting_welcome')}</span>
                            }
                            <br />{currentChild?.name}!
                        </div>
                        <div className="yg-greeting-parent-balance">
                            <span className="yg-greeting-parent-balance-name">{t('total_balance')}</span>
                            <div>
                                <span className="yg-card-alwn-costs-q">£</span>
                                <span className="yg-greeting-parent-balance-number">{this.state.bankInfo?.available}</span>
                            </div>
                        </div>
                    </div>
                    <div className="yg-parentdb-buttons">
                        <button type="button" className="btn  yg-parentdb-btn" onClick={this.onRequestMoney}>
                            <img src={request} alt="Request" />&nbsp;{t('request')}
                        </button>
                    </div>
                    <ChildSelfCards child={currentChild} childInfo={this.state.childInfo} t={t} />
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getChildDashboardRequest: () => dispatch(ChildActions.getChildDashboardRequest()),
        getAccountBalanceRequest: () => dispatch(BankActions.getAccountBalanceRequest())
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        bank: state.bank,
        child: state.child
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChildDashboard))