import React, { Component } from 'react'
import Modal from 'react-modal'
import cancel from '../../../../svg/cancel.svg'
import profile_pic from '../../../../svg/profile_pic.svg'
import { connect } from 'react-redux'
import Select from 'react-select'
import GoalsDetails from './goals-details'

class Goals extends Component {
    constructor(props) {
        super(props)
        this.state = {
            childrenList: [],
            childrenOptions: [],
            selectedOption: null,
            editGoalModalIsOpen: false,
            addGoalModalIsOpen: false
        }
    }

    componentDidMount() {
        if (this.props.child === undefined) {
            this.setState({
                childrenList: this.props.childrenList,
                childrenOptions: this.props.childrenList.map(i => ({
                    picture: i.picture,
                    name: i.name,
                    balance: i.balance.available
                }))
            })
        }
    }

    onChangeSelectedChild = (child) => {
        this.setState({ selectedOption: child })
    }

    handleCheckEditGoalModal = (editGoalModalIsOpen) => {
        this.setState({ editGoalModalIsOpen: editGoalModalIsOpen })
    }

    handleCheckAddGoalModal = (addGoalModalIsOpen) => {
        this.setState({ addGoalModalIsOpen: addGoalModalIsOpen })
    }

    render() {
        const { t } = this.props

        const singleChildCard = ({ picture, name, balance }) => (
            <div className="yg-card-head-transfer yg-modal-child mt-2">
                <div>
                    <img src={picture === undefined ? profile_pic : picture} alt="Avatar" className="yg-card-avatar-min" />
                    <span className="yg-card-header pl-3">{name}</span>
                </div>
                <div className="yg-balance-header">
                    <span className="yg-balance-headername-min">{t('balance')}</span>
                    <div>
                        <span className="yg-card-alwn-costs-q-xs">£</span>
                        <span className="yg-card-number-min">{balance === undefined ? 0 : balance}</span>
                    </div>
                </div>
            </div>
        )

        const customStyles = {
            menu: () => ({
                background: 'linear-gradient(90deg, #effaff 35%, #fff1ea 100%)',
                marginTop: '5px',
                border: '1px #E1E2E6 solid',
                paddingBottom: '8px'
            }),
            option: ({ isFocused, isSelected }) => ({
                borderBottom: '1px #E1E2E6 solid',
                cursor: 'pointer',
                marginBottom: '-8px',
                backgroundColor: isFocused
                    ? '#E1E2E6'
                    : undefined
            })
        }

        return (
            <Modal
                isOpen={this.props.showGoalsModal}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                <div className={window.innerWidth < 769 ? "w-90" : "w-80"}>
                    {
                        this.props.child !== undefined ?
                            <GoalsDetails
                                t={t}
                                child={this.props.child}
                                handleCheckEditGoalModal={this.handleCheckEditGoalModal}
                                handleCheckAddGoalModal={this.handleCheckAddGoalModal}
                                handleCloseModal={this.props.handleCloseModal}
                            /> :
                            <>
                                {
                                    this.state.editGoalModalIsOpen || this.state.addGoalModalIsOpen ? null :
                                        <>
                                            <span className="yg-greeting-name">{t('goals')}</span>
                                            <div className="yg-password-form-div pt-5">
                                                <label htmlFor="yg-gender" className="yg-headline-label-light-xs-black">{t('select_child')}</label>
                                                <Select
                                                    defaultValue={this.state.childrenOptions[0]}
                                                    onChange={this.onChangeSelectedChild}
                                                    options={this.state.childrenOptions}
                                                    formatOptionLabel={singleChildCard}
                                                    isSearchable={false}
                                                    styles={customStyles}
                                                />
                                            </div>
                                        </>
                                }
                                <GoalsDetails
                                    t={t}
                                    childrenList={this.state.childrenList}
                                    selectedOption={this.state.selectedOption?.value}
                                    handleCheckEditGoalModal={this.handleCheckEditGoalModal}
                                    handleCheckAddGoalModal={this.handleCheckAddGoalModal}
                                    handleCloseModal={this.props.handleCloseModal}
                                />
                            </>
                    }
                </div>
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}
const mapStateToProps = (state) => {
    return {
        parent: state.parent
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Goals)