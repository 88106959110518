import React, { Component } from 'react';
import Modal from 'react-modal';
import cancel from '../../../svg/cancel.svg';

export default class RequestMoney extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: "5",
            otherValue: 0
        }
    }

    handleCloseModal = () => {
        this.setState({
            setAll: false
        })
    }

    onAmountChange = (e) => {
        this.setState({
            amount: e.target.id
        })
    }

    onOtherChange = (e) => {
        this.setState({
            otherValue: e.target.value
        })
    }

    render() {
        const { t } = this.props;
        let child = this.props.child;

        return (
            <Modal
                isOpen={this.props.requestMoneyModal}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                <div className={window.innerWidth < 769 ? "w-90" : "w-80"}>
                    <span className="yg-greeting-name">{t('request_money')}</span>
                    <div className="pt-5 mt-5">
                        <div className="yg-password-form-div">
                            <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('amount')}</label>
                            <div className={child.color === "Blue" ? "yg-gradient-blue" : "yg-gradient-orange"}>
                                <input type="radio" id="5" name="amount" className={child.color === "Blue" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "5"} onChange={this.onAmountChange} />
                                <label htmlFor="5" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>5</label>
                                <input type="radio" id="10" name="amount" className={child.color === "Blue" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "10"} onChange={this.onAmountChange} />
                                <label htmlFor="10" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>10</label>
                                <input type="radio" id="20" name="amount" className={child.color === "Blue" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "20"} onChange={this.onAmountChange} />
                                <label htmlFor="20" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>20</label>
                                <input type="radio" id="other" name="amount" className={child.color === "Blue" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "other"} onChange={this.onAmountChange} />
                                <label htmlFor="other" className="rb-label-dashboard">{t('other')}</label>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.amount === "other" ?
                            <div className="yg-password-form-div">
                                <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('other_amount')}</label>
                                <div className="yg-radio-form-sa pt-2">
                                    <div className="yg-input-other w-100">
                                        <span className="yg-card-number-small-time">£</span>
                                        <input
                                            type="number"
                                            className={child.color === "Blue" ? "rb-input-text-blue p-10-5" : "rb-input-text-orange p-10-5"}
                                            onChange={this.onOtherChange} />
                                    </div>
                                </div>
                            </div> : null
                    }
                    <div className="yg-btn-form" style={{ display: 'flex' }}>
                        <button
                            type="button"
                            className="btn yg-btn-min yg-btn-black"
                            disabled={this.state.amount === "other" ? (this.state.otherValue <= 0 ? true : false) : false}
                        >
                            {t('request_money')}
                        </button>
                        <button
                            type="button"
                            className="btn  yg-btn-min yg-btn-gray"
                            onClick={this.props.handleCloseModal}
                        >
                            {t('cancel')}
                        </button>
                    </div>
                </div>
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </Modal>
        );
    }
}