import React, { Component } from 'react'
import Modal from 'react-modal'
import cancel from '../../../svg/cancel.svg'
import profile_pic from '../../../svg/profile_pic.svg'
import forward from '../../../svg/forward.png'
import ChangeEmail from '../common/change-email'
import ChangePhone from '../common/change-phone'
import ChangePassword from '../common/change-password'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'
import { Storage } from 'aws-amplify'
import { ToastContainer, toast } from 'react-toastify'

class ChildProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            avatar: {},
            avatarUrl: "",
            newAvatarUploaded: false,
            name: "",
            email: "",
            phone: "",
            password: "",
            birthday: "",
            gender: "male",
            color: "",
            passwordShown: false,
            showChangeEmailModal: false,
            showChangePhoneModal: false,
            showChangePasswordModal: false
        }
    }

    componentDidMount() {
        Storage.list('profile_pictures/')
            .then(result => {
                result.map(i => {
                    if (i.key.includes(this.props.user?.userData?.attributes.sub)) {
                        this.setState({
                            avatar: i
                        })
                    }
                })
                this.onDownloadImage(this.state.avatar)
            })
            .catch(err => console.log(err))

        Auth.currentUserInfo().then((data) => {
            this.setState({
                name: data.attributes.name,
                email: data.attributes.email,
                phone: data.attributes.phone_number,
                birthday: data.attributes.birthdate,
                gender: data.attributes.gender,
                color: data.attributes.color === "1" ? "blue" : "orange"
            })
        })
    }

    onDownloadImage = async (avatar) => {
        await Storage.get(avatar.key).then((data) => {
            this.setState({
                avatarUrl: data
            })
        })
    }

    togglePasswordVisiblity = () => {
        if (this.state.passwordShown) {
            this.setState({
                passwordShown: false
            })
        } else if (!this.state.passwordShown) {
            this.setState({
                passwordShown: true
            })
        }
    }

    onEmailChangeModal = () => {
        this.setState({
            showChangeEmailModal: true
        })
    }

    onPhoneChangeModal = () => {
        this.setState({
            showChangePhoneModal: true
        })
    }

    onPasswordChangeModal = () => {
        this.setState({
            showChangePasswordModal: true
        })
    }

    onGenderChange = (e) => {
        this.setState({
            gender: e.target.id
        })
    }

    onColorChange = (e) => {
        this.setState({
            color: e.target.id
        })
    }

    onUploadAvatar = (e) => {
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0]
            this.setState({
                avatarUrl: URL.createObjectURL(img),
                avatar: img,
                newAvatarUploaded: true
            })
        }
    }

    onUploadImage = async () => {
        await Storage.put('profile_pictures/' + this.props.user?.userData?.attributes.sub + '.'
            + this.state.avatar.name.split('.').pop(), this.state.avatar, {}).then(() => {
                toast.success('Profile photo successfully changed!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.setState({
                    newAvatarUploaded: false
                })
                this.props.handleChangeProfilePhoto(this.state.avatar)
                setTimeout(() => {
                    this.props.handleCloseModal(true)
                }, 1500)
            })
    }

    render() {
        const { t } = this.props
        const currentChild = this.props.user?.userData?.attributes

        return (
            <Modal
                isOpen={this.props.showChildProfile}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                {
                    this.state.showChangeEmailModal ?
                        <ChangeEmail t={t} handleCloseModal={this.props.handleCloseModal} /> :
                        this.state.showChangePhoneModal ?
                            <ChangePhone t={t} handleCloseModal={this.props.handleCloseModal} /> :
                            this.state.showChangePasswordModal ?
                                <ChangePassword t={t} handleCloseModal={this.props.handleCloseModal} /> :
                                <div className={window.innerWidth < 769 ? "w-90" : "w-80"}>
                                    <span className="yg-greeting-name">{t('profile')}</span>
                                    <div className="form-group yg-form-edit-profile pt-5 mt-2">
                                        <div className="edit-profile-form-group">
                                            <div>
                                                <span className="yg-headline-label-dark">{t('profile_photo')}</span>
                                                <div className="image-upload">
                                                    <label htmlFor="yg-profile-photo" className="yg-headline-label-dark">
                                                        <img src={this.state.avatarUrl === undefined ? profile_pic : this.state.avatarUrl} alt="Profile" className="yg-profile-pic-uploaded" />
                                                    </label>
                                                    <input
                                                        type="file"
                                                        ref="file"
                                                        className="form-control"
                                                        id="yg-profile-photo"
                                                        onChange={this.onUploadAvatar}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="yg-name" className="yg-headline-label-dark">{t('your_name')}</label>
                                                <div className="yg-password-form">
                                                    <input
                                                        placeholder={this.state.name}
                                                        disabled
                                                        name="name"
                                                        type="text"
                                                        className="form-control yg-form-input"
                                                        id="yg-name"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="yg-name" className="yg-headline-label-dark">{t('date_of_birth')}</label>
                                                <div className="yg-password-form">
                                                    <input
                                                        placeholder={this.state.birthday}
                                                        disabled
                                                        name="name"
                                                        type="text"
                                                        className="form-control yg-form-input"
                                                        id="yg-name"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('gender')}</label>
                                                <div className="yg-radio-form-sa">
                                                    <div className="yg-gradient">
                                                        <input type="radio" id="male" name="gender" className="rb-input" onChange={this.onGenderChange} checked={this.state.gender === 'male'} />
                                                        <label htmlFor="male" className="rb-label" >{t('male')}</label>
                                                        <input type="radio" id="female" name="gender" className="rb-input" onChange={this.onGenderChange} checked={this.state.gender === 'female'} />
                                                        <label htmlFor="female" className="rb-label" >{t('female')}</label>
                                                        <input type="radio" id="non_binary" name="gender" className="rb-input" onChange={this.onGenderChange} checked={this.state.gender === 'non_binary'} />
                                                        <label htmlFor="non_binary" className="rb-label" >{t('non_binary')}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="yg-color" className="yg-headline-label-dark">{t('color')}</label>
                                                <div className="yg-radio-form-color">
                                                    {
                                                        currentChild.color === "1" ?
                                                            <input type="radio" id="blue" name="color" className="rb-input-color-blue" onChange={this.onColorChange} checked={this.state.color === 'blue'} /> :
                                                            <input type="radio" id="orange" name="color" className="rb-input-color-orange" onChange={this.onColorChange} checked={this.state.color === 'orange'} />
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="yg-email" className="yg-headline-label-dark">{t('email')}</label>
                                                <div className="yg-password-form">
                                                    <input
                                                        placeholder={t('placeholder_email')}
                                                        name="email"
                                                        type="text"
                                                        disabled
                                                        className="form-control yg-form-input"
                                                        id="yg-email"
                                                        value={this.state.email}
                                                    />
                                                    <button className="yg-balance-header goforward-button" onClick={this.onEmailChangeModal}>
                                                        <img src={forward} alt="Forward" className="parent-profile-svg" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="yg-phone" className="yg-headline-label-dark">{t('phone_number')}</label>
                                                <div className="yg-password-form">
                                                    <input
                                                        placeholder={t('phone_template')}
                                                        name="phone"
                                                        type="text"
                                                        disabled
                                                        className="form-control yg-form-input"
                                                        id="yg-phone"
                                                        value={this.state.phone}
                                                    />
                                                    <button className="yg-balance-header goforward-button" onClick={this.onPhoneChangeModal}>
                                                        <img src={forward} alt="Forward" className="parent-profile-svg" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="yg-password-form-div">
                                                <label htmlFor="yg-password" className="yg-headline-label-dark">{t('password')}</label>
                                                <div className="yg-password-form">
                                                    <input
                                                        placeholder={t('type_your_pass')}
                                                        name="password"
                                                        disabled
                                                        type={this.state.passwordShown ? "text" : "password"}
                                                        className="form-control yg-form-input"
                                                        id="yg-password"
                                                        value={this.state.password}
                                                    />
                                                    <button className="yg-balance-header goforward-button pl-3" onClick={this.onPasswordChangeModal}>
                                                        <img src={forward} alt="Forward" className="parent-profile-svg" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn yg-btn yg-btn-black"
                                                    disabled={!this.state.newAvatarUploaded ? true : false}
                                                    onClick={this.onUploadImage}
                                                >
                                                    {t('save')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                }
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
                <ToastContainer
                    position="bottom-left"
                    className="toast-container-mobile"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}
const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChildProfile)