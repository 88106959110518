import React, { Component } from 'react'
import Modal from 'react-modal'
import cancel from '../../../svg/cancel.svg'
import forward from '../../../svg/forward.png'
import EditProfile from './edit-profile'
import DatePicker from 'react-date-picker'
import { connect } from 'react-redux'
import profile_pic from '../../../svg/profile_pic.svg'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
import start from '../../../svg/illustrations/start.svg'
import ParentActions from '../../../reducers/ParentRedux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class ChildrenProfiles extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showChildProfile: false,
            childInfo: "",
            childAdd: false,
            name: '',
            phone: '',
            gender: 'male',
            color: 'blue',
            birthday: new Date(),
            checked: false,
            avatarUrl: [],
            avatar: {},
            avatarUploaded: false,
            count: 0, // Using for avoiding infinite loops in componentDidUpdate
            childrenList: [],
            showChildInfo: false,
            childEmail: "",
            childTemporaryPassword: ""
        }
    }

    componentDidMount() {
        this.props.getChildListRequest()
        this.setState({
            childrenList: this.props.parent?.getChildListResponse?.data?.data?.Items
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.childrenList !== this.props.parent?.getChildListResponse?.data?.data?.Items) {
            this.setState({
                childrenList: this.props.parent?.getChildListResponse?.data?.data?.Items
            })
        }
        if (this.props.parent.addChildResponse !== prevProps.parent.addChildResponse && !this.props.parent.addChildError && this.props.parent.addChildResponse !== null) {
            if (this.state.count === 0) {
                toast.success('Child successfully added!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.setState({ count: 1 })
            }
            this.setState({ count: 0 })
        }
        if (this.props.parent.addChildResponse !== prevProps.parent.addChildResponse && !this.props.parent.addChildError && this.state.showChildInfo) {
            if (this.state.count === 0) {
                this.setState({
                    childEmail: this.props.parent.addChildResponse?.data?.data?.child?.email,
                    childTemporaryPassword: this.props.parent.addChildResponse?.data?.data?.temporaryPassword,
                    count: 1
                })
            }
            this.setState({ count: 0 })
        }
    }

    onEditGoal = (e) => {
        this.setState({ editGoalModal: e.target.innerText })
    }

    onShowChildProfile = (i) => {
        this.setState({
            showChildProfile: true,
            childInfo: i
        })
        this.props.handleShowChildProfile(true)
    }

    onAddChild = () => {
        this.setState({ childAdd: true })
    }

    handlePasswordChange = (e) => {
        this.setState({ password: e.target.value })
    }

    onNameChange = (e) => {
        this.setState({ name: e.target.value })
    }

    onEmailChange = (e) => {
        this.setState({ email: e.target.value })
    }

    onPhoneChange = (e) => {
        this.setState({ phone: e.target.value })
    }

    onGenderChange = (e) => {
        this.setState({ gender: e.target.id })
    }

    onColorChange = (e) => {
        this.setState({ color: e.target.id })
    }

    onUploadAvatar = (e) => {
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0]
            this.setState({
                avatarUrl: URL.createObjectURL(img),
                avatar: img,
                avatarUploaded: true
            })
        }
    }

    onAddChildToCognito = async () => {
        var reader = new FileReader()
        if (this.state.avatarUploaded) {
            reader?.readAsDataURL(this.state.avatar)
            reader.onload = () => {
                this.props.addChildRequest({
                    name: this.state.name,
                    email: this.state.email,
                    phone: this.state.phone,
                    birthdate: "1999.02.10",
                    gender: this.state.gender,
                    color: this.state.color === 'blue' ? 1 : 2,
                    photo: reader.result
                })
                this.setState({ avatarUploaded: false })
                this.showChildsInfo()
            }
        } else {
            this.props.addChildRequest({
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                birthdate: "1999.02.10",
                gender: this.state.gender,
                color: this.state.color === 'blue' ? 1 : 2
            })
            this.showChildsInfo()
        }
    }

    showChildsInfo = () => {
        this.setState({
            showChildInfo: true,
            childAdd: false
        })
    }

    onAddOtherChild = () => {
        this.setState({
            showChildInfo: false,
            childAdd: true
        })
    }

    onChangeBirthday = (e) => {
        this.setState({ birthday: e })
    }

    render() {
        const { t } = this.props

        return (
            <Modal
                isOpen={this.props.showProfiles}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                {
                    this.state.showChildProfile ?
                        <EditProfile
                            t={t}
                            childInfo={this.state.childInfo}
                            handleCloseModal={this.props.handleCloseModal}
                        /> :
                        <div className="w-80">
                            {
                                this.state.childAdd ?
                                    <>
                                        <span className="yg-greeting-parent-name">{t('add_child')}</span>
                                        <div className="form-group yg-form-edit-profile pt-4">
                                            <div className="edit-profile-form-group">
                                                <div>
                                                    <span className="yg-headline-label-light-s-black">{t('profile_photo')}</span>
                                                    <div className="image-upload">
                                                        <label htmlFor="yg-profile-photo" className="yg-headline-label-light-s-black">
                                                            {
                                                                !this.state.avatarUploaded ?
                                                                    <img src={profile_pic} alt="Profile" className="yg-profile-pic" /> :
                                                                    <img src={this.state.avatarUrl} alt="Profile" className="yg-profile-pic-uploaded" />
                                                            }
                                                        </label>
                                                        <input type="file" className="form-control" id="yg-profile-photo" onChange={this.onUploadAvatar} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="yg-name" className="yg-headline-label-light-s-black">{t('childs_name')}</label>
                                                    <div className="yg-password-form">
                                                        <input
                                                            placeholder={t('ellie_smith')}
                                                            name="name"
                                                            type="text"
                                                            className="form-control yg-form-input"
                                                            id="yg-name"
                                                            value={this.state.name}
                                                            onChange={(e) => this.onNameChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="yg-email" className="yg-headline-label-light-s-black">{t('email')}</label>
                                                    <div className="yg-password-form">
                                                        <input
                                                            placeholder={t('placeholder_email')}
                                                            name="email"
                                                            type="text"
                                                            className="form-control yg-form-input"
                                                            id="yg-email"
                                                            value={this.state.email}
                                                            onChange={(e) => this.onEmailChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="yg-gender" className="yg-headline-label-light-s-black">{t('gender')}</label>
                                                    <div className="yg-radio-form-sa">
                                                        <div className="yg-gradient">
                                                            <input type="radio" id="male" name="gender" className="rb-input" onChange={this.onGenderChange} checked={this.state.gender === 'male'} />
                                                            <label htmlFor="male" className="rb-label" >{t('male')}</label>
                                                            <input type="radio" id="female" name="gender" className="rb-input" onChange={this.onGenderChange} checked={this.state.gender === 'female'} />
                                                            <label htmlFor="female" className="rb-label" >{t('female')}</label>
                                                            <input type="radio" id="non_binary" name="gender" className="rb-input" onChange={this.onGenderChange} checked={this.state.gender === 'non_binary'} />
                                                            <label htmlFor="non_binary" className="rb-label" >{t('non_binary')}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="yg-color" className="yg-headline-label-light-s-black">{t('color')}</label>
                                                    <div className="yg-radio-form-color">
                                                        <input type="radio" id="blue" name="color" className="rb-input-color-blue" onChange={this.onColorChange} checked={this.state.color === 'blue'} />
                                                        <input type="radio" id="orange" name="color" className="rb-input-color-orange" onChange={this.onColorChange} checked={this.state.color === 'orange'} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="yg-phone" className="yg-headline-label-light-s-black">{t('phone_number')}</label>
                                                    <div className="yg-password-form">
                                                        <input
                                                            placeholder={t('phone_template')}
                                                            name="phone"
                                                            type="text"
                                                            className="form-control yg-form-input"
                                                            id="yg-phone"
                                                            value={this.state.phone}
                                                            onChange={(e) => this.onPhoneChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor="yg-birthday" className="yg-headline-label-light-s-black">{t('date_of_birth')}</label>
                                                    <div className="yg-password-form">
                                                        <DatePicker
                                                            value={this.state.birthday}
                                                            onChange={this.onChangeBirthday}
                                                            format="dd/MM/yyyy"
                                                            className="form-control yg-form-input birthday-picker"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        onClick={this.onAddChildToCognito}
                                                        className="btn yg-btn yg-btn-black"
                                                        disabled={this.state.name?.length === 0 || this.state.email?.length === 0 || this.state.phone?.length === 0 || this.state.gender?.length === 0 || this.state.color?.length === 0 ? true : false}
                                                    >
                                                        {t('add')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </> :
                                    this.state.showChildInfo ?
                                        <>
                                            {
                                                this.props.parent.fetchingApiButton ?
                                                    <img id="animlogo-signup" src={logo_single_big} alt="Logo" /> :
                                                    <div className="change-email-modal-form pt-5">
                                                        <img src={start} alt="Let's Start" className="yg-parentdb-nochildimg" />
                                                        <div className="form-group yg-form-100">
                                                            <span className="yg-codelogin-headline-main">{t('child_login_info')}</span>
                                                            <div className="child-info-block mt-3">
                                                                <div>
                                                                    {t('email')}:&nbsp;<span className='yg-headline-label-light-s-blue'>{this.state.childEmail}</span>
                                                                </div>
                                                                <div>
                                                                    {t('password')}:&nbsp;<span className='yg-headline-label-light-s-blue'>{this.state.childTemporaryPassword}</span>
                                                                </div>
                                                            </div>
                                                            <div className="yg-btn-form-lets-start">
                                                                <button
                                                                    type="button"
                                                                    onClick={this.props.handleCloseModal}
                                                                    className="btn yg-btn-thin yg-btn-black"
                                                                >
                                                                    {t('lets_start')}
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    onClick={this.onAddOtherChild}
                                                                    className="btn yg-btn-thin yg-btn-white"
                                                                >
                                                                    {t('add_other_child')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                !this.props.parent.fetchingApi &&
                                                <>
                                                    <span className="yg-greeting-parent-name">{t('children')}</span>
                                                    <div className="pt-5">
                                                        {
                                                            this.state.childrenList?.map((i, k) => {
                                                                return (
                                                                    <button key={k} className="card-button" onClick={() => this.onShowChildProfile(i)}>
                                                                        <div className={i.color === "1" ? "yg-card-head yg-gradient-blue yg-modal-child-max mb-4" : "yg-card-head yg-gradient-orange yg-modal-child-max mb-4"}>
                                                                            <div className="yg-radio-form-sa">
                                                                                <img src={i.picture === undefined ? profile_pic : i.picture} alt="Avatar" className="yg-card-avatar-max" />
                                                                                <span className="yg-cc-selfcard-topname pl-4">{i.name}</span>
                                                                            </div>
                                                                            <div className="yg-balance-header">
                                                                                <img src={forward} alt="Forward" className="expenses-svg" />
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className="yg-btn-form ml-2 mr-2">
                                                        <button
                                                            type="button"
                                                            className="btn yg-btn yg-btn-black"
                                                            onClick={this.onAddChild}
                                                        >
                                                            {t('add_child')}
                                                        </button>
                                                    </div>
                                                </>
                                            }
                                        </>
                            }
                        </div>
                }
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
                <ToastContainer
                    position="bottom-left"
                    className="toast-container-mobile"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addChildRequest: (body) => dispatch(ParentActions.addChildRequest(body)),
        getChildListRequest: () => dispatch(ParentActions.getChildListRequest())
    }
}
const mapStateToProps = (state) => {
    return {
        parent: state.parent
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChildrenProfiles)