import React, { Component } from 'react'
import bank_child from '../../../svg/illustrations/bank_child.svg'
import select_back from '../../../svg/select_back.svg'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import BankActions from '../../../reducers/BankRedux'
import { BankWindow } from '../../../components/helpers/bank-window'
import BankAccounts from '../common/bank-accounts'

class ConnectBank extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectMainAccount: false,
            returnBack: false,
            sandboxUrl: "",
            urlReceived: false,
            code: "",
            goToNextStep: false
        }
    }

    componentDidMount() {
        this.props.connectUrlRequest()
        this.setState({ urlReceived: true })
    }

    componentDidUpdate() {
        if (this.props.bank.connectUrlResponse !== null && this.state.urlReceived) {
            this.setState({
                sandboxUrl: this.props.bank?.connectUrlResponse?.data?.data.sandbox_connect_bank_url,
                urlReceived: false,
                goToNextStep: true
            })
        }
        if (this.state.code.length > 0 && this.state.goToNextStep) {
            this.setState({
                selectMainAccount: true,
                goToNextStep: false
            })
        }
    }

    onBankResponse = (err, res) => {
        if (err) {
            this.setState({ code: res })
        }
        this.setState({ code: res })
    }

    onReturnBack = () => {
        this.setState({
            returnBack: true
        })
        window.location.href = '/signin'
    }

    render() {
        const { t } = this.props

        return (
            <>
                {
                    this.state.selectMainAccount ?
                        <BankAccounts
                            code={this.state.code}
                            role={"child"}
                        /> :
                        <>
                            <button className="yg-select-back-lp" onClick={this.onReturnBack}>
                                <img src={select_back} alt="Back" />
                            </button>
                            <div className="connect-bank-from-child">
                                <img src={bank_child} alt="Bank" />
                                <div className="form-group yg-form">
                                    <span className="yg-codelogin-headline-main">Lorem ipsum dolor sit amet, consetet</span>
                                    <div className="yg-codelogin-form">
                                        <label htmlFor="yg-password" className="yg-headline-label">
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                                        </label>
                                    </div>
                                    <div className="yg-btn-form">
                                        <BankWindow
                                            bridge={this.onBankResponse}
                                            url={this.state.sandboxUrl}
                                        >
                                            {t('connect_to_bank')}
                                        </BankWindow>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        connectUrlRequest: () => dispatch(BankActions.connectUrlRequest())
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        bank: state.bank
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConnectBank))