import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    completeChoresRequest: ['body'],
    completeChoresSuccess: ['data'],
    completeChoresFailure: ['error'],

    setDailyBudgetRequest: ['body'],
    setDailyBudgetSuccess: ['data'],
    setDailyBudgetFailure: ['error'],

    getDailyBudgetRequest: [],
    getDailyBudgetSuccess: ['data'],
    getDailyBudgetFailure: ['error'],

    getChildDashboardRequest: [],
    getChildDashboardSuccess: ['data'],
    getChildDashboardFailure: ['error']
})

export const ChildTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    completeChoresResponse: null,
    completeChoresError: false,
    completeChoresErrorMessage: '',

    setDailyBudgetResponse: null,
    setDailyBudgetError: false,
    setDailyBudgetErrorMessage: '',

    getDailyBudgetResponse: null,
    getDailyBudgetError: false,
    getDailyBudgetErrorMessage: '',

    getChildDashboardResponse: null,
    getChildDashboardError: false,
    getChildDashboardErrorMessage: '',
})

/* ------------- Complete Chores ------------- */
export const completeChoresRequest = (state, action) => {
    return state.merge({
        completeChoresResponse: null,
        completeChoresError: false,
        completeChoresErrorMessage: ''
    })
}
export const completeChoresSuccess = (state, action) => {
    return state.merge({
        completeChoresResponse: action.data,
        completeChoresError: false,
        completeChoresErrorMessage: ''
    })
}
export const completeChoresFailure = (state, action) => {
    return state.merge({
        completeChoresError: true,
        completeChoresErrorMessage: action.error
    })
}

/* ------------- Set Daily Budget ------------- */
export const setDailyBudgetRequest = (state, action) => {
    return state.merge({
        setDailyBudgetResponse: null,
        setDailyBudgetError: false,
        setDailyBudgetErrorMessage: ''
    })
}
export const setDailyBudgetSuccess = (state, action) => {
    return state.merge({
        setDailyBudgetResponse: action.data,
        setDailyBudgetError: false,
        setDailyBudgetErrorMessage: ''
    })
}
export const setDailyBudgetFailure = (state, action) => {
    return state.merge({
        setDailyBudgetError: true,
        setDailyBudgetErrorMessage: action.error
    })
}

/* ------------- Get Daily Budget ------------- */
export const getDailyBudgetRequest = (state, action) => {
    return state.merge({
        getDailyBudgetResponse: null,
        getDailyBudgetError: false,
        getDailyBudgetErrorMessage: ''
    })
}
export const getDailyBudgetSuccess = (state, action) => {
    return state.merge({
        getDailyBudgetResponse: action.data,
        getDailyBudgetError: false,
        getDailyBudgetErrorMessage: ''
    })
}
export const getDailyBudgetFailure = (state, action) => {
    return state.merge({
        getDailyBudgetError: true,
        getDailyBudgetErrorMessage: action.error
    })
}

/* ------------- Get Child Dashboard ------------- */
export const getChildDashboardRequest = (state, action) => {
    return state.merge({
        getChildDashboardResponse: null,
        getChildDashboardError: false,
        getChildDashboardErrorMessage: ''
    })
}
export const getChildDashboardSuccess = (state, action) => {
    return state.merge({
        getChildDashboardResponse: action.data,
        getChildDashboardError: false,
        getChildDashboardErrorMessage: ''
    })
}
export const getChildDashboardFailure = (state, action) => {
    return state.merge({
        getChildDashboardError: true,
        getChildDashboardErrorMessage: action.error
    })
}

/* ------------- Connection Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.COMPLETE_CHORES_REQUEST]: completeChoresRequest,
    [Types.COMPLETE_CHORES_SUCCESS]: completeChoresSuccess,
    [Types.COMPLETE_CHORES_FAILURE]: completeChoresFailure,

    [Types.SET_DAILY_BUDGET_REQUEST]: setDailyBudgetRequest,
    [Types.SET_DAILY_BUDGET_SUCCESS]: setDailyBudgetSuccess,
    [Types.SET_DAILY_BUDGET_FAILURE]: setDailyBudgetFailure,

    [Types.GET_DAILY_BUDGET_REQUEST]: getDailyBudgetRequest,
    [Types.GET_DAILY_BUDGET_SUCCESS]: getDailyBudgetSuccess,
    [Types.GET_DAILY_BUDGET_FAILURE]: getDailyBudgetFailure,

    [Types.GET_CHILD_DASHBOARD_REQUEST]: getChildDashboardRequest,
    [Types.GET_CHILD_DASHBOARD_SUCCESS]: getChildDashboardSuccess,
    [Types.GET_CHILD_DASHBOARD_FAILURE]: getChildDashboardFailure
})