import React, { Component } from 'react'
import Modal from 'react-modal'
import Expense from '../common/expense'
import { PieChart } from 'react-minimal-pie-chart'
import 'react-circular-progressbar/dist/styles.css'
import { connect } from 'react-redux'
import BankActions from '../../../reducers/BankRedux'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import moment from "moment"
import { Popover } from 'react-tiny-popover'
import 'react-circular-progressbar/dist/styles.css'
import cancel from '../../../svg/cancel.svg'
import calendar from '../../../svg/calendar.svg'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
// Expenses icons
import shopping from '../../../svg/expenses/shopping.svg'
import gambling from '../../../svg/expenses/gambling.svg'
import fees from '../../../svg/expenses/fees.svg'
import uncategorized from '../../../svg/expenses/uncategorized.svg'
import transport from '../../../svg/expenses/transport.svg'
import all from '../../../svg/expenses/all.svg'

class AllExpenses extends Component {

    dateFormat = "YYYY-MM-DD"

    constructor(props) {
        super(props)
        this.state = {
            displayCalendar: false,
            inputValue: "",
            anchorEl: null,
            fromDate: undefined,
            toDate: undefined,
            calendarIsClosed: true,
            getTransictionsCalled: false,
            expenses: [],
            showExpense: false,
            expense: {}
        }
    }

    componentDidMount() {
        // Get first and last day of current week
        var curr = new Date
        var first = curr.getDate() - curr.getDay() - 10
        var last = first + 6
        var firstday = new Date(curr.setDate(first))
        var lastday = new Date(curr.setDate(last))

        this.props.getAccountTransactionsRequest({
            userId: this.props.user.userData?.username,
            to: moment(lastday).format(this.dateFormat),
            from: moment(firstday).format(this.dateFormat),
            accountType: 1
        })
        this.setState({
            expenses: this.props.bank.getAccountTransactionsResponse?.data?.data?.data,
            inputValue: moment(firstday).format(this.dateFormat) + " - " + moment(lastday).format(this.dateFormat)
        })
    }

    componentDidUpdate() {
        if (this.state.getTransictionsCalled) {
            !this.props.bank.fetchingApiButton &&
                this.setState({
                    expenses: this.props.bank.getAccountTransactionsResponse?.data?.data?.data,
                    getTransictionsCalled: false
                })
        }
    }

    onSelectDateRanges = ({ selection }) => {
        let { startDate, endDate } = selection

        startDate = moment(startDate)
        startDate = startDate.isValid() ? startDate.toDate() : undefined

        endDate = moment(endDate)
        endDate = endDate.isValid() ? endDate.toDate() : undefined

        let inputValue = ""
        if (startDate) inputValue += moment(startDate).format(this.dateFormat)
        if (endDate) inputValue += " - " + moment(endDate).format(this.dateFormat)

        this.setState({
            fromDate: startDate,
            toDate: endDate,
            inputValue
        })
    }

    processInputValue(value) {
        let [fromDate, toDate] = value.split("-").map(elm => elm.trim())

        fromDate = moment(fromDate, this.dateFormat)
        fromDate = fromDate.isValid() ? fromDate.toDate() : undefined

        toDate = moment(toDate, this.dateFormat)
        toDate = toDate.isValid() ? toDate.toDate() : undefined

        return { fromDate, toDate }
    }

    onAdornmentClick = (e) => {
        this.setState({
            displayCalendar: true,
            anchorEl: e.currentTarget
        })
    }

    onPopoverClose = () => {
        this.setState({
            displayCalendar: false,
            anchorEl: null,
            calendarIsClosed: false
        })
        if (!this.state.calendarIsClosed) {
            this.props.getAccountTransactionsRequest({
                userId: this.props.user.userData?.username,
                to: (this.state.toDate?.toString())?.substring(0, 10),
                from: (this.state.fromDate?.toString())?.substring(0, 10),
                accountType: 1
            })
            this.setState({
                calendarIsClosed: true,
                getTransictionsCalled: true
            })
        }
    }

    onShowExpense = (expense) => {
        this.setState({
            showExpense: true,
            expense: expense
        })
    }

    render() {
        const { t } = this.props
        let blueColors = ['#03A6F7', '#83D6FF', '#CBEEFF', '#EFFAFF']
        let orangeColors = ['#FF5B02', 'rgb(157 70 24 / 54%)', '#FFE0CF', '#FFF1EA']

        return (
            <Modal
                isOpen={this.props.showExpensesModal}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                {
                    this.state.showExpense ?
                        <Expense
                            t={t}
                            childInfo={this.props.childInfo}
                            expense={this.state.expense}
                            expenses={this.state.expenses}
                        /> :
                        <div className={window.innerWidth < 769 ? "w-90" : "w-80"}>
                            <span className="yg-greeting-name">{t('expenses')}</span>
                            {
                                this.props.bank.fetchingApiButton ?
                                    <button
                                        type="button"
                                        className="btn yg-btn yg-btn-black mt-4"
                                    >
                                        <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                                    </button> :
                                    <>
                                        <div className="pt-4 expenses-with-chart">
                                            <Popover
                                                isOpen={this.state.displayCalendar}
                                                positions={['top', 'bottom', 'left', 'right']}
                                                content={() => (
                                                    <DateRangePicker
                                                        ranges={[
                                                            {
                                                                startDate: this.state.fromDate,
                                                                endDate: this.state.toDate,
                                                                key: "selection"
                                                            }
                                                        ]}
                                                        editableDateInputs={true}
                                                        moveRangeOnFirstSelection={false}
                                                        onChange={this.onSelectDateRanges}
                                                        showMonthAndYearPickers={true}
                                                        showDateDisplay={false}
                                                        scroll={{ enabled: true }}
                                                    />
                                                )}
                                                onClickOutside={this.onPopoverClose}
                                            >
                                                <div className="yg-expenses-form-main" onClick={this.onAdornmentClick}>
                                                    <img src={calendar} alt="Calendar" className="expenses-svg" />
                                                    <input
                                                        className="yg-expenses-form-div-input input-calendar"
                                                        type="text"
                                                        value={this.state.inputValue}
                                                        placeholder={t('please_choose_date_range')}
                                                    />
                                                </div>
                                            </Popover>
                                            <div className="expenses-chart">
                                                <PieChart
                                                    totalValue={this.state.expenses?.totalAmount}
                                                    animate={true}
                                                    data={
                                                        this.state.expenses?.slice(0, 4)?.map((i, k) => {
                                                            return (
                                                                { value: i.amount, color: (this.props.childInfo.color === "1" ? blueColors[k] : orangeColors[k]) }
                                                            )
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                            }
                            <div className="yg-password-form-div">
                                <>
                                    {
                                        this.props.bank.fetchingApiButton ? null :
                                            <>
                                                <span className="yg-headline-label-dark">{t('expenses')}</span>
                                                <div className="yg-carddtls-expdtls-black">
                                                    <div className="yg-carddtls-exprow">
                                                        <img
                                                            className="yg-balance-header-img- mr-3"
                                                            src={all}
                                                            alt="Icon"
                                                        />
                                                        <div className="yg-balance-header-left">
                                                            <span className="yg-cc-topname">{t('all')}</span>
                                                        </div>
                                                    </div>
                                                    <div className="yg-balance-header">
                                                        <div>
                                                            <span className="yg-card-alwn-costs-q-small">£</span>
                                                            <span className="yg-card-number-small">{this.props.childInfo?.expenses?.totalAmount}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.expenses?.slice(0, 3)?.map((i, k) => {
                                                        return (
                                                            <button
                                                                key={k}
                                                                style={{ background: this.props.childInfo.color === "1" ? blueColors[k] : orangeColors[k] }}
                                                                className={this.props.childInfo.color === "1" ? "yg-carddtls-expdtls-blue w-100" : "yg-carddtls-expdtls-orange-details w-100"}
                                                                onClick={() => this.onShowExpense(i)}
                                                            >
                                                                <div className="yg-carddtls-exprow">
                                                                    <img
                                                                        className="yg-balance-header-img-details mr-3"
                                                                        src={
                                                                            i.transaction_classification[0]?.includes("Shopping") ? shopping :
                                                                                i.transaction_classification[0]?.includes("Gambling") ? gambling :
                                                                                    i.transaction_classification[0]?.includes("Fees") ? fees :
                                                                                        i.transaction_classification[0]?.includes("Transport") ? transport :
                                                                                            uncategorized
                                                                        }
                                                                        alt="Icon"
                                                                    />
                                                                    <div className="yg-balance-header-left">
                                                                        <span className="yg-cc-topname">
                                                                            {i.transaction_classification[0] === undefined ? t('uncategorized') : i.transaction_classification[0]}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="yg-balance-header">
                                                                    <div>
                                                                        <span className="yg-card-alwn-costs-q-small">£</span>
                                                                        <span className="yg-card-number-small">{i.amount}</span>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        )
                                                    })
                                                }
                                            </>
                                    }
                                </>
                            </div>
                        </div>
                }
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAccountTransactionsRequest: (body) => dispatch(BankActions.getAccountTransactionsRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        bank: state.bank
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AllExpenses)