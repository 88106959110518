import React, { Component } from 'react'
import password from '../../../svg/illustrations/password.svg'
import select_back from '../../../svg/select_back.svg'
import CheckEmail from '../common/check-email'
import { CognitoUser } from 'amazon-cognito-identity-js'
import Pool from '../../helpers/userpool'

export default class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            returnBack: false,
            email: '',
            reset: false
        }
    }

    onReturnBack = () => {
        this.setState({
            returnBack: true
        })
        window.location.href = '/signin'
    }

    onEmailChange = (e) => {
        this.setState({
            email: e.target.value
        })
    };

    getUser = () => {
        return new CognitoUser({
            Username: this.state.email,
            Pool
        })
    }

    onResetPassword = () => {
        this.getUser().forgotPassword({
            onSuccess: data => {
                console.log('RESET PASS onSuccess: ', data)
            },
            onFailure: err => {
                console.log('RESET PASS onFailure: ', err)
            },
            inputVerificationCode: data => {
                console.log('RESET PASS inputCode: ', data)
            }
        })
        this.setState({
            reset: true
        })
    }

    render() {
        const { t } = this.props;

        return (
            <>
                {
                    this.state.reset ? <CheckEmail t={t} getUser={this.getUser} /> :
                        <>
                            <button className="yg-select-back-lp" onClick={() => this.onReturnBack()}>
                                <img src={select_back} alt="Back" />
                            </button>
                            <div className="connect-bank-from-child">
                                <img src={password} alt="Bank" />
                                <div className="form-group yg-form pt-4">
                                    <span className="yg-codelogin-headline-main pb-2">{t('reset_your_password')}</span>
                                    <div className="yg-codelogin-form">
                                        <label htmlFor="yg-password" className="yg-headline-label">{t('enter_your_email_so_we_can_send_a_link')}</label>
                                    </div>
                                    <div className="yg-password-form-div pt-4 w-50">
                                        <label htmlFor="yg-email" className="yg-headline-label-dark">{t('email')}</label>
                                        <div className="yg-password-form">
                                            <input
                                                placeholder={t('placeholder_email')}
                                                name="email"
                                                type="text"
                                                className="form-control yg-form-input"
                                                id="yg-email"
                                                value={this.state.email}
                                                onChange={(e) => this.onEmailChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="yg-btn-form w-50">
                                        <button
                                            type="button"
                                            onClick={() => this.onResetPassword()}
                                            className="btn  yg-btn-min yg-btn-black w-60"
                                        >
                                            {t('send')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </>
        );
    }
}