import React, { Component } from 'react'
import cancel from '../../../../svg/cancel.svg'
import chore_white from '../../../../svg/chore_white.svg'
import EditChore from './edit-chore'
import AddChore from './add-chore'
import { connect } from 'react-redux'
import CommonActions from '../../../../reducers/CommonRedux'

class ChoresDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedChore: null,
            addChoreModal: false,
            editChoreModal: false,
            selectedChild: null,
            todo: [],
            done: [],
            earned: []
        }
    }

    componentDidMount() {
        let selectedChild = this.props.child !== undefined ? this.props.child : this.props.childrenList[0]
        this.props.choresListRequest({
            userId: selectedChild.sub
        })
        setTimeout(() => {
            this.setState({
                todo: this.props.common?.choresListResponse?.data?.data.todo,
                done: this.props.common?.choresListResponse?.data?.data.done,
                earned: this.props.common?.choresListResponse?.data?.data.earned
            })
        }, 500)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedOption !== this.props.selectedOption) {
            let selectedChild = this.props.child !== undefined ? this.props.child :
                this.props.selectedOption === undefined ? this.props.childrenList[0] :
                    this.props.childrenList.find((e) => {
                        return e.name === this.props.selectedOption
                    })
            this.props.choresListRequest({
                userId: selectedChild.sub
            })
            setTimeout(() => {
                this.setState({
                    todo: this.props.common?.choresListResponse?.data?.data.todo,
                    done: this.props.common?.choresListResponse?.data?.data.done,
                    earned: this.props.common?.choresListResponse?.data?.data.earned
                })
            }, 500)
        }
    }

    onEditChore = (selectedChore) => {
        this.setState({
            editChoreModal: true,
            selectedChore: selectedChore
        })
        this.props.handleCheckEditChoreModal(true)
    }

    onAddChore = () => {
        this.setState({
            addChoreModal: true
        })
        this.props.handleCheckAddChoreModal(true)
    }

    onCheckChore = () => {
    }

    render() {
        const { t } = this.props

        let selectedChild =
            this.props.child !== undefined ? this.props.child :
                this.props.selectedOption === undefined ? this.props.childrenList[0] :
                    this.props.childrenList.find((e) => {
                        return e.name === this.props.selectedOption
                    })

        return (
            <>
                {
                    this.state.editChoreModal ?
                        // Edit Chore
                        <EditChore
                            t={t}
                            selectedChild={selectedChild}
                            selectedChore={this.state.selectedChore}
                            handleCloseModal={this.props.handleCloseModal}
                        /> :
                        // Add Chore
                        this.state.addChoreModal ?
                            <AddChore
                                t={t}
                                selectedChild={selectedChild}
                                handleCloseModal={this.props.handleCloseModal}
                            /> :
                            // Chores Details Table
                            <div className="pt-2">
                                <span className="yg-greeting-name">
                                    {selectedChild?.name + t('s_chores')}
                                </span>
                                <button
                                    type="button"
                                    onClick={this.onAddChore}
                                    className="btn yg-parentdb-btn-dark mt-3 yg-headline-label-light-xs-white">
                                    <img src={chore_white} alt="Chore" className="expenses-svg" />&nbsp;{t('add_chore')}
                                </button>
                                <div className="yg-cc-pb-top mt-5">
                                    <span className="yg-headline-label-light-xs-black">{t('to_do')}</span>
                                    <span className="yg-headline-label-light-xs-black">{this.state.todo?.length}&nbsp;{this.state.todo?.length === 1 ? t('chore') : t('chores')}</span>
                                </div>
                                <div className="yg-password-form-div">
                                    {
                                        this.state.todo?.map((i, k) => {
                                            return (
                                                <button
                                                    key={k}
                                                    className={selectedChild.color === "1" ? "yg-expenses-form-div-details-secondary-white" : "yg-expenses-form-div-details-white-primary"}
                                                    onClick={() => this.onEditChore(i)}
                                                >
                                                    <div className="yg-radio-form-chores">
                                                        <span className={selectedChild.color === "1" ? "yg-headline-label-light-xs-blue" : "yg-headline-label-light-xs-orange"}>
                                                            {i.name}
                                                        </span>
                                                        <div>
                                                            <span className="pound-gray">£</span>
                                                            <span className={selectedChild.color === "1" ? "yg-headline-label-light-s-blue" : "yg-headline-label-light-s-orange"}>
                                                                {i.amount}
                                                            </span>
                                                            <span className="pound-gray">
                                                                /{i.period === "1" ? "one time" : i.period === "2" ? "daily" : "weekly"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </button>
                                            )
                                        })
                                    }
                                </div>
                                <div className="yg-cc-pb-top">
                                    <span className="yg-headline-label-light-xs-black">{t('done')}</span>
                                    <span className="yg-headline-label-light-xs-black">{this.state.done?.length}&nbsp;{t('chore')}</span>
                                </div>
                                {
                                    this.state.done?.map((i, k) => {
                                        return (
                                            <div>
                                                <button className="yg-expenses-form-div-details-secondary-green">
                                                    <div className="yg-radio-form-chores">
                                                        <div className="yg-radio-form-sa">
                                                            <label className="yg-check-green">
                                                                <input type="checkbox" checked onChange={this.onCheckChore} />
                                                                <span className="checkmark-green"></span>
                                                            </label>
                                                            <span className="yg-headline-label-light-xs-green">
                                                                {i.name}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="pound-gray">£</span>
                                                            <span className="yg-headline-label-light-s-green">
                                                                {i.spent}
                                                            </span>
                                                            <span className="pound-gray">
                                                                /{i.spent_period}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                                <div className="yg-cc-pb-top pt-4">
                                    <span className="yg-headline-label-light-xs-black">{t('earnings')}</span>
                                    <span className="yg-headline-label-light-xs-black"><span className="pound-gray">£</span>0</span>
                                </div>
                                <div>
                                    <div className="yg-password-form-div">
                                        {
                                            this.state.earned?.map((i, k) => {
                                                return (
                                                    <button key={k} className={"yg-expenses-form-div-details-" + i.backcolor}>
                                                        <div className="yg-radio-form-chores">
                                                            <span className={i.backcolor === "secondary-white" ? "yg-headline-label-light-xs-blue" : "yg-headline-label-light-xs-orange"}>
                                                                {i.name}
                                                            </span>
                                                            <div>
                                                                <span className="pound-gray">£</span>
                                                                <span className={i.backcolor === "secondary-white" ? "yg-headline-label-light-s-blue" : "yg-headline-label-light-s-orange"}>
                                                                    {i.spent}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </button>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                }
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        choresListRequest: (body) => dispatch(CommonActions.choresListRequest(body)),
    }
}
const mapStateToProps = (state) => {
    return {
        parent: state.parent,
        common: state.common
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChoresDetails)