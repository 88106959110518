import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { Redirect } from 'react-router'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Auth } from 'aws-amplify'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
const eye = <FontAwesomeIcon icon={faEye} />
const closed_eye = <FontAwesomeIcon icon={faEyeSlash} />

class CreateAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            phone: '',
            checked: false,
            passwordShown: false,
            redirectToSignIn: false,
            loading: false
        }
    }

    componentWillUnmount() {
        this.setState({ loading: false })
    }

    togglePasswordVisiblity = () => {
        if (this.state.passwordShown) {
            this.setState({
                passwordShown: false
            })
        } else if (!this.state.passwordShown) {
            this.setState({
                passwordShown: true
            })
        }
    };

    handlePasswordChange = (event) => {
        this.setState({
            password: event.target.value
        })
    };

    onNameChange = (event) => {
        this.setState({
            name: event.target.value
        })
    };

    onEmailChange = (event) => {
        this.setState({
            email: event.target.value
        })
    };

    onPhoneChange = (event) => {
        this.setState({
            phone: event.target.value
        })
    };

    onSwitchChange = (e) => {
        this.setState({
            checked: e
        })
    }

    onNavigateToSignIn = () => {
        this.setState({
            redirectToSignIn: true
        })
    }

    onCreateAccount = async () => {
        let email = this.state.email
        let password = this.state.password
        let phone_number = this.state.phone
        let username = this.state.email
        let name = this.state.name

        try {
            await this.setState({ loading: true }, () => {
                Auth.signUp({
                    username,
                    password,
                    attributes: {
                        email,
                        phone_number,
                        name,
                        'custom:type': "1"
                    }
                }).then(result => {
                    toast.success('Account successfully created!', {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    this.setState({
                        loading: false
                    })
                    setTimeout(() => {
                        this.props.handleGoToNextStep(true)
                        this.props.handleSendUsername(username)
                        this.props.handleSendPassword(password)
                    }, 1000);
                }).catch(() => {
                    this.setState({ loading: false })
                })
            })
        } catch (error) {
            this.setState({ loading: false })
            console.log('Error creating account => ', error.message)
            if (error.message === "An account with the given email already exists.") {
                toast.error('Given email already exists!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            } else if (error.message === "Password must have uppercase characters") {
                toast.error('Password must have uppercase characters!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            } else if (error.message === "Password must have numeric characters") {
                toast.error('Password must have numeric characters!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            } else if (error.message === "Password not long enough" || error.message === "Password did not conform with policy: Password not long enough") {
                toast.error('Password is not long enough!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            } else if (error.message === "Invalid phone number format") {
                toast.error('Invalid phone number format!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            } else {
                toast.error('Error! Please try again!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    }

    render() {
        const { t } = this.props;

        return (
            <>
                {
                    this.state.redirectToSignIn ?
                        <Redirect push to="/signin" /> :
                        <div className="create-accout-form pt-5">
                            <div className="create-account-form-header">
                                <span className="create-account-form-h1">{t('create_account')}</span>
                                <button className="yg-link btn-as-link" onClick={() => this.onNavigateToSignIn()}>
                                    {t('do_you_have_an_account')}
                                </button>
                            </div>
                            <div className="form-group yg-form">
                                <div className="create-account-form-group">
                                    <div className="yg-password-form-div">
                                        <label htmlFor="yg-name" className="yg-headline-label-dark">{t('full_name')}</label>
                                        <div className="yg-password-form">
                                            <input
                                                placeholder={t('ellie_smith')}
                                                name="name"
                                                type="text"
                                                className="form-control yg-form-input"
                                                id="yg-name"
                                                value={this.state.name}
                                                onChange={(e) => this.onNameChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="yg-password-form-div">
                                        <label htmlFor="yg-email" className="yg-headline-label-dark">{t('email')}</label>
                                        <div className="yg-password-form">
                                            <input
                                                placeholder={t('placeholder_email')}
                                                name="email"
                                                type="text"
                                                className="form-control yg-form-input"
                                                id="yg-email"
                                                value={this.state.email}
                                                onChange={(e) => this.onEmailChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="yg-password-form-div">
                                        <label htmlFor="yg-phone" className="yg-headline-label-dark">{t('phone_number')}</label>
                                        <div className="yg-password-form">
                                            <input
                                                placeholder={t('phone_template')}
                                                name="phone"
                                                type="text"
                                                className="form-control yg-form-input"
                                                id="yg-phone"
                                                value={this.state.phone}
                                                onChange={(e) => this.onPhoneChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="yg-password-form-div">
                                        <label htmlFor="yg-password" className="yg-headline-label-dark">{t('password')}</label>
                                        <div className="yg-password-form">
                                            <input
                                                placeholder={t('type_your_pass')}
                                                name="password"
                                                type={this.state.passwordShown ? "text" : "password"}
                                                className="form-control yg-form-input"
                                                id="yg-password"
                                                value={this.state.password}
                                                onChange={(e) => this.handlePasswordChange(e)}
                                            />
                                            {
                                                this.state.passwordShown ?
                                                    <i onClick={this.togglePasswordVisiblity}>{eye}</i>
                                                    : <i onClick={this.togglePasswordVisiblity}>{closed_eye}</i>
                                            }
                                        </div>
                                    </div>
                                    <div className="yg-btn-form">
                                        {
                                            this.state.loading ?
                                                <button
                                                    type="button"
                                                    className="btn yg-btn yg-btn-black"
                                                >
                                                    <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                                                </button> :
                                                <button
                                                    type="button"
                                                    disabled={this.state.name?.length === 0 || this.state.email?.length === 0 || this.state.password?.length === 0 || this.state.phone?.length === 0 ? true : false}
                                                    onClick={this.onCreateAccount}
                                                    className="btn yg-btn yg-btn-black"
                                                >
                                                    {t('create')}
                                                </button>
                                        }
                                    </div>
                                    <ToastContainer
                                        position="bottom-left"
                                        className="toast-container-mobile"
                                        autoClose={1000}
                                        hideProgressBar
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            </div>
                        </div>
                }
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}
const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateAccount))