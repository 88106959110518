import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import { ToastContainer, toast } from 'react-toastify'
import phone from '../../../svg/illustrations/phone.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
const eye = <FontAwesomeIcon icon={faEye} />
const closed_eye = <FontAwesomeIcon icon={faEyeSlash} />

export default class ForceResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            newPassword: '',
            match: null,
            passwordShown: false,
            newPasswordShown: false
        }
    }

    togglePasswordVisiblity = () => {
        if (this.state.passwordShown) {
            this.setState({
                passwordShown: false
            })
        } else if (!this.state.passwordShown) {
            this.setState({
                passwordShown: true
            })
        }
    }

    toggleRepeatPasswordVisiblity = () => {
        if (this.state.newPasswordShown) {
            this.setState({
                newPasswordShown: false
            })
        } else if (!this.state.newPasswordShown) {
            this.setState({
                newPasswordShown: true
            })
        }
    }

    handlePasswordChange = (event) => {
        this.setState({
            password: event.target.value
        })
    }

    handleNewPasswordChange = (event) => {
        this.setState({
            newPassword: event.target.value,
            match: null
        })
    }

    comparePassword = (event) => {
        if (this.state.password === this.state.newPassword) {
            this.setState({
                match: true
            })
        } else {
            this.setState({
                match: false
            })
        }
    }

    onSubmitNewPass = () => {
        Auth.completeNewPassword(
            this.props.user,
            this.state.newPassword,
        ).then(user => {
            toast.success('Password changed successfully!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setTimeout(() => {
                window.location.href = '/signin'
            }, 2000)
        }).catch(e => {
            console.log(e)
            toast.error('Error! Please try again!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        })
    }

    onNavigateToSignIn = () => {
        window.location.href = '/signin'
    }

    render() {
        const { t } = this.props

        return (
            <div className="connect-bank-from-child pt-5">
                <img src={phone} alt="Bank" />
                <div className="form-group yg-form pt-4">
                    <div className="yg-password-form-div-entnewpass">
                        <label htmlFor="yg-password" className="yg-headline-label-dark">{t('password')}</label>
                        <div className="yg-password-form">
                            <input
                                placeholder={t('type_new_pass')}
                                name="password"
                                type={this.state.passwordShown ? "text" : "password"}
                                className="form-control"
                                id="yg-password"
                                value={this.state.password}
                                onChange={(event) => this.handlePasswordChange(event)}
                            />
                            {
                                this.state.passwordShown ?
                                    <i onClick={this.togglePasswordVisiblity}>{eye}</i>
                                    : <i onClick={this.togglePasswordVisiblity}>{closed_eye}</i>
                            }
                        </div>
                    </div>
                    <div className="yg-password-form-div-entnewpass">
                        <label htmlFor="yg-password" className="yg-headline-label-dark">{t('repeat_pass')}</label>
                        <div className="yg-password-form">
                            <input
                                placeholder={t('repeat_new_pass_placeholder')}
                                name="password"
                                type={this.state.newPasswordShown ? "text" : "password"}
                                className="form-control"
                                id="yg-password"
                                value={this.state.newPassword}
                                onChange={(event) => this.handleNewPasswordChange(event)}
                                onBlur={this.comparePassword}
                            />
                            {
                                this.state.newPasswordShown ?
                                    <i onClick={this.toggleRepeatPasswordVisiblity}>{eye}</i>
                                    : <i onClick={this.toggleRepeatPasswordVisiblity}>{closed_eye}</i>
                            }
                        </div>
                        {
                            this.state.match === false ?
                                <p className="error-msg">{t('passwords_dont_match')}</p>
                                : null
                        }
                    </div>
                    <div className="yg-btn-form" style={{ display: 'flex' }}>
                        <button
                            type="button"
                            className="btn  yg-btn yg-btn-black"
                            onClick={this.onSubmitNewPass}
                        >
                            {t('send')}
                            <ToastContainer
                                position="bottom-left"
                                autoClose={1000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        </button>
                        <button
                            type="button"
                            className="btn  yg-btn yg-btn-gray"
                            onClick={this.onNavigateToSignIn}
                        >
                            {t('cancel')}
                        </button>
                    </div>
                    <ToastContainer
                        position="bottom-left"
                        className="toast-container-mobile"
                        autoClose={1000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        )
    }
}