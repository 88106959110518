import React, { Component } from 'react'
import ConnectBank from '../components/forms/child/connect-bank'
import Toggler from '../components/helpers/toggler'
import ResetPassword from '../components/forms/common/reset-password'
import ForceResetPassword from '../components/forms/child/force-reset-password'
import logo_single_big from '../svg/logos/logo_single_big.svg'
import logo_single_writing from '../svg/logos/logo_single_writing.svg'
import { Auth } from 'aws-amplify'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import UserActions from '../reducers/UserRedux'
import VerificationActions from '../reducers/VerificationRedux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { withTranslation } from 'react-i18next'
import CheckPhone from '../components/forms/common/check-phone'
const eye = <FontAwesomeIcon icon={faEye} />
const closed_eye = <FontAwesomeIcon icon={faEyeSlash} />

class SignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            childChecked: false,
            email: '',
            passwordShown: false,
            password: '',
            redirectToSignUp: false,
            childSigned: false,
            childSignedComplete: false,
            forgotPassword: false,
            redirectToDashboard: false,
            emailChild: '',
            passwordChild: '',
            switchValue: "Parent",
            loggedIn: false,
            forceChangePassword: false,
            currentUser: undefined,
            type: 1,
            signInSmsSent: false
        }
    }

    // Password methods

    togglePasswordVisiblity = () => {
        if (this.state.passwordShown) {
            this.setState({
                passwordShown: false
            })
        } else if (!this.state.passwordShown) {
            this.setState({
                passwordShown: true
            })
        }
    }

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value })
    }

    handlePasswordChangeChild = (event) => {
        this.setState({ passwordChild: event.target.value })
    }

    onForgotPassword = () => {
        this.setState({ forgotPassword: true })
    }

    // Email methods

    onEmailChange = (event) => {
        this.setState({ email: event.target.value })
    }

    onEmailChangeChild = (event) => {
        this.setState({ emailChild: event.target.value })
    }

    // Redirect methods

    handleRedirectToDashboard = (redirectToDashboard) => {
        this.state.childChecked ?
            this.setState({
                signInSmsSent: false,
                childSigned: redirectToDashboard
            }) :
            this.setState({
                signInSmsSent: false,
                redirectToDashboard: redirectToDashboard
            })
    }

    onNavigateToSignUp = () => {
        this.setState({ redirectToSignUp: true })
    }

    // Switch

    onSwitchChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.value === "Child") {
            this.setState({
                childChecked: true
            })
        } else {
            this.setState({
                childChecked: false
            })
        }
    }

    onSignParent = async () => {
        try {
            await Auth.signIn(this.state.email, this.state.password).then((user) => {
                this.props.setUserData(user)
                if (!user.attributes["email_verified"]) {
                    /*toast.error('Verify your email!', {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    }*/
                    this.props.getCodeRequest({
                        access_token: user.signInUserSession.accessToken.jwtToken,
                        type: "phone_number"
                    })
                    this.setState({
                        //signInSmsSent: true
                        redirectToDashboard: true
                    })
                } else if (!user.attributes["phone_number_verified"]) {
                    toast.error('Verify your phone!', {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    })
                } else if (user.attributes["custom:bankConnection"] !== "1") {
                    toast.error('Connect to the bank!', {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    })
                } else if (user.attributes["custom:type"] === "1") {
                    this.props.getCodeRequest({
                        access_token: user.signInUserSession.accessToken.jwtToken,
                        type: "phone_number"
                    })
                    this.setState({
                        //signInSmsSent: true
                        redirectToDashboard: true
                    })
                } else {
                    toast.error('Please sign with parent credentials!', {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    })
                }
            })
        } catch (error) {
            toast.error('Incorrect username or password!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    onSignChild = async () => {
        try {
            const user = await Auth.signIn(this.state.emailChild, this.state.passwordChild)
            this.props.setUserData(user)
            if (this.props.user.userData.challengeName === "NEW_PASSWORD_REQUIRED") {
                this.setState({
                    forceChangePassword: true,
                    currentUser: user
                })
            } else {
                if (user.attributes["custom:type"] === "2") {
                    if (this.props.user.userData.attributes["custom:bankConnection"] === undefined) {
                        this.props.getCodeRequest({
                            access_token: user.signInUserSession.accessToken.jwtToken,
                            type: "phone_number"
                        })
                        this.setState({
                            //signInSmsSent: true
                            childSigned: true
                        })
                    } else {
                        this.props.getCodeRequest({
                            access_token: user.signInUserSession.accessToken.jwtToken,
                            type: "phone_number"
                        })
                        this.setState({
                            //signInSmsSent: true
                            childSignedComplete: true
                        })
                    }
                } else {
                    toast.error('Please sign with child credentials!', {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    })
                }
            }
        } catch (error) {
            toast.error('Incorrect username or password!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    render() {
        return (
            <>
                {
                    this.state.redirectToSignUp ?
                        <Redirect push to="/signup" /> :
                        this.state.redirectToDashboard ?
                            <Redirect push to="/parent-dashboard" /> :
                            <div className="yg-loginpage-row">
                                <div className="col yg-login-left">
                                    <img src={logo_single_big} alt="Logo" className="logo-single-big" />
                                    <img src={logo_single_writing} alt="Logo" />
                                </div>
                                <div className="col yg-signin-form">
                                    {
                                        this.state.childSigned || this.state.forgotPassword || this.state.forceChangePassword || this.state.signInSmsSent ? null :
                                            <Toggler
                                                name="switchValue"
                                                checked={this.state.switchValue}
                                                labels={[
                                                    "Parent",
                                                    "Child"
                                                ]}
                                                handleChange={this.onSwitchChange}
                                            />
                                    }
                                    {
                                        this.state.signInSmsSent ?
                                            <CheckPhone
                                                t={this.props.t}
                                                username={this.state.email}
                                                password={this.state.password}
                                                redirectToDashboard={this.handleRedirectToDashboard}
                                            /> :
                                            this.state.childChecked ?
                                                (
                                                    this.state.childSigned ?
                                                        <ConnectBank t={this.props.t} /> :
                                                        this.state.childSignedComplete ?
                                                            <Redirect push to="/child-dashboard" /> :
                                                            this.state.forgotPassword ?
                                                                <ResetPassword t={this.props.t} /> :
                                                                this.state.forceChangePassword ?
                                                                    <ForceResetPassword t={this.props.t} user={this.state.currentUser} /> :
                                                                    <div className="signin-form" style={{ width: "100%" }}>
                                                                        <div className="create-account-form-header">
                                                                            <span className="create-account-form-h1" style={{ 'paddingBottom': '1.8rem' }}>{this.props.t('sign_in')}</span>
                                                                        </div>
                                                                        <div className="form-group yg-form">
                                                                            <div className="create-account-form-group">
                                                                                <div className="yg-password-form-div">
                                                                                    <label htmlFor="yg-email" className="yg-headline-label">{this.props.t('email')}</label>
                                                                                    <div className="yg-password-form">
                                                                                        <input
                                                                                            placeholder={this.props.t('placeholder_email')}
                                                                                            name="email"
                                                                                            type="text"
                                                                                            className="form-control yg-form-input"
                                                                                            id="yg-email"
                                                                                            value={this.state.emailChild}
                                                                                            onChange={(e) => this.onEmailChangeChild(e)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="yg-password-form-div">
                                                                                    <label htmlFor="yg-password" className="yg-headline-label">{this.props.t('password')}</label>
                                                                                    <div className="yg-password-form">
                                                                                        <input
                                                                                            placeholder={this.props.t('type_your_pass')}
                                                                                            name="password"
                                                                                            type={this.state.passwordShown ? "text" : "password"}
                                                                                            className="form-control yg-form-input"
                                                                                            id="yg-password"
                                                                                            value={this.state.passwordChild}
                                                                                            onChange={(e) => this.handlePasswordChangeChild(e)}
                                                                                        />
                                                                                        {
                                                                                            this.state.passwordShown ?
                                                                                                <i onClick={this.togglePasswordVisiblity}>{eye}</i>
                                                                                                : <i onClick={this.togglePasswordVisiblity}>{closed_eye}</i>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="yg-btn-form">
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => this.onSignChild()}
                                                                                        className="btn  yg-btn yg-btn-black mb-3"
                                                                                        disabled={this.state.emailChild.length === 0 || this.state.passwordChild.length === 0 ? true : false}
                                                                                    >
                                                                                        {this.props.t('sign_in')}
                                                                                        <ToastContainer
                                                                                            position="bottom-left"
                                                                                            className="toast-container-mobile"
                                                                                            autoClose={1000}
                                                                                            hideProgressBar
                                                                                            newestOnTop={false}
                                                                                            closeOnClick
                                                                                            rtl={false}
                                                                                            pauseOnFocusLoss
                                                                                            draggable
                                                                                            pauseOnHover
                                                                                        />
                                                                                    </button>
                                                                                    <button className="yg-link-dark btn-as-link forgot_password-span" onClick={this.onForgotPassword}>
                                                                                        {this.props.t('forgot_password')}
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                )
                                                :
                                                <>
                                                    {
                                                        this.state.forgotPassword ?
                                                            <ResetPassword t={this.props.t} /> :
                                                            <div className="signin-form" style={{ width: "100%" }}>
                                                                <div className="create-account-form-header">
                                                                    <span className="create-account-form-h1">{this.props.t('sign_in')}</span>
                                                                    <button className="yg-link btn-as-link" onClick={() => this.onNavigateToSignUp()}>{this.props.t('dont_have_an_account')}</button>
                                                                </div>
                                                                <div className="form-group yg-form">
                                                                    <div className="create-account-form-group">
                                                                        <div className="yg-password-form-div">
                                                                            <label htmlFor="yg-email" className="yg-headline-label">{this.props.t('email')}</label>
                                                                            <div className="yg-password-form">
                                                                                <input
                                                                                    placeholder={this.props.t('placeholder_email')}
                                                                                    name="email"
                                                                                    type="text"
                                                                                    className="form-control yg-form-input"
                                                                                    id="yg-email"
                                                                                    value={this.state.email}
                                                                                    onChange={(e) => this.onEmailChange(e)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="yg-password-form-div">
                                                                            <label htmlFor="yg-password" className="yg-headline-label">{this.props.t('password')}</label>
                                                                            <div className="yg-password-form">
                                                                                <input
                                                                                    placeholder={this.props.t('type_your_pass')}
                                                                                    name="password"
                                                                                    type={this.state.passwordShown ? "text" : "password"}
                                                                                    className="form-control yg-form-input"
                                                                                    id="yg-password"
                                                                                    value={this.state.password}
                                                                                    onChange={(e) => this.handlePasswordChange(e)}
                                                                                />
                                                                                {
                                                                                    this.state.passwordShown ?
                                                                                        <i onClick={this.togglePasswordVisiblity}>{eye}</i>
                                                                                        : <i onClick={this.togglePasswordVisiblity}>{closed_eye}</i>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="yg-btn-form">
                                                                            {
                                                                                !this.state.loggedIn ?
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn  yg-btn yg-btn-black mb-3"
                                                                                        onClick={this.onSignParent}
                                                                                        disabled={this.state.email.length === 0 || this.state.password.length === 0 ? true : false}
                                                                                    >
                                                                                        {this.props.t('sign_in')}
                                                                                        <ToastContainer
                                                                                            position="bottom-left"
                                                                                            className="toast-container-mobile"
                                                                                            autoClose={1000}
                                                                                            hideProgressBar
                                                                                            newestOnTop={false}
                                                                                            closeOnClick
                                                                                            rtl={false}
                                                                                            pauseOnFocusLoss
                                                                                            draggable
                                                                                            pauseOnHover
                                                                                        />
                                                                                    </button> : null
                                                                            }
                                                                            <button className="yg-link-dark btn-as-link forgot_password-span" onClick={this.onForgotPassword}>
                                                                                {this.props.t('forgot_password')}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </>
                                    }
                                </div>
                            </div>
                }
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserData: (userData) => dispatch(UserActions.setUserData(userData)),
        getCodeRequest: (body) => dispatch(VerificationActions.getCodeRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SignIn))