import React, { Component } from 'react'
import AllExpenses from '../modals/child/all-expenses'
import Chores from '../modals/child/chores'
import Goals from '../modals/child/goals'
import Budget from '../modals/child/budget'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import goal_blue from '../../svg/goal_blue.svg'
import goal_orange from '../../svg/goal_orange.svg'
import chore_blue from '../../svg/chore_blue.svg'
import chore_orange from '../../svg/chore_orange.svg'
import budget_blue from '../../svg/budget_blue.svg'
import budget_orange from '../../svg/budget_orange.svg'
import transaction_blue from '../../svg/transaction_blue.svg'
import transaction_orange from '../../svg/transaction_orange.svg'
import coffee from '../../svg/coffee.svg'
// Expenses icons
import shopping from '../../svg/expenses/shopping.svg'
import gambling from '../../svg/expenses/gambling.svg'
import fees from '../../svg/expenses/fees.svg'
import uncategorized from '../../svg/expenses/uncategorized.svg'
import transport from '../../svg/expenses/transport.svg'

export default class ChildSelfCards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showExpensesModal: false,
            showChoresModal: false,
            showGoalsModal: false,
            showBudgetModal: false
        }
    }

    // Expenses modal
    onShowExpenses = () => {
        this.setState({ showExpensesModal: true })
    }

    // Budget modal
    onShowBudget = () => {
        this.setState({ showBudgetModal: true })
    }

    // Chores modal
    onShowChores = () => {
        this.setState({ showChoresModal: true })
    }

    // Goals modal
    onShowGoals = () => {
        this.setState({ showGoalsModal: true })
    }

    handleCloseModal = () => {
        this.setState({
            showExpensesModal: false,
            showChoresModal: false,
            showGoalsModal: false,
            showBudgetModal: false
        })
    }

    render() {
        const { t } = this.props

        return (
            <>
                {
                    this.state.showExpensesModal ?
                        <AllExpenses
                            childInfo={this.props.childInfo}
                            t={t}
                            handleCloseModal={this.handleCloseModal}
                            showExpensesModal={this.state.showExpensesModal}
                        /> : null
                }
                {
                    this.state.showChoresModal ?
                        <Chores
                            childInfo={this.props.childInfo}
                            t={t}
                            handleCloseModal={this.handleCloseModal}
                            showChoresModal={this.state.showChoresModal}
                        /> : null
                }
                {
                    this.state.showGoalsModal ?
                        <Goals
                            child={this.props.childInfo}
                            t={t}
                            handleCloseModal={this.handleCloseModal}
                            showGoalsModal={this.state.showGoalsModal}
                        /> : null
                }
                {
                    this.state.showBudgetModal ?
                        <Budget
                            child={this.props.childInfo}
                            t={t}
                            handleCloseModal={this.handleCloseModal}
                            showBudgetModal={this.state.showBudgetModal}
                        /> : null
                }
                <div className="yg-cards-grid">
                    <div className="yg-cards-grid-left">
                        <button className="card-button">
                            <div className={this.props.child.color === "1" ? "col-12 card yg-selfcard-blue" : "col-12 card yg-selfcard-orange"}>
                                <div className="yg-selfcard-head">
                                    <span className="yg-headline-label-light-s-black">{t('allowance')}</span>
                                </div>
                                <div className={this.props.child.color === "1" ? "yg-selfcard-alwn-blue" : "yg-selfcard-alwn-orange"}>
                                    <div className="yg-balance-header-left">
                                        <span className="yg-balance-headerleft-name">{t('next')}</span>
                                        <div>
                                            <span className={this.props.child.color === "1" ? "yg-card-number-blue" : "yg-card-number-orange"}>{this.props.childInfo?.allowance?.remainingDay}</span>
                                            <span className="yg-card-number-small-time">{t('days_later')}</span>
                                        </div>
                                    </div>
                                    <div className="yg-balance-header">
                                        <span className="yg-balance-headername">{t('total')}</span>
                                        <div>
                                            <span className="yg-card-number-small-time">£</span>
                                            <span className={this.props.child.color === "1" ? "yg-card-number-blue" : "yg-card-number-orange"}>{this.props.childInfo?.allowance?.amount}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </button>
                        {
                            (window.innerWidth > 769) ?
                                <button onClick={this.onShowExpenses} className="card-button">
                                    <div className={this.props.child.color === "1" ? "col-12 card yg-selfcard-blue" : "col-12 card yg-selfcard-orange"}>
                                        <div className="yg-carddtls-head">
                                            <div className="yg-cc-pb-icon">
                                                {this.props.child.color === "1" ? <img src={transaction_blue} alt="Transaction" className="yg-cc-icon" /> : <img src={transaction_orange} alt="Transaction" className="yg-cc-icon" />}
                                            </div>
                                            <span className="yg-headline-label-light-s-black">{t('expenses')}</span>
                                        </div>
                                        <div className="yg-carddtls-expdtls pt-2 pb-1">
                                            <span className="yg-card-number-small-time">{t('weekly')}</span>
                                            <div>
                                                <span className="yg-card-number-small-time">£</span>
                                                <span className={this.props.child.color === "1" ? "yg-card-number-blue-s" : "yg-card-number-orange-s"}>
                                                    {this.props.childInfo?.expenses?.totalAmount}
                                                </span>
                                            </div>
                                        </div>
                                        <div className={this.props.child.color === "1" ? "yg-carddtls-expenses-blue" : "yg-carddtls-expenses-orange"}>
                                            {this.props.childInfo?.expenses?.data?.slice(0, 3).map((i, k) => {
                                                return (
                                                    <>
                                                        {
                                                            // For avoiding border bottom on last element
                                                            (k + 1 === this.props.childInfo?.expenses?.data?.slice(0, 3).length) ?
                                                                <div key={k} className={this.props.child.color === "1" ? "yg-carddtls-expdtls-blue-last" : "yg-carddtls-expdtls-orange-last"}>
                                                                    <div className="yg-carddtls-exprow">
                                                                        <CircularProgressbarWithChildren
                                                                            className="expenses-progress"
                                                                            value={i.amount}
                                                                            maxValue={this.props.childInfo?.expenses?.totalAmount}
                                                                            styles={buildStyles({
                                                                                pathColor: this.props.child.color === "1" ? "#03A6F7" : "#ff5b02",
                                                                                trailColor: this.props.child.color === "1" ? "#CBEEFF" : "#ffe0cf"
                                                                            })}>
                                                                            <img
                                                                                className="yg-balance-header-img-s"
                                                                                src={
                                                                                    i.transaction_classification[0]?.includes("Shopping") ? shopping :
                                                                                        i.transaction_classification[0]?.includes("Gambling") ? gambling :
                                                                                            i.transaction_classification[0]?.includes("Fees") ? fees :
                                                                                                i.transaction_classification[0]?.includes("Transport") ? transport :
                                                                                                    uncategorized
                                                                                }
                                                                                alt="Source"
                                                                            />
                                                                        </CircularProgressbarWithChildren>
                                                                        <div className="yg-balance-header-left">
                                                                            <span className="yg-cc-topname">{i.transaction_classification[0] === undefined ? t('uncategorized') : i.transaction_classification[0]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="yg-balance-header">
                                                                        <div>
                                                                            <span className="yg-card-alwn-costs-q-small">£</span>
                                                                            <span className="yg-card-number-small">{i.amount}</span>
                                                                        </div>
                                                                    </div>
                                                                </div> :
                                                                <div key={k} className={this.props.child.color === "1" ? "yg-carddtls-expdtls-blue" : "yg-carddtls-expdtls-orange"}>
                                                                    <div className="yg-carddtls-exprow">
                                                                        <CircularProgressbarWithChildren
                                                                            className="expenses-progress"
                                                                            value={i.amount}
                                                                            maxValue={this.props.childInfo?.expenses?.totalAmount}
                                                                            styles={buildStyles({
                                                                                pathColor: this.props.child.color === "1" ? "#03A6F7" : "#ff5b02",
                                                                                trailColor: this.props.child.color === "1" ? "#CBEEFF" : "#ffe0cf"
                                                                            })}>
                                                                            <img
                                                                                className="yg-balance-header-img-s"
                                                                                src={
                                                                                    i.transaction_classification[0]?.includes("Shopping") ? shopping :
                                                                                        i.transaction_classification[0]?.includes("Gambling") ? gambling :
                                                                                            i.transaction_classification[0]?.includes("Fees") ? fees :
                                                                                                i.transaction_classification[0]?.includes("Transport") ? transport :
                                                                                                    uncategorized
                                                                                }
                                                                                alt="Source"
                                                                            />
                                                                        </CircularProgressbarWithChildren>
                                                                        <div className="yg-balance-header-left">
                                                                            <span className="yg-cc-topname">{i.transaction_classification[0] === undefined ? t('uncategorized') : i.transaction_classification[0]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="yg-balance-header">
                                                                        <div>
                                                                            <span className="yg-card-alwn-costs-q-small">£</span>
                                                                            <span className="yg-card-number-small">{i.amount}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </button> : null
                        }
                    </div>
                    <div>
                        {
                            (window.innerWidth > 769) ?
                                <button onClick={this.onShowBudget} className="card-button">
                                    <div className={this.props.child.color === "1" ? "col-12 card yg-selfcard-blue" : "col-12 card yg-selfcard-orange"}>
                                        <div className="yg-selfcard-head">
                                            <div className="yg-cc-pb-icon">
                                                {this.props.child.color === "1" ? <img src={budget_blue} className="yg-cc-icon" alt="Budget" /> : <img src={budget_orange} className="yg-cc-icon" alt="Budget" />}
                                            </div>
                                            <span className="yg-headline-label-light-s-black">{t('todays_budget')}</span>
                                        </div>
                                        <div className="yg-cc-pb-selfcard">
                                            <div className={this.props.child.color === "1" ? "progress yg-pb-blue-selfcard" : "progress yg-pb-orange-selfcard"}>
                                                <div
                                                    className={this.props.child.color === "1" ? "progress-bar yg-pbb-blue" : "progress-bar yg-pbb-orange"}
                                                    role="progressbar"
                                                    style={{ width: this.props.child.todays_budget_percentage }}
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100" />
                                            </div>
                                            <div className="yg-selfcard-costs">
                                                <span className="yg-card-alwn-costs-q-small">£</span>
                                                <span className="yg-card-number-small">{this.props.childInfo?.dailyBudget?.expensesAmount}/{this.props.childInfo?.dailyBudget?.maxBudget}</span>
                                            </div>
                                        </div>
                                    </div>
                                </button> :
                                <>
                                    <button onClick={this.onShowBudget} className="card-button">
                                        <div className={this.props.child.color === "1" ? "col-12 card yg-selfcard-blue" : "col-12 card yg-selfcard-orange"}>
                                            <div className="yg-selfcard-head">
                                                <div className="yg-cc-pb-icon">
                                                    {this.props.child.color === "1" ? <img src={budget_blue} className="yg-cc-icon" alt="Budget" /> : <img src={budget_orange} className="yg-cc-icon" alt="Budget" />}
                                                </div>
                                                <span className="yg-headline-label-light-s-black">{t('todays_budget')}</span>
                                            </div>
                                            <div className="yg-cc-pb-selfcard">
                                                <div className={this.props.child.color === "1" ? "progress yg-pb-blue-selfcard" : "progress yg-pb-orange-selfcard"}>
                                                    <div
                                                        className={this.props.child.color === "1" ? "progress-bar yg-pbb-blue" : "progress-bar yg-pbb-orange"}
                                                        role="progressbar"
                                                        style={{ width: this.props.child.todays_budget_percentage }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100" />
                                                </div>
                                                <div className="yg-selfcard-costs">
                                                    <span className="yg-card-alwn-costs-q-small">£</span>
                                                    <span className="yg-card-number-small">{this.props.child.todays_budget}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                    <button onClick={this.onShowExpenses} className="card-button">
                                        <div className={this.props.child.color === "1" ? "col-12 card yg-selfcard-blue" : "col-12 card yg-selfcard-orange"}>
                                            <div className="yg-carddtls-head">
                                                <div className="yg-cc-pb-icon">
                                                    {this.props.child.color === "Blue" ? <img src={transaction_blue} alt="Transaction" className="yg-cc-icon" /> : <img src={transaction_orange} alt="Transaction" className="yg-cc-icon" />}
                                                </div>
                                                <span className="yg-headline-label-light-s-black">{t('expenses')}</span>
                                            </div>
                                            <div className="yg-carddtls-expdtls pt-2 pb-1">
                                                <span className="yg-card-number-small-time">Today</span>
                                                <div>
                                                    <span className="yg-card-number-small-time">£</span>
                                                    <span className={this.props.child.color === "1" ? "yg-card-number-blue-s" : "yg-card-number-orange-s"}>{this.props.child.allowance_total}</span>
                                                </div>
                                            </div>
                                            <div className={this.props.child.color === "1" ? "yg-carddtls-expenses-blue" : "yg-carddtls-expenses-orange"}>
                                                {this.props.child?.expenses?.map((i, k) => {
                                                    return (
                                                        <div key={k} className={this.props.child.color === "1" ? "yg-carddtls-expdtls-blue" : "yg-carddtls-expdtls-orange"}>
                                                            <div className="yg-carddtls-exprow">
                                                                <img className="yg-balance-header-img" src={coffee} alt="Source" />
                                                                <div className="yg-balance-header-left">
                                                                    <span className="yg-cc-topname">{i.place}</span>
                                                                    <span className="yg-card-number-small-time">{i.time}</span>
                                                                </div>
                                                            </div>
                                                            <div className="yg-balance-header">
                                                                <div>
                                                                    <span className="yg-card-alwn-costs-q-small">£</span>
                                                                    <span className="yg-card-number-small">{i.spent}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </button>
                                </>
                        }
                        <button onClick={this.onShowChores} className="card-button">
                            <div className={this.props.child.color === "1" ? "col-12 card yg-selfcard-blue" : "col-12 card yg-selfcard-orange"}>
                                <div className="yg-selfcard-head">
                                    <div className="yg-cc-pb-icon">
                                        {this.props.child.color === "1" ? <img src={chore_blue} className="yg-cc-icon" alt="Chore" /> : <img src={chore_orange} className="yg-cc-icon" alt="Chore" />}
                                    </div>
                                    <span className="yg-headline-label-light-s-black">{t('chores')}</span>
                                </div>
                                <div className="yg-cc-pb-selfcard">
                                    <div className={this.props.child.color === "1" ? "progress yg-pb-blue-selfcard" : "progress yg-pb-orange-selfcard"}>
                                        <div
                                            className={this.props.child.color === "1" ? "progress-bar yg-pbb-blue" : "progress-bar yg-pbb-orange"}
                                            role="progressbar"
                                            style={{ width: this.props.child.chores_budget_percentage }}
                                            aria-valuenow="25"
                                            aria-valuemin="0"
                                            aria-valuemax="100" />
                                    </div>
                                    <div className="yg-selfcard-costs">
                                        <span className="yg-card-alwn-costs-q-small">£</span>
                                        <span className="yg-card-number-small">{this.props.childInfo?.chores?.completedChoresAmount}/{this.props.childInfo?.chores?.totalChoresAmount}</span>
                                    </div>
                                </div>
                            </div>
                        </button>
                        <button onClick={this.onShowGoals} className="card-button">
                            <div className={this.props.child.color === "1" ? "col-12 card yg-selfcard-blue" : "col-12 card yg-selfcard-orange"}>
                                <div className="yg-selfcard-head">
                                    <div className="yg-cc-pb-icon">
                                        {this.props.child.color === "1" ? <img src={goal_blue} className="yg-cc-icon" alt="Goal" /> : <img src={goal_orange} className="yg-cc-icon" alt="Goal" />}
                                    </div>
                                    <span className="yg-headline-label-light-s-black">{t('goals')}</span>
                                </div>
                                <div className="yg-cc-pb-selfcard">
                                    <div className={this.props.child.color === "1" ? "progress yg-pb-blue-selfcard" : "progress yg-pb-orange-selfcard"}>
                                        <div
                                            className={this.props.child.color === "1" ? "progress-bar yg-pbb-blue" : "progress-bar yg-pbb-orange"}
                                            role="progressbar"
                                            style={{ width: this.props.child.goals_budget_percentage }}
                                            aria-valuenow="25"
                                            aria-valuemin="0"
                                            aria-valuemax="100" />
                                    </div>
                                    <div className="yg-selfcard-costs">
                                        <span className="yg-card-alwn-costs-q-small">£</span>
                                        <span className="yg-card-number-small">{this.props.childInfo?.goals?.totalGoalsTarget}/{this.props.childInfo?.goals?.totalGoalsBalance}</span>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </>
        )
    }
}