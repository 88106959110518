import React, { Component } from 'react';
import Modal from 'react-modal';
import cancel from '../../../svg/cancel.svg';

export default class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSettingsModal: false,
            checked: true,
            notificationsList: true
        }
    }

    onShowSettings = () => {
        this.setState({
            showSettingsModal: true
        })
    }

    onClearAll = () => {
        this.setState({
            notificationsList: false
        })
    }

    render() {
        const { t } = this.props;
        return (
            <Modal
                isOpen={this.props.showNotifications}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                {
                    this.state.showSettingsModal ?
                        <div className="w-80">
                            <span className="yg-greeting-name">{t('notification_settings')}</span>
                            <div className="yg-topnav-mb-send mt-5 mb-2"><span className="yg-headline-label-dark-xs pr-4">{t('email')}</span></div>
                            <div className="yg-gradient untrg-savings mb-4 w-100">
                                <div className="yg-balance-header-left">
                                    <span className="yg-headline-label-dark-xs py-3">{t('all_notifications')}</span>
                                    <span className="yg-card-alwn-days pb-3">{t('bank_name')}</span>
                                </div>
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <span className="yg-headline-label-dark-xs">{t('parents_notification')}</span>
                            <div className="yg-gradient untrg-savings mt-2 mb-4">
                                <div className="yg-balance-header-left">
                                    <span className="yg-headline-label-dark-xs py-3">{t('allowance_reminder')}</span>
                                </div>
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <span className="yg-headline-label-dark-xs">{t('childs_reminder')}</span>
                            <div className="yg-gradient">
                                <div className="untrg-savings mt-2">
                                    <div className="yg-balance-header-left">
                                        <span className="yg-headline-label-dark-xs py-3">{t('low_balance')}</span>
                                    </div>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="untrg-savings w-100">
                                    <div className="yg-balance-header-left w-70">
                                        <span className="yg-headline-label-dark-xs py-3">{t('spendings')}</span>
                                    </div>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="untrg-savings w-100">
                                    <div className="yg-balance-header-left w-70">
                                        <span className="yg-headline-label-dark-xs py-3">{t('completed_chores')}</span>
                                    </div>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="untrg-savings w-100">
                                    <div className="yg-balance-header-left w-70">
                                        <span className="yg-headline-label-dark-xs py-3">{t('completed_goals')}</span>
                                    </div>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="untrg-savings mb-3 w-100">
                                    <div className="yg-balance-header-left w-70">
                                        <span className="yg-headline-label-dark-xs py-3">{t('money_request')}</span>
                                    </div>
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div> :
                        <div className="w-80">
                            <span className="yg-greeting-name">{t('notifications')}</span>
                            <div className="yg-btn-form-lets-start pb-4 pt-5">
                                <button
                                    type="button"
                                    className="btn  yg-btn-thin yg-btn-black"
                                    onClick={this.onShowSettings}
                                >
                                    <span className="yg-headline-label-light-xs-white">{t('settings')}</span>
                                </button>
                                <button
                                    type="button"
                                    className="btn  yg-btn-thin yg-btn-white"
                                    onClick={this.onClearAll}
                                >
                                    <span className="yg-headline-label-light-xs-black">{t('clear_all')}</span>
                                </button>
                            </div>
                            {
                                !this.state.notificationsList ? null :
                                    <>
                                        <div className="pt-5">
                                            <span className="yg-card-alwn-costs-q-xs">TODAY</span>
                                            {/*
                                                this.props.children.map((i, k) => {
                                                    return (
                                                        <div key={k} className="yg-card-head mt-2 py-2">
                                                            <div>
                                                                <img src={i.avatar} alt="Avatar" className="yg-card-avatar-min" />
                                                                <span className="card-header yg-payments-span">{i.name}{t('s_weekly_allowance')}</span>
                                                            </div>
                                                            <div>
                                                                <span className="yg-card-number-small-time">3 hours ago</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })*/
                                            }
                                        </div>
                                        <div className="pt-4">
                                            <span className="yg-card-alwn-costs-q-xs">17 SEPTEMBER</span>
                                            {/*
                                                this.props.children.map((i, k) => {
                                                    return (
                                                        <div key={k} className="yg-card-head mt-2 py-2">
                                                            <div>
                                                                <img src={i.avatar} alt="Avatar" className="yg-card-avatar-min" />
                                                                <span className="card-header yg-payments-span">{t('extra_allowance_to')}{i.name}</span>
                                                            </div>
                                                            <div>
                                                                <span className="yg-card-number-small-time">14:50</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })*/
                                            }
                                        </div>
                                    </>
                            }
                        </div>
                }
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </Modal>
        );
    }
}