import React, { Component } from 'react'
import DatePicker from 'react-date-picker'
import CheckEmail from './check-email'
import profile_pic from '../../../svg/profile_pic.svg'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
import { connect } from 'react-redux'
import ParentActions from '../../../reducers/ParentRedux'
import { ToastContainer, toast } from 'react-toastify'

class AddChild extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            phone: '',
            gender: 'male',
            color: 'blue',
            email: '',
            birthday: new Date(),
            checked: false,
            avatar: [],
            avatarUploaded: false,
            childAdded: false,
            childAddedToCognito: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.parent.addChildResponse !== null && this.state.childAddedToCognito) {
            if (this.props.parent.addChildResponse !== prevProps.parent.addChildResponse && this.props.parent.addChildResponse.data.data.temporaryPassword !== undefined
                || this.props.parent.addChildResponse.data.data.temporaryPassword !== "" || this.props.parent.addChildResponse.data.data.temporaryPassword !== null) {
                toast.success('Child successfully added!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
                setTimeout(() => {
                    this.props.handleSendData(this.props.parent.addChildResponse.data.data)
                    this.props.handleGoToNextStep(true)
                }, 1500)
            }
        }
    }

    componentWillUnmount() {
        this.setState({ childAddedToCognito: false })
    }

    onNameChange = (e) => {
        this.setState({ name: e.target.value })
    }

    onEmailChange = (e) => {
        this.setState({ email: e.target.value })
    }
    onPhoneChange = (e) => {
        this.setState({ phone: e.target.value })
    }

    onGenderChange = (e) => {
        this.setState({ gender: e.target.id })
    }

    onColorChange = (e) => {
        this.setState({ color: e.target.id })
    }

    onUploadAvatar = (e) => {
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0]
            this.setState({
                avatar: URL.createObjectURL(img),
                avatarUploaded: true
            })
        }
    }

    onAddChild = async () => {
        this.props.addChildRequest({
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            birthdate: "1999.02.10",
            gender: this.state.gender,
            color: this.state.color === 'blue' ? 1 : 2
        })
        this.setState({
            childAddedToCognito: true
        })
    }

    onChangeBirthday = (e) => {
        this.setState({
            birthday: e
        })
    }

    render() {
        const { t } = this.props

        return (
            <>
                {
                    this.state.childAdded ? <CheckEmail t={t} handleGoToNextStep={this.props.handleGoToNextStep} username={this.state.email} /> :
                        <div className="create-accout-form pt-5">
                            <div className="form-group yg-form">
                                <div className="create-account-form-group pt-4">
                                    <div>
                                        <span className="yg-headline-label-light-s-black">{t('profile_photo')}</span>
                                        <div className="image-upload">
                                            <label htmlFor="yg-profile-photo" className="yg-headline-label-light-s-black">
                                                {
                                                    !this.state.avatarUploaded ?
                                                        <img src={profile_pic} alt="Profile" className="yg-profile-pic" /> :
                                                        <img src={this.state.avatar} alt="Profile" className="yg-profile-pic-uploaded" />
                                                }
                                            </label>
                                            <input type="file" className="form-control" id="yg-profile-photo" onChange={this.onUploadAvatar} />
                                        </div>
                                    </div>
                                    <div></div>
                                    <div className="yg-password-form-div">
                                        <label htmlFor="yg-name" className="yg-headline-label-light-s-black">{t('childs_name')}</label>
                                        <div className="yg-password-form">
                                            <input
                                                placeholder={t('ellie_smith')}
                                                name="name"
                                                type="text"
                                                className="form-control yg-form-input"
                                                id="yg-name"
                                                value={this.state.name}
                                                onChange={this.onNameChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="yg-password-form-div">
                                        <label htmlFor="yg-email" className="yg-headline-label-light-s-black">{t('email')}</label>
                                        <div className="yg-password-form">
                                            <input
                                                placeholder={t('placeholder_email')}
                                                name="email"
                                                type="text"
                                                className="form-control yg-form-input"
                                                id="yg-email"
                                                value={this.state.email}
                                                onChange={this.onEmailChange}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="yg-gender" className="yg-headline-label-light-s-black">{t('gender')}</label>
                                        <div className="yg-radio-form-sa">
                                            <div className="yg-gradient">
                                                <input type="radio" id="male" name="gender" className="rb-input" onChange={this.onGenderChange} checked={this.state.gender === 'male'} />
                                                <label htmlFor="male" className="rb-label" >{t('male')}</label>
                                                <input type="radio" id="female" name="gender" className="rb-input" onChange={this.onGenderChange} checked={this.state.gender === 'female'} />
                                                <label htmlFor="female" className="rb-label" >{t('female')}</label>
                                                <input type="radio" id="non_binary" name="gender" className="rb-input" onChange={this.onGenderChange} checked={this.state.gender === 'non_binary'} />
                                                <label htmlFor="non_binary" className="rb-label" >{t('non_binary')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="yg-color" className="yg-headline-label-light-s-black">{t('color')}</label>
                                        <div className="yg-radio-form-color">
                                            <input type="radio" id="blue" name="color" className="rb-input-color-blue" onChange={this.onColorChange} checked={this.state.color === 'blue'} />
                                            <input type="radio" id="orange" name="color" className="rb-input-color-orange" onChange={this.onColorChange} checked={this.state.color === 'orange'} />
                                        </div>
                                    </div>
                                    <div className="yg-password-form-div">
                                        <label htmlFor="yg-phone" className="yg-headline-label-light-s-black">{t('phone_number')}</label>
                                        <div className="yg-password-form">
                                            <input
                                                placeholder={t('phone_template')}
                                                name="phone"
                                                type="text"
                                                className="form-control yg-form-input"
                                                id="yg-phone"
                                                value={this.state.phone}
                                                onChange={this.onPhoneChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="yg-password-form-div">
                                        <label htmlFor="yg-birthday" className="yg-headline-label-light-s-black">{t('date_of_birth')}</label>
                                        <div className="yg-password-form">
                                            <DatePicker
                                                value={this.state.birthday}
                                                onChange={this.onChangeBirthday}
                                                format="dd/MM/yyyy"
                                                className="form-control yg-form-input birthday-picker"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            this.props.parent.fetchingApiButton ?
                                                <button
                                                    type="button"
                                                    className="btn yg-btn yg-btn-black"
                                                >
                                                    <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                                                </button> :
                                                <button
                                                    type="button"
                                                    onClick={this.onAddChild}
                                                    className="btn yg-btn yg-btn-black"
                                                    disabled={this.state.name?.length === 0 || this.state.email?.length === 0 || this.state.phone?.length === 0 || this.state.gender?.length === 0 || this.state.color?.length === 0 ? true : false}
                                                >
                                                    {t('add')}
                                                </button>
                                        }
                                    </div>
                                    <ToastContainer
                                        position="bottom-left"
                                        className="toast-container-mobile"
                                        autoClose={1000}
                                        hideProgressBar
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            </div>
                        </div>
                }
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addChildRequest: (body) => dispatch(ParentActions.addChildRequest(body)),
    }
}
const mapStateToProps = (state) => {
    return {
        parent: state.parent
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddChild)