import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setLoader: ['value']
});

export const MainTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    loader: false,
});

/* ------------- Reducers ------------- */

export const setLoader = (state, action) => {
    return state.merge({ loader: action.value })
}

/* ------------- Connection Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_LOADER]: setLoader,
})