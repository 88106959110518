import React, { Component } from 'react'
import LetStart from '../child/lets-start'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
import BankActions from '../../../reducers/BankRedux'
import { ToastContainer, toast } from 'react-toastify'

class BankAccounts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectMainAccount: false,
            sandboxUrl: "",
            urlReceived: false,
            message: "",
            goToNextStep: false,
            bankAccounts: [],
            accountChosen: false,
            redirectToLetsStartForm: false,
            account: {},
            chooseSavingsAccount: false
        }
    }

    componentDidMount() {
        this.props.getAccountsRequest({
            code: this.props.code
        })
        this.setState({
            urlReceived: true,
            displayBankAccounts: true
        })
    }

    componentDidUpdate() {
        if (this.state.message.length > 0 && this.state.goToNextStep) {
            this.setState({
                selectMainAccount: true,
                goToNextStep: false
            })
        }
        if (this.props.bank.getAccountsResponse !== null && this.props.bank.getAccountsResponse?.data?.data?.results.length > 0 && this.state.displayBankAccounts) {
            this.setState({
                bankAccounts: this.props.bank.getAccountsResponse.data.data.results,
                displayBankAccounts: false
            })
        }
        if (this.props.bank.setAccountResponse !== null && this.props.bank.setAccountResponse?.data?.success && this.state.accountChosen) {
            toast.success('Bank account added successfully!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            this.setState({ accountChosen: false })
            setTimeout(() => {
                const { t } = this.props;
                if (this.props.role === "child") {
                    this.setState({ redirectToLetsStartForm: true })
                } else this.props.handleGoToNextStep(true)
            }, 1500);
        }
    }

    onChooseAccount = (account) => {
        this.setState({
            account: account
        })
    }

    onSaveMainAccount = () => {
        this.props.setAccountRequest({
            accountId: this.state.account.account_id,
            accountName: this.state.account.display_name,
            accountIban: this.state.account.account_number.iban,
            providerName: this.state.account.provider.display_name,
            accountType: 1
        })
        if (this.props.role === "parent") {
            this.setState({
                accountChosen: true
            })
        } else {
            this.setState({
                chooseSavingsAccount: true
            })
        }
    }

    onSaveSavingsAccount = () => {
        this.props.setAccountRequest({
            accountId: this.state.account.account_id,
            accountName: this.state.account.display_name,
            accountIban: this.state.account.account_number.iban,
            providerName: this.state.account.provider.display_name,
            accountType: 2
        })
        this.setState({
            accountChosen: true
        })
    }

    render() {
        const { t } = this.props

        return (
            <>
                {
                    this.state.redirectToLetsStartForm ? <LetStart t={t} /> :
                        this.state.chooseSavingsAccount ?
                            <div className="bank-aclist-form">
                                <span className="yg-headline-label pb-5">{t('you_should_select_savings_bank_account')}</span>
                                <div className="bank-list w-100">
                                    {
                                        (this.state.bankAccounts?.filter((i) => i.display_name?.includes("SAVINGS"))).map((i, k) => {
                                            return (
                                                <button
                                                    className="yg-gradient bank-accounts w-100 mb-3"
                                                    onClick={() => this.onChooseAccount(i)}
                                                    key={k}
                                                >
                                                    <span className="yg-headline-label-light-s-black pt-3 pb-5">{i.display_name}</span>
                                                    <span className="yg-card-alwn-days">{i.display_name}</span>
                                                    <span className="yg-headline-label-light-xs-black pt-2 pb-3">{i.account_number.number}</span>
                                                </button>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    this.props.bank.fetchingApiButton ?
                                        <button
                                            type="button"
                                            className="btn yg-btn yg-btn-black mt-5"
                                        >
                                            <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                                        </button> :
                                        <button
                                            type="button"
                                            onClick={this.onSaveSavingsAccount}
                                            className="btn yg-btn yg-btn-black mt-5"
                                            disabled={this.state.account === {} ? true : false}
                                        >
                                            {t('save')}
                                        </button>
                                }
                            </div> :
                            <div className="bank-aclist-form">
                                <span className="yg-codelogin-headline-main pb-2">{t('your_bank_integration_is_complete')}</span>
                                <span className="yg-headline-label pb-5">{t('you_should_select_main_bank_account')}</span>
                                <div className="bank-list w-100">
                                    {
                                        (this.state.bankAccounts?.filter((i) => i.display_name?.includes("TRANSACTION"))).map((i, k) => {
                                            return (
                                                <button
                                                    className="yg-gradient bank-accounts w-100 mb-3"
                                                    onClick={() => this.onChooseAccount(i)}
                                                    key={k}
                                                >
                                                    <span className="yg-headline-label-light-s-black pt-3 pb-5">{i.display_name}</span>
                                                    <span className="yg-card-alwn-days">{i.display_name}</span>
                                                    <span className="yg-headline-label-light-xs-black pt-2 pb-3">{i.account_number.number}</span>
                                                </button>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    this.props.bank.fetchingApiButton ?
                                        <button
                                            type="button"
                                            className="btn yg-btn yg-btn-black mt-5"
                                        >
                                            <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                                        </button> :
                                        <button
                                            type="button"
                                            onClick={this.onSaveMainAccount}
                                            className="btn yg-btn yg-btn-black mt-5"
                                            disabled={this.state.account === {} ? true : false}
                                        >
                                            {t('save')}
                                        </button>
                                }
                            </div>
                }
                <ToastContainer
                    position="bottom-left"
                    className="toast-container-mobile"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAccountsRequest: (body) => dispatch(BankActions.getAccountsRequest(body)),
        setAccountRequest: (body) => dispatch(BankActions.setAccountRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        bank: state.bank
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BankAccounts))