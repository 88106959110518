import React, { Component } from 'react'
import Modal from 'react-modal'
import cancel from '../../../svg/cancel.svg'
import { connect } from 'react-redux'
import ParentActions from '../../../reducers/ParentRedux'
import { ToastContainer, toast } from 'react-toastify'
import Select from 'react-select'

class SetAllowance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: "5",
            period: "weekly",
            allowance_day: "",
            otherValue: 0,
            setAllowanceSet: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.parent.setAllowanceResponse !== null && this.state.setAllowanceSet) {
            if (this.props.parent.setAllowanceResponse.success !== false || this.props.parent.setAllowanceResponse.success !== undefined || this.props.parent.setAllowanceResponse.success !== null) {
                toast.success('Allowance is set!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(() => {
                    this.setState({
                        setAllowanceSet: false
                    })
                    this.props.handleCloseModal(true)
                }, 2000);
            }
        }
    }

    onPeriodChange = (e) => {
        this.setState({
            period: e.target.id,
            allowance_day: ""
        })
    }

    onAmountChange = (e) => {
        this.setState({
            amount: e.target.id
        })
    }

    onOtherChange = (e) => {
        this.setState({
            otherValue: e.target.value
        })
    }

    onSetAllowance = () => {
        this.props.setAllowanceRequest({
            child_sub: this.props.child.sub,
            period: this.state.period === "weekly" ? 1 : 2,
            amount: this.state.amount === "other" ? this.state.otherValue : this.state.amount,
            day: this.state.allowance_day.value
        })
        this.setState({
            setAllowanceSet: true
        })
    }

    onChangeAllowance = (value) => {
        this.setState({
            allowance_day: value
        })
    }

    render() {
        const { t } = this.props;
        let child = this.props.child;

        const customStyles = {
            menu: () => ({
                backgroundColor: child.color === "1" ? '#effaff' : '#fff1ea',
                marginTop: '5px',
                borderRadius: '12px',
                padding: '7px 0'
            }),
            control: () => ({
                padding: '5px 15px',
                borderRadius: '12px',
                border: 'none',
                display: 'flex',
                backgroundColor: child.color === "1" ? '#effaff' : '#fff1ea'
            }),
            option: (styles, { isFocused, isSelected }) => ({
                cursor: 'pointer',
                padding: '10px 15px',
                color: child.color === "1" ? (isSelected ? '#03A6F7' : isFocused ? '#03A6F7' : undefined) : (isSelected ? '#ff5b02' : isFocused ? '#ff5b02' : undefined)
            })
        }

        let weeklyOptions = [
            {
                value: 1,
                label: 'Monday'
            },
            {
                value: 2,
                label: 'Tuesday'
            },
            {
                value: 3,
                label: 'Wednesday'
            },
            {
                value: 4,
                label: 'Thursday'
            },
            {
                value: 5,
                label: 'Friday'
            },
            {
                value: 6,
                label: 'Saturday'
            },
            {
                value: 7,
                label: 'Sunday'
            }
        ]

        let monthlyOptions = [
            {
                value: 1,
                label: 'First Week'
            },
            {
                value: 2,
                label: 'Second Week'
            },
            {
                value: 3,
                label: 'Third Week'
            },
            {
                value: 4,
                label: 'Fourth Week'
            }
        ]

        return (
            <Modal
                isOpen={this.props.showAllowanceModal}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                <div className="w-80">
                    <span className="yg-greeting-name">{child.name}{t('s_allowance')}</span>
                    <div>
                        <div className="yg-password-form-div pt-5">
                            <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('period')}</label>
                            <div className="yg-radio-form-sa pt-2">
                                <div className={child.color === "1" ? "yg-gradient-blue" : "yg-gradient-orange"}>
                                    <input type="radio" id="weekly" name="period" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} onChange={this.onPeriodChange} checked={this.state.period === 'weekly'} />
                                    <label htmlFor="weekly" className="rb-label" >{t('weekly')}</label>
                                    <input type="radio" id="monthly" name="period" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} onChange={this.onPeriodChange} checked={this.state.period === 'monthly'} />
                                    <label htmlFor="monthly" className="rb-label" >{t('monthly')}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="yg-password-form-div">
                            <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('amount')}</label>
                            {
                                this.state.period === "weekly" ?
                                    <div className={child.color === "1" ? "yg-gradient-blue" : "yg-gradient-orange"}>
                                        <input type="radio" id="5" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "5"} onChange={this.onAmountChange} />
                                        <label htmlFor="5" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>5</label>
                                        <input type="radio" id="10" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "10"} onChange={this.onAmountChange} />
                                        <label htmlFor="10" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>10</label>
                                        <input type="radio" id="15" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "15"} onChange={this.onAmountChange} />
                                        <label htmlFor="15" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>15</label>
                                        <input type="radio" id="other" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "other"} onChange={this.onAmountChange} />
                                        <label htmlFor="other" className="rb-label-dashboard">{t('other')}</label>
                                    </div> :
                                    <div className={child.color === "1" ? "yg-gradient-blue" : "yg-gradient-orange"}>
                                        <input type="radio" id="20" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "20"} onChange={this.onAmountChange} />
                                        <label htmlFor="20" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>20</label>
                                        <input type="radio" id="30" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "30"} onChange={this.onAmountChange} />
                                        <label htmlFor="30" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>30</label>
                                        <input type="radio" id="40" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "40"} onChange={this.onAmountChange} />
                                        <label htmlFor="40" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>40</label>
                                        <input type="radio" id="other" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "other"} onChange={this.onAmountChange} />
                                        <label htmlFor="other" className="rb-label-dashboard">{t('other')}</label>
                                    </div>
                            }
                        </div>
                    </div>
                    {
                        this.state.amount === "other" ?
                            <div className="yg-password-form-div">
                                <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('other_amount')}</label>
                                <div className="yg-radio-form-sa pt-2">
                                    <div className="yg-input-other w-100">
                                        <span className="yg-card-number-small-time">£</span>
                                        <input
                                            type="number"
                                            className={child.color === "1" ? "rb-input-text-blue p-10-5" : "rb-input-text-orange p-10-5"}
                                            onChange={this.onOtherChange} />
                                    </div>
                                </div>
                            </div> : null
                    }
                    <div className="yg-password-form-div">
                        <label htmlFor="yg-allowance-day" className="yg-headline-label-dark">{t('allowance_day')}</label>
                        <Select
                            className="allowance-select"
                            onChange={this.onChangeAllowance}
                            options={this.state.period === "weekly" ? weeklyOptions : monthlyOptions}
                            isSearchable={false}
                            styles={customStyles}
                        />
                    </div>
                    <div className="yg-btn-form" style={{ display: 'flex' }}>
                        <button
                            type="button"
                            className="btn  yg-btn yg-btn-black"
                            onClick={this.onSetAllowance}
                            disabled={(this.state.allowance_day.length === 0 ? true : false) || (this.state.amount === "other" && this.state.otherValue === "")}
                        >
                            {t('save')}
                        </button>
                        <button
                            type="button"
                            className="btn  yg-btn yg-btn-gray"
                            onClick={this.props.handleCloseModal}
                        >
                            {t('cancel')}
                        </button>
                    </div>
                </div>
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
                <ToastContainer
                    position="bottom-left"
                    className="toast-container-mobile"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAllowanceRequest: (body) => dispatch(ParentActions.setAllowanceRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        parent: state.parent
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SetAllowance)