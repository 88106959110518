import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    choresListRequest: ['body'],
    choresListSuccess: ['data'],
    choresListFailure: ['error'],

    goalsListRequest: ['body'],
    goalsListSuccess: ['data'],
    goalsListFailure: ['error'],
});

export const CommonTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    choresListResponse: null,
    choresListError: false,
    choresListErrorMessage: '',

    goalsListResponse: null,
    goalsListError: false,
    goalsListErrorMessage: '',
});

/* ------------- Chores List ------------- */
export const choresListRequest = (state, action) => {
    return state.merge({
        choresListResponse: null,
        choresListError: false,
        choresListErrorMessage: ''
    })
}
export const choresListSuccess = (state, action) => {
    return state.merge({
        choresListResponse: action.data,
        choresListError: false,
        choresListErrorMessage: ''
    })
}
export const choresListFailure = (state, action) => {
    return state.merge({
        choresListError: true,
        choresListErrorMessage: action.error
    })
}

/* ------------- Goals List ------------- */
export const goalsListRequest = (state, action) => {
    return state.merge({
        goalsListResponse: null,
        goalsListError: false,
        goalsListErrorMessage: ''
    })
}
export const goalsListSuccess = (state, action) => {
    return state.merge({
        goalsListResponse: action.data,
        goalsListError: false,
        goalsListErrorMessage: ''
    })
}
export const goalsListFailure = (state, action) => {
    return state.merge({
        goalssListError: true,
        goalsListErrorMessage: action.error
    })
}

/* ------------- Connection Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.CHORES_LIST_REQUEST]: choresListRequest,
    [Types.CHORES_LIST_SUCCESS]: choresListSuccess,
    [Types.CHORES_LIST_FAILURE]: choresListFailure,

    [Types.GOALS_LIST_REQUEST]: goalsListRequest,
    [Types.GOALS_LIST_SUCCESS]: goalsListSuccess,
    [Types.GOALS_LIST_FAILURE]: goalsListFailure
})