import { call, put } from 'redux-saga/effects'
import ChildTypes from '../reducers/ChildRedux'
import { Auth } from 'aws-amplify'

const getToken = async () => {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
}

export function* getChildDashboard(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.getChildDashboard, token);
        if (response.hasOwnProperty('error')) {
            yield put(ChildTypes.getChildDashboardFailure(response))
        } else {
            yield put(ChildTypes.getChildDashboardSuccess(response))
        }
    } catch (error) {
        yield put(ChildTypes.getChildDashboardFailure(error))
    }
}

// DAILY BUDGET

export function* getDailyBudget(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.getDailyBudget, token);
        if (response.hasOwnProperty('error')) {
            yield put(ChildTypes.getDailyBudgetFailure(response))
        } else {
            yield put(ChildTypes.getDailyBudgetSuccess(response))
        }
    } catch (error) {
        yield put(ChildTypes.getDailyBudgetFailure(error))
    }
}

export function* setDailyBudget(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.setDailyBudget, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(ChildTypes.setDailyBudgetFailure(response))
        } else {
            yield put(ChildTypes.setDailyBudgetSuccess(response))
        }
    } catch (error) {
        yield put(ChildTypes.setDailyBudgetFailure(error))
    }
}

// CHORES

export function* completeChores(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.completeChores, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(ChildTypes.completeChoresFailure(response))
        } else {
            yield put(ChildTypes.completeChoresSuccess(response))
        }
    } catch (error) {
        yield put(ChildTypes.completeChoresFailure(error))
    }
}
