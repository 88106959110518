import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from 'react-router-dom'

function ConnectBankSuccess({ history }) {
    let location = useLocation()
    const [code, setCode] = useState(null)

    useEffect(() => {
        console.log("location", location)
        const code = new URLSearchParams(location.search).get("code");
        console.log("code", code)
        setCode(code)
    }, [location])

    const close = () => {
        window.opener.onSuccess(code)
        window.close()
    }

    return (
        <div>
            <h1>Connect Bank Success</h1>
            <button onClick={close}>Go Home</button>
        </div>
    )
}

export default withRouter(ConnectBankSuccess);