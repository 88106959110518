import React, { Component } from 'react'
import { connect } from 'react-redux'
import ParentActions from '../../../../reducers/ParentRedux'
import { ToastContainer, toast } from 'react-toastify'

class AddGoal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            amount: "5",
            otherValue: 0,
            goalAdded: false,
            onSaveButtonClicked: false
        }
    }

    componentDidUpdate() {
        if (this.props.parent?.addGoalResponse?.data?.messages?.message === "Goal added successfully" && this.state.goalAdded) {
            toast.success('Goal successfully added!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            this.setState({
                goalAdded: false
            })
            setTimeout(() => {
                this.props.handleCloseModal(true)
            }, 1500)
        } else if (this.props.parent?.addGoalResponse?.response?.data?.messages?.message === "Please provide child_sub, name, period, amount attributes" && this.state.onSaveButtonClicked) {
            toast.error('Please fill up all fields!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            this.setState({
                onSaveButtonClicked: false
            })
        }
    }

    onNameChange = (e) => {
        this.setState({
            name: e.target.value
        })
    }

    onAmountChange = (e) => {
        this.setState({
            amount: e.target.id
        })
    }

    onOtherChange = (e) => {
        this.setState({
            otherValue: e.target.value
        })
    }

    onAddGoalToApi = () => {
        this.props.addGoalRequest({
            child_sub: this.props.selectedChild.sub,
            name: this.state.name,
            target: this.state.amount === "other" ? this.state.otherValue : this.state.amount
        })
        this.setState({
            goalAdded: true,
            onSaveButtonClicked: true
        })
    }

    render() {
        const { t } = this.props
        let child = this.props.selectedChild

        return (
            <div>
                <span className="yg-greeting-name">
                    {t('add_goal')}
                </span>
                <div className="yg-password-form-div mt-5">
                    <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('goal_name')}</label>
                    <div className="yg-radio-form-sa pt-2">
                        <div className={child.color === "1" ? "yg-gradient-blue w-100" : "yg-gradient-orange w-100"}>
                            <input
                                type="text"
                                className={child.color === "1" ? "rb-input-text-blue" : "rb-input-text-orange"}
                                onChange={this.onNameChange} />
                        </div>
                    </div>
                </div>
                <div className="yg-password-form-div">
                    <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('amount')}</label>
                    <div className={child.color === "1" ? "yg-gradient-blue" : "yg-gradient-orange"}>
                        <input type="radio" id="5" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "5"} onChange={this.onAmountChange} />
                        <label htmlFor="5" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>5</label>
                        <input type="radio" id="10" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "10"} onChange={this.onAmountChange} />
                        <label htmlFor="10" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>10</label>
                        <input type="radio" id="15" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "15"} onChange={this.onAmountChange} />
                        <label htmlFor="15" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>15</label>
                        <input type="radio" id="other" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "other"} onChange={this.onAmountChange} />
                        <label htmlFor="other" className="rb-label-dashboard">{t('other')}</label>
                    </div>
                </div>
                {
                    this.state.amount === "other" ?
                        <div className="yg-password-form-div">
                            <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('other_amount')}</label>
                            <div className="yg-radio-form-sa pt-2">
                                <div className="yg-input-other w-100">
                                    <span className="yg-card-number-small-time">£</span>
                                    <input
                                        type="number"
                                        className={child.color === "1" ? "rb-input-text-blue p-10-5" : "rb-input-text-orange p-10-5"}
                                        onChange={this.onOtherChange} />
                                </div>
                            </div>
                        </div> : null
                }
                <div className="yg-btn-form" style={{ display: 'flex' }}>
                    <button
                        type="button"
                        className="btn yg-btn yg-btn-black"
                        onClick={this.onAddGoalToApi}
                        disabled={(this.state.name.length === 0 || (this.state.amount === "other" ? this.state.otherValue <= 0 : this.state.amount.length === 0)) ? true : false}
                    >
                        {t('save')}
                    </button>
                    <button
                        type="button"
                        className="btn yg-btn yg-btn-gray"
                        onClick={() => this.props.handleCloseModal(true)}
                    >
                        {t('cancel')}
                    </button>
                </div>
                <ToastContainer
                    position="bottom-left"
                    className="toast-container-mobile"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addGoalRequest: (body) => dispatch(ParentActions.addGoalRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        parent: state.parent
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddGoal)