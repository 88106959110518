import React, { Component } from 'react'
import Modal from 'react-modal'
import cancel from '../../../svg/cancel.svg'
import { connect } from 'react-redux'
import ChildActions from '../../../reducers/ChildRedux'
import CommonActions from '../../../reducers/CommonRedux'
import { ToastContainer, toast } from 'react-toastify'
import PureModal from 'react-pure-modal'
import 'react-pure-modal/dist/react-pure-modal.min.css'

class Chores extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addChoreModal: false,
            amount: "5",
            period: "onetime",
            choreName: "",
            changeChoreNameValue: false,
            confirmModal: false,
            todo: [],
            done: [],
            earned: []
        }
    }

    componentDidMount() {
        this.props.choresListRequest({
            userId: this.props.user.userData.attributes.sub
        })
        setTimeout(() => {
            this.setState({
                todo: this.props.common?.choresListResponse?.data?.data.todo,
                done: this.props.common?.choresListResponse?.data?.data.done,
                earned: this.props.common?.choresListResponse?.data?.data.earned
            })
        }, 500)
    }

    componentDidUpdate(prevProps, prevState) {
        /*this.props.choresListRequest({
            userId: this.props.user.userData.attributes.sub
        })
        setTimeout(() => {
            this.setState({
                todo: this.props.common?.choresListResponse?.data?.data.todo,
                done: this.props.common?.choresListResponse?.data?.data.done,
                earned: this.props.common?.choresListResponse?.data?.data.earned
            })
        }, 500)*/
    }

    onAddChore = () => {
        this.setState({
            addChoreModal: true
        })
    }

    onAmountChange = (e) => {
        this.setState({
            amount: e.target.id
        })
    }

    onPeriodChange = (e) => {
        this.setState({
            period: e.target.id
        })
    }

    onChoreNameChange = (e) => {
        this.setState({
            choreName: e.target.value
        })
    }

    handleChangeChoreName = (changeChoreName) => {
        this.setState({
            changeChoreNameValue: changeChoreName
        })
    }

    onConfirmChore = (e, item) => {
        if (e.target.checked) {
            this.setState({
                confirmModal: true
            })
        }
    }

    onCloseConfirmModal = () => {
        this.setState({
            confirmModal: false
        })
    }

    onConfirmChoreApi = (e, item) => {
        this.props.completeChoresRequest({
            // id: "7a93c0b2-1336-4246-bec2-cfe3a450b0a4", // Chore ID
            completed: true
        })
    }

    render() {
        const { t } = this.props
        let child = this.props.childInfo

        return (
            <Modal
                isOpen={this.props.showChoresModal}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                <div className={window.innerWidth < 769 ? "w-90" : "w-70"}>
                    <span className="yg-greeting-name">{t('chores')}</span>
                    <div className="yg-cc-pb-top pt-5">
                        <span className="yg-headline-label-light-xs-black">{t('to_do')}</span>
                        <span className="yg-headline-label-light-xs-black">{this.state.todo?.length}&nbsp;{t('chores')}</span>
                    </div>
                    <div>
                        <div className="yg-password-form-div">
                            {
                                this.state.todo?.map((i, k) => {
                                    return (
                                        <div
                                            key={k}
                                            className={this.props.user.userData.attributes["custom:color"] === "2" ? "yg-expenses-form-div-details-white-primary" : "yg-expenses-form-div-details-secondary-white"}
                                        >
                                            <div className="yg-balance-header-left">
                                                <span className={i.backcolor === "secondary-white" ? "yg-headline-label-light-xs-blue" : "yg-headline-label-light-xs-orange"}>
                                                    {i?.name}
                                                </span>
                                                <div>
                                                    <span className="pound-gray">£</span>
                                                    <span className={i.backcolor === "secondary-white" ? "yg-headline-label-light-s-blue" : "yg-headline-label-light-s-orange"}>
                                                        {i.amount}
                                                    </span>
                                                    <span className="pound-gray">
                                                        /{i.period}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="form-check">
                                                <label className="yg-check">
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => this.onConfirmChore(e, i)}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <PureModal
                                                header={t('are_you_sure_you_finished_this_chore')}
                                                footer={
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn yg-btn-min yg-btn-white-w"
                                                            onClick={(e) => this.onConfirmChoreApi(e, i)}
                                                        >
                                                            {t('yes_i_finished')}
                                                        </button>
                                                    </div>
                                                }
                                                isOpen={this.state.confirmModal}
                                                onClose={this.onCloseConfirmModal}
                                            >
                                                <p>{t('your_parent_will_be_notified_chore')}</p>
                                            </PureModal>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="yg-cc-pb-top">
                        <span className="yg-headline-label-light-xs-black">{t('done')}</span>
                        <span className="yg-headline-label-light-xs-black">{this.state.done?.length}&nbsp;{t('chore')}</span>
                    </div>
                    {
                        this.state.done?.map((i, k) => {
                            return (
                                <div key={k} className="yg-expenses-form-div-details-secondary-green">
                                    <div className="yg-radio-form-chores">
                                        <div className="yg-radio-form-sa">
                                            <label className="yg-check-green">
                                                <input type="checkbox" checked />
                                                <span className="checkmark-green"></span>
                                            </label>
                                            <span className="yg-headline-label-light-xs-green">
                                                {i?.name}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="pound-gray">£</span>
                                            <span className="yg-headline-label-light-s-green">
                                                {i?.completedChoresAmount}
                                            </span>
                                            <span className="pound-gray">
                                                /{i?.totalChoresAmount}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="yg-cc-pb-top pt-4">
                        <span className="yg-headline-label-light-xs-black">{t('earnings')}</span>
                        <span className="yg-headline-label-light-xs-black"><span className="pound-gray">£</span>14</span>
                    </div>
                    <div>
                        <div className="yg-password-form-div">
                            {
                                this.state.earned?.map((i, k) => {
                                    return (
                                        <div
                                            key={k}
                                            className={this.props.user.userData.attributes["custom:color"] === "2" ? "yg-expenses-form-div-details-white-primary" : "yg-expenses-form-div-details-secondary-white"}
                                        >
                                            <div className="yg-radio-form-chores">
                                                <span className={"yg-headline-label-light-xs-blue"}>
                                                    {i?.name}
                                                </span>
                                                <div>
                                                    <span className="pound-gray">£</span>
                                                    <span className={"yg-headline-label-light-s-blue"}>
                                                        {i?.amount}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        completeChoresRequest: (body) => dispatch(ChildActions.completeChoresRequest(body)),
        choresListRequest: (body) => dispatch(CommonActions.choresListRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        child: state.child,
        common: state.common,
        user: state.user
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Chores)
