import React, { Component } from 'react'
import Modal from 'react-modal'
import cancel from '../../../svg/cancel.svg'
import Select from 'react-select'
import profile_pic from '../../../svg/profile_pic.svg'

export default class TransferChild extends Component {
    constructor(props) {
        super(props)
        this.state = {
            amount: "5",
            setAll: false,
            otherValue: 0,
            selectedOption: null,
            childrenList: []
        }
    }

    componentDidMount() {
        if (this.props.child === undefined) {
            this.setState({
                childrenList: this.props.childrenList
            })
        }
    }

    handleCloseModal = () => {
        this.setState({ setAll: false })
    }

    onAmountChange = (e) => {
        this.setState({ amount: e.target.id })
    }

    onOtherChange = (e) => {
        this.setState({ otherValue: e.target.value })
    }

    onChangeSelectedChild = (child) => {
        this.setState({ selectedOption: child })
    }

    render() {
        const { t } = this.props

        let childrenOptions = this.state.childrenList?.map(i => ({
            picture: i.picture,
            name: i.name,
            balance: i.balance.available
        }))

        const singleChildCard = ({ picture, name, balance }) => (
            <div className="yg-card-head-transfer yg-modal-child mt-2">
                <div>
                    <img src={picture === undefined ? profile_pic : picture} alt="Avatar" className="yg-card-avatar-min" />
                    <span className="yg-card-header pl-3">{name}</span>
                </div>
                <div className="yg-balance-header">
                    <span className="yg-balance-headername-min">{t('balance')}</span>
                    <div>
                        <span className="yg-card-alwn-costs-q-xs">£</span>
                        <span className="yg-card-number-min">{balance === undefined ? 0 : balance}</span>
                    </div>
                </div>
            </div>
        )

        const customStyles = {
            menu: () => ({
                background: 'linear-gradient(90deg, #effaff 35%, #fff1ea 100%)',
                marginTop: '5px',
                border: '1px #E1E2E6 solid',
                paddingBottom: '8px'
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                borderBottom: '1px #E1E2E6 solid',
                cursor: 'pointer',
                marginBottom: '-8px',
                backgroundColor: isFocused
                    ? '#E1E2E6'
                    : undefined
            })
        }

        return (
            <Modal
                closeTimeoutMS={50}
                isOpen={this.props.transferToChildModal}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                <div>
                    <span className="yg-greeting-name">{t('transfer')}</span>
                    {
                        this.props.child !== undefined ? null :
                            <div className="yg-password-form-div pt-5">
                                <label htmlFor="yg-gender" className="yg-headline-label-light-xs-black">{t('select_child')}</label>
                                <Select
                                    defaultValue={childrenOptions === undefined ? null : childrenOptions[0]}
                                    onChange={this.onChangeSelectedChild}
                                    options={childrenOptions}
                                    formatOptionLabel={singleChildCard}
                                    isSearchable={false}
                                    styles={customStyles}
                                />
                            </div>
                    }
                    <div className="yg-password-form-div">
                        <label htmlFor="yg-gender" className="yg-headline-label-light-xs-black">{t('amount')}</label>
                        <div className="yg-gradient">
                            <input type="radio" id="5" name="amount" className="rb-input" checked={this.state.amount === "5"} onChange={this.onAmountChange} />
                            <label htmlFor="5" className="rb-label-dashboard"><span className="pound-gray">£</span>5</label>
                            <input type="radio" id="10" name="amount" className="rb-input" checked={this.state.amount === "10"} onChange={this.onAmountChange} />
                            <label htmlFor="10" className="rb-label-dashboard"><span className="pound-gray">£</span>10</label>
                            <input type="radio" id="15" name="amount" className="rb-input" checked={this.state.amount === "15"} onChange={this.onAmountChange} />
                            <label htmlFor="15" className="rb-label-dashboard"><span className="pound-gray">£</span>15</label>
                            <input type="radio" id="other" name="amount" className="rb-input" checked={this.state.amount === "other"} onChange={this.onAmountChange} />
                            <label htmlFor="other" className="rb-label-dashboard">{t('other')}</label>
                        </div>
                    </div>
                    {
                        this.state.amount === "other" ?
                            <div className="yg-password-form-div">
                                <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('other_amount')}</label>
                                <div className="yg-radio-form-sa pt-2">
                                    <div className="yg-input-other w-100">
                                        <span className="yg-card-number-small-time">£</span>
                                        <input
                                            type="number"
                                            className="rb-input-text-black p-10-5"
                                            onChange={this.onOtherChange} />
                                    </div>
                                </div>
                            </div> : null
                    }
                    <div className="yg-btn-form" style={{ display: 'flex' }}>
                        <button
                            type="button"
                            className="btn yg-btn yg-btn-black"
                            disabled={this.state.amount === "other" ? (this.state.otherValue <= 0 ? true : false) : false}
                        >
                            {t('save')}
                        </button>
                        <button
                            type="button"
                            className="btn yg-btn yg-btn-gray"
                            onClick={this.props.handleCloseModal}
                        >
                            {t('cancel')}
                        </button>
                    </div>
                </div>
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </Modal>
        )
    }
}