import React, { Component } from 'react'
import Modal from 'react-modal'
import cancel from '../../../svg/cancel.svg'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Range, getTrackBackground } from 'react-range'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ChildActions from '../../../reducers/ChildRedux'
import { ToastContainer, toast } from 'react-toastify'

class Budget extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDetailedExpensesModal: "",
            budgetPercentage: 0,
            values: [0],
            maxVal: 0,
            dailyBudgetIsSet: false
        }
    }

    componentDidMount() {
        this.props.getDailyBudgetRequest()
        this.setState({
            budgetPercentage: this.props.child?.dailyBudget?.expensesAmount,
            values: [this.props.child?.dailyBudget?.expensesAmount],
            maxVal: this.props.child?.dailyBudget?.maxBudget
        })
    }

    componentDidUpdate() {
        if (this.props.child.setDailyBudgetResponse !== null && this.state.dailyBudgetIsSet) {
            if (this.props.child.setDailyBudgetResponse.success !== false || this.props.child.setDailyBudgetResponse.success !== undefined || this.props.child.setDailyBudgetResponse.success !== null) {
                toast.success('Daily Budget is set!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(() => {
                    this.setState({
                        dailyBudgetIsSet: false
                    })
                    this.props.handleCloseModal(true)
                }, 2000)
            }
        }
    }

    onShowDetailedExpenses = (e) => {
        this.setState({
            showDetailedExpensesModal: e.target.innerText
        })
    }

    onSliderChange = (values) => {
        this.setState({
            values: values,
            budgetPercentage: values.toString()
        })
    }

    onSetDailyBudget = () => {
        this.props.setDailyBudgetRequest({
            budget: Number(this.state.budgetPercentage)
        })
        this.setState({
            dailyBudgetIsSet: true
        })
    }

    render() {
        const { t } = this.props
        const MIN = 0
        const MAX = this.state.maxVal

        return (
            <Modal
                isOpen={this.props.showBudgetModal}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                <div className={window.innerWidth < 769 ? "w-90" : "w-80"}>
                    <span className="yg-greeting-name">{t('daily_budget')}</span>
                    <div className="pt-4 expenses-with-chart">
                        <div className="expenses-chart-budget-child">
                            <CircularProgressbarWithChildren
                                value={this.state.budgetPercentage}
                                maxValue={this.state.maxVal}
                                styles={buildStyles({
                                    pathColor: "#03A6F7",
                                    trailColor: "#CBEEFF"
                                })}>
                                <div>
                                    <span className="yg-card-number-min-gray">£</span>
                                    <span className="yg-card-number-blue">{this.state.budgetPercentage}</span>
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                    </div>
                    <div className="form-label yg-topnav-mb-logo py-5">
                        <Range
                            values={this.state.values}
                            min={MIN}
                            max={MAX}
                            onChange={(values) => this.onSliderChange(values)}
                            renderTrack={({ props, children }) => (
                                <div
                                    onMouseDown={props.onMouseDown}
                                    onTouchStart={props.onTouchStart}
                                    style={{
                                        ...props.style,
                                        display: "flex",
                                        width: "100%"
                                    }}
                                >
                                    <div
                                        ref={props.ref}
                                        style={{
                                            height: "8px",
                                            width: "100%",
                                            borderRadius: "5px",
                                            background: getTrackBackground({
                                                values: this.state.values,
                                                colors: ["#03a6f7", "#CBEEFF"],
                                                min: MIN,
                                                max: MAX
                                            }),
                                            alignSelf: "center"
                                        }}
                                    >
                                        {children}
                                    </div>
                                </div>
                            )}
                            renderThumb={({ props, isDragged }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: "22px",
                                        width: "22px",
                                        borderRadius: "100%",
                                        backgroundColor: "#03a6f7",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        boxShadow: "0px 2px 6px #AAA"
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="yg-password-form-div ta-c">
                        <span className="yg-headline-label-dark-xs">{t('you_can_save')}</span>
                        <span className="yg-blue-20">£{(this.state.maxVal - this.state.budgetPercentage).toString().substring(0, 3)}</span>
                        <span className="yg-headline-label-dark-xs">{t('if_you_follow_this_daily_budget')}</span>
                    </div>
                    <div className="yg-btn-form" style={{ display: 'flex' }}>
                        <button
                            type="button"
                            className="btn yg-btn yg-btn-black"
                            onClick={this.onSetDailyBudget}
                        >
                            {t('save')}
                        </button>
                        <button
                            type="button"
                            className="btn yg-btn yg-btn-gray"
                            onClick={this.props.handleCloseModal}
                        >
                            {t('cancel')}
                        </button>
                    </div>
                </div>
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
                <ToastContainer
                    position="bottom-left"
                    className="toast-container-mobile"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDailyBudgetRequest: (body) => dispatch(ChildActions.setDailyBudgetRequest(body)),
        getDailyBudgetRequest: () => dispatch(ChildActions.getDailyBudgetRequest())
    }
}
const mapStateToProps = (state) => {
    return {
        child: state.child
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Budget))