import React from 'react'
import SignUp from "./pages/signup.js"
import SignIn from "./pages/signin.js"
import Dashboard from "./pages/dashboard.js"
import { BankChildElement } from "./pages/bank-child-element"
import ConnectBankSuccess from "./pages/connect-bank-success.js"
import Loader from "./components/helpers/loader"
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom"
import { Provider } from 'react-redux'
import { reduxStore, persistor } from '../src/reducers'
import { PersistGate } from 'redux-persist/integration/react'
import { useEffect } from 'react'
import Amplify from "aws-amplify"
import awsExportsDev from './aws-exports-dev'
import { Hub } from 'aws-amplify'

const listener = (data) => {
    switch (data.payload.event) {
        case 'signIn':
            console.log('user signed in')
            break
        case 'signUp':
            console.log('user signed up')
            break
        case 'signOut':
            console.log('user signed out')
            break
        case 'signIn_failure':
            console.log('user sign in failed')
            break
        case 'tokenRefresh':
            console.log('token refresh succeeded')
            break
        case 'tokenRefresh_failure':
            console.log('token refresh failed')
            break
        case 'configured':
            console.log('the Auth module is configured')
    }
}

export default function App() {

    useEffect(() => {
        Hub.listen('auth', listener)
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            Amplify.configure({
                Auth: {
                    region: awsExportsDev.aws_cognito_region,
                    userPoolId: awsExportsDev.aws_user_pools_id,
                    userPoolWebClientId: awsExportsDev.aws_user_pools_web_client_id,
                    identityPoolId: awsExportsDev.aws_cognito_identity_pool_id,
                    identityPoolRegion: awsExportsDev.aws_cognito_region
                },
                API: {
                    endpoints: [
                        {
                            name: awsExportsDev.aws_cloud_logic_custom[0].name,
                            endpoint: awsExportsDev.aws_cloud_logic_custom[0].endpoint,
                            service: awsExportsDev.aws_cloud_logic_custom[0].service,
                            region: awsExportsDev.aws_cloud_logic_custom[0].region
                        }
                    ]
                },
                Storage: {
                    AWSS3: {
                        bucket: awsExportsDev.aws_user_files_s3_bucket,
                        region: awsExportsDev.aws_user_files_s3_bucket_region
                    }
                }
            })
            console.log('I am in dev')
        } else {
            //Amplify.configure(awsExportsProd)
            Amplify.configure({
                Auth: {
                    region: 'eu-west-2',
                    userPoolId: 'eu-west-2_l4VqGnWGS',
                    userPoolWebClientId: '75emjrhqr1t0gv11dufdgi3vvb',
                },
                API: {
                    endpoints: [
                        {
                            name: "YounGoMoneyAPI",
                            endpoint: " https://ke3lvm5dpi.execute-api.eu-west-2.amazonaws.com/dev",
                            service: "lambda",
                            region: "eu-west-2"
                        }
                    ]
                }
            })
            console.log = function () { }
        }
    }, [])

    return (
        <Provider store={reduxStore}>
            <PersistGate persistor={persistor} loading={null}>
                <React.StrictMode>
                    <Loader />
                    <Router>
                        <Switch>
                            <Route exact path="/">
                                <SignIn />
                            </Route>
                            <Route exact path="/signup">
                                <SignUp />
                            </Route>
                            <Route exact path="/signin">
                                <SignIn />
                            </Route>
                            <Route path="/parent-dashboard">
                                <Dashboard />
                            </Route>
                            <Route path="/child-dashboard">
                                <Dashboard />
                            </Route>
                            <Route path="/bank-child-element">
                                <BankChildElement />
                            </Route>
                            <Route path="/redirect-page">
                                <ConnectBankSuccess />
                            </Route>
                        </Switch>
                    </Router>
                </React.StrictMode>
            </PersistGate>
        </Provider>
    )
}