import React, { Component } from 'react'
import setAllowance from '../../../svg/illustrations/set_allowance.svg'
import SetAllowance from './set-allowance'

export default class SetAllowanceBefore extends Component {

    constructor(props) {
        super(props);
        this.state = {
            set_allowance: false
        }
    }

    onSelect = () => {
        if (this.state.set_allowance) {
            this.setState({
                set_allowance: false
            })
        } else if (!this.state.set_allowance) {
            this.setState({
                set_allowance: true
            })
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                {
                    !this.state.set_allowance ?
                        <div className="code-form">
                            <img src={setAllowance} alt="Set Allowance" className="yg-parentdb-nochildimg" />
                            <div className="form-group yg-form">
                                <span className="yg-codelogin-headline-main">Lorem ipsum dolor sit amet, consetet</span>
                                <div className="yg-codelogin-form">
                                    <label htmlFor="yg-password" className="yg-headline-label">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor</label>
                                </div>
                                <div className="yg-btn-form">
                                    <button
                                        type="button"
                                        onClick={this.onSelect}
                                        className="btn yg-btn yg-btn-black"
                                    >
                                        {t('set_allowance')}
                                    </button>
                                </div>
                            </div>
                        </div> :
                        <SetAllowance t={t} handleGoToNextStep={this.props.handleGoToNextStep} childData={this.props.childData}/>
                }
            </>
        );
    }
}