import React, { Component } from 'react'
import Modal from 'react-modal'
import cancel from '../../../svg/cancel.svg'
import calendar from '../../../svg/calendar.svg'
import transfer_green from '../../../svg/transfer_green.svg'
import { Chart } from 'react-charts'
import { connect } from 'react-redux'
import CommonActions from '../../../reducers/CommonRedux'
import {
    AreaChart,
    GridlineSeries,
    Gridline,
    AreaSeries,
    PointSeries,
    LinearXAxis,
    LinearYAxis,
    LinearXAxisTickSeries,
    Line,
    Area,
    Gradient,
    GradientStop,
    LinearYAxisTickSeries,
    LinearYAxisTickLabel,
    LinearXAxisTickLabel
} from 'reaviz'

class Goals extends Component {
    constructor(props) {
        super(props)
        this.state = {
            savingAccountModal: false,
            changeGoalNameValue: false,
            goals_info: {}
        }
    }

    componentDidMount() {
        this.props.goalsListRequest({
            child_sub: this.props.user.userData.attributes.sub
        })
        this.setState({
            goals_info: this.props.common?.goalsListResponse?.data
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedOption !== this.props.selectedOption) {
            this.props.goalsListRequest({
                child_sub: this.props.user.userData.attributes.sub
            })
            setTimeout(() => {
                this.setState({
                    goals_info: this.props.common?.goalsListResponse?.data
                })
            }, 500)
        }
    }

    onShowSavingAccount = (e) => {
        this.setState({
            savingAccountModal: true
        })
    }

    render() {
        const { t } = this.props
        let child = this.props.child

        const data_values = [
            {
                key: 0,
                data: 5
            },
            {
                key: 5,
                data: 10
            },
            {
                key: 10,
                data: 14
            },
            {
                key: 15,
                data: 5
            },
            {
                key: 20,
                data: 18
            }
        ]

        return (
            <Modal
                isOpen={this.props.showGoalsModal}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                <div className={window.innerWidth < 769 ? "w-90" : "w-80"}>
                    <span className="yg-greeting-name">{this.state.savingAccountModal ? t('saving_account') : t('goals')}</span>
                    {
                        this.state.savingAccountModal ?
                            <>
                                <div className="yg-expenses-form-div mt-4">
                                    <img src={calendar} alt="Calendar" className="expenses-svg" />
                                    <span className="yg-headline-label-light-xs">Last 6 weeks</span>
                                </div>
                                <div className="mt-4">
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '120px'
                                        }}
                                    >
                                        <AreaChart
                                            gridlines={<GridlineSeries line={<Gridline direction="none" />} />}
                                            data={data_values}
                                            yAxis={
                                                <LinearYAxis
                                                    axisLine={null}
                                                    tickSeries={
                                                        <LinearYAxisTickSeries
                                                            line={null}
                                                            label={null}
                                                        />
                                                    }
                                                />
                                            }
                                            xAxis={
                                                <LinearXAxis
                                                    axisLine={null}
                                                    tickSeries={
                                                        <LinearXAxisTickSeries
                                                            line={null}
                                                            label={null}
                                                        />
                                                    }
                                                />
                                            }
                                            series={
                                                <AreaSeries
                                                    colorScheme={'#03A6F7'}
                                                    symbols={<PointSeries show={true} />}
                                                    area={
                                                        <Area
                                                            gradient={
                                                                <Gradient
                                                                    stops={[
                                                                        <GradientStop offset="30%" stopOpacity={0} />,
                                                                        <GradientStop offset="95%" stopOpacity={1} />
                                                                    ]}
                                                                />
                                                            }
                                                        />
                                                    }
                                                    line={<Line strokeWidth={1} />}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="yg-carddtls-savingaccount mt-3">
                                    <div className="yg-carddtls-expdtls">
                                        <span className="yg-headline-label-dark py-2">{t('total_savings')}</span>
                                        <div className="yg-balance-header">
                                            <div>
                                                <span className="yg-card-alwn-costs-q-xs">£</span>
                                                <span className="yg-card-number-min">12</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yg-carddtls-expdtls">
                                        <div className="yg-carddtls-exprow">
                                            <div className="yg-balance-header-left">
                                                <span className="yg-cc-topname yg-balance-headername-min">12 September</span>
                                                <span className="yg-card-number-small-time">09:30</span>
                                            </div>
                                        </div>
                                        <div className="yg-balance-header">
                                            <div>
                                                <span className="pound-gray">£</span>
                                                <span className="yg-headline-label-dark">5</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="yg-carddtls-expdtls">
                                        <div className="yg-carddtls-exprow">
                                            <div className="yg-balance-header-left">
                                                <span className="yg-cc-topname yg-balance-headername-min">5 September</span>
                                                <span className="yg-card-number-small-time">04:30</span>
                                            </div>
                                        </div>
                                        <div className="yg-balance-header">
                                            <div>
                                                <span className="pound-gray">£</span>
                                                <span className="yg-headline-label-dark">3.50</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> :
                            <>
                                <button className="card-button-goals pt-3" onClick={this.onShowSavingAccount}>
                                    <div className="goals-chart mt-3">
                                        <div className="goals-lowtitle-title">
                                            <span className="yg-headline-label-light-xs-black">{t('saving_account')}</span>
                                            <div className="yg-goals-right">
                                                <span className="yg-headline-label-light-xxs-black-neutral">{t('balance')}</span>
                                                <div>
                                                    <span className="yg-card-alwn-costs-q-xs">£</span>
                                                    <span className="yg-card-number-min">{this.state.goals_info?.data?.savingAccount?.data?.available}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '120px'
                                            }}
                                        >
                                            <AreaChart
                                                gridlines={<GridlineSeries line={<Gridline direction="none" />} />}
                                                data={data_values}
                                                yAxis={
                                                    <LinearYAxis
                                                        axisLine={null}
                                                        tickSeries={
                                                            <LinearYAxisTickSeries
                                                                line={null}
                                                                label={null}
                                                            />
                                                        }
                                                    />
                                                }
                                                xAxis={
                                                    <LinearXAxis
                                                        axisLine={null}
                                                        tickSeries={
                                                            <LinearXAxisTickSeries
                                                                line={null}
                                                                label={null}
                                                            />
                                                        }
                                                    />
                                                }
                                                series={
                                                    <AreaSeries
                                                        colorScheme={'#03A6F7'}
                                                        symbols={<PointSeries show={true} />}
                                                        area={
                                                            <Area
                                                                gradient={
                                                                    <Gradient
                                                                        stops={[
                                                                            <GradientStop offset="30%" stopOpacity={0} />,
                                                                            <GradientStop offset="95%" stopOpacity={1} />
                                                                        ]}
                                                                    />
                                                                }
                                                            />
                                                        }
                                                        line={<Line strokeWidth={1} />}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </button>
                                <div className="goals-lowtitle">
                                    <span className="yg-headline-label-light-xs-blue">{t('share_equally')}</span>
                                    <span className="pound-gray">{t('you_can_share_your_untargeted_savings')}</span>
                                </div>
                                <div className="yg-gradient untrg-savings">
                                    <span className="yg-headline-label-light-xs-black">{t('untargeted_savings')}</span>
                                    <div>
                                        <span className="pound-gray">£</span>
                                        <span className="yg-headline-label-dark">{this.state.goals_info?.data?.undistributedAmount}</span>
                                    </div>
                                </div>
                                {
                                    this.state.goals_info?.data?.goalList?.map((i, k) => {
                                        return (
                                            <button key={k} className="card-button-goals" onClick={this.onEditGoal}>
                                                <div className="card yg-selfcard-green">
                                                    <div className="yg-card-allowance-blue">
                                                        <span className="yg-headline-label-light-xs-black">{i.name}</span>
                                                        <img src={transfer_green} alt="Transfer" className="expenses-svg-left" />
                                                    </div>
                                                    <div className="yg-cc-pb-selfcard">
                                                        <div className="progress yg-pb-green-selfcard">
                                                            <div
                                                                className="progress-bar yg-pbb-green"
                                                                role="progressbar"
                                                                style={{ width: i.percentage }}
                                                                aria-valuenow="25"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100" />
                                                        </div>
                                                        <div className="yg-selfcard-costs">
                                                            <span className="pound-gray">£</span>
                                                            <span className="yg-headline-label-dark">{i.target}/{i.balance}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        )
                                    })
                                }
                            </>
                    }
                </div>
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        goalsListRequest: (body) => dispatch(CommonActions.goalsListRequest(body)),
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        common: state.common
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Goals)