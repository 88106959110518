import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setUserData: ['data'],
    clearRedux: []
});

export const UserTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    userData: null,
});

/* ------------- Reducers ------------- */
export const setUserData = (state, action) => {
    return state.merge({ userData: action.data })
}

export const clearRedux = (state, action) => {
    return state;
}

/* ------------- Connection Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_USER_DATA]: setUserData,
    [Types.CLEAR_REDUX]: clearRedux
})