import React, { Component } from 'react'
import logo from '../../svg/logo.svg'
import profile_pic from '../../svg/profile_pic.svg'
import profiles from '../../svg/profiles.svg'
import card from '../../svg/card.svg'
import transaction from '../../svg/transaction.svg'
import notification from '../../svg/notification.svg'
import subscription from '../../svg/subscription.svg'
import logout from '../../svg/logout.svg'
import ChildrenProfiles from '../modals/parent/children-profiles'
import BankAccounts from '../modals/parent/bank-accounts'
import BankAccountsChild from '../modals/child/bank-accounts'
import NotificationsChild from '../modals/child/notifications'
import ChildProfile from '../modals/child/profile'
import Notifications from '../modals/parent/notifications'
import Payments from '../modals/parent/payments'
import Profile from '../modals/parent/profile'
import { Redirect } from 'react-router'
import { Auth } from 'aws-amplify'
import { connect } from 'react-redux'
import UserActions from '../../reducers/UserRedux'
import ParentActions from '../../reducers/ParentRedux'
import { Storage } from 'aws-amplify'

class Navmenu extends Component {

    constructor(props) {
        super(props)
        this.state = {
            avatarObject: {},
            avatarUrl: "",
            // Parent Nav Menu
            showProfiles: false,
            showBankAccounts: false,
            showPayments: false,
            showNotifications: false,
            showSubscription: false,
            showParentProfile: false,
            parentName: "",
            // Child Nav Menu
            showBankAccountsChild: false,
            showNotificationsChild: false,
            showSubscriptionChild: false,
            showChildProfile: false,
            // Dashboard
            showDashboard: false,
            showFirstAddChild: true,
            newProfilePhotoChanged: false
        }
    }

    componentDidMount() {
        Storage.list('profile_pictures/')
            .then(result => {
                result.map(i => {
                    if (i.key.includes(this.props.user?.userData?.attributes.sub)) {
                        this.setState({
                            avatarObject: i
                        })
                    }
                })
                this.onDownloadImage(this.state.avatarObject)
            })
            .catch(err => console.log(err))

        this.setState({
            parentName: this.props.user?.userData?.attributes?.name
        })
    }

    handleChangeProfilePhoto = (newProfilePhoto) => {
        this.setState({
            avatarObject: newProfilePhoto,
            newProfilePhotoChanged: true
        })
        setTimeout(() => {
            this.onDownloadImage(this.state.avatarObject)
        }, 500)
    }

    onDownloadImage = async (avatarObject) => {
        if (this.state.newProfilePhotoChanged) {
            await Storage.get('profile_pictures/' + avatarObject.name).then((data) => {
                this.setState({
                    avatarUrl: data,
                    newProfilePhotoChanged: false
                })
            })
        } else {
            await Storage.get(avatarObject.key).then((data) => {
                this.setState({
                    avatarUrl: data
                })
            })
        }
    }

    componentDidUpdate() {
        if (this.props.openAddChildModal && this.state.showFirstAddChild) {
            this.setState({
                showProfiles: true,
                showBankAccounts: false,
                showPayments: false,
                showNotifications: false,
                showSubscription: false,
                showFirstAddChild: false
            })
        }
    }

    // Parent Nav Menu
    onShowProfiles = () => {
        this.setState({
            showProfiles: true,
            showBankAccounts: false,
            showPayments: false,
            showNotifications: false,
            showSubscription: false
        })
    }

    onShowBankAccounts = () => {
        this.setState({
            showBankAccounts: true,
            showProfiles: false,
            showPayments: false,
            showNotifications: false,
            showSubscription: false
        })
    }

    onShowPayments = () => {
        this.setState({
            showPayments: true,
            showProfiles: false,
            showBankAccounts: false,
            showNotifications: false,
            showSubscription: false
        })
    }

    onShowNotifications = () => {
        this.setState({
            showNotifications: true,
            showProfiles: false,
            showBankAccounts: false,
            showPayments: false,
            showSubscription: false
        })
    }

    onShowSubscription = () => {
        this.setState({
            showSubscription: true,
            showProfiles: false,
            showBankAccounts: false,
            showPayments: false,
            showNotifications: false
        })
    }

    onShowParentProfile = () => {
        this.setState({
            showProfiles: false,
            showBankAccounts: false,
            showPayments: false,
            showNotifications: false,
            showSubscription: false,
            showParentProfile: true
        })
    }

    // Child Nav Menu
    onShowBankAccountsChild = () => {
        this.setState({
            showBankAccountsChild: true,
            showNotificationsChild: false,
            showSubscriptionChild: false
        })
    }

    onShowNotificationsChild = () => {
        this.setState({
            showBankAccountsChild: false,
            showNotificationsChild: true,
            showSubscriptionChild: false
        })
    }

    onShowChildProfile = () => {
        this.setState({
            showBankAccountsChild: false,
            showNotificationsChild: false,
            showSubscriptionChild: false,
            showChildProfile: true
        })
    }

    onShowDashboard = () => {
        this.setState({
            showDashboard: true
        })
    }

    handleCloseModal = () => {
        this.setState({
            // Parent Nav Menu
            showProfiles: false,
            showBankAccounts: false,
            showPayments: false,
            showNotifications: false,
            showSubscription: false,
            showParentProfile: false,
            // Child Nav Menu
            showBankAccountsChild: false,
            showNotificationsChild: false,
            showSubscriptionChild: false,
            showChildProfile: false
        })
        this.props.handleShowChildProfile(false)
        this.props.handleShowParentProfile(false)
    }

    handleCloseModalOnAdd = (childAdded) => {
        if (childAdded) {
            this.setState({
                // Parent Nav Menu
                showProfiles: false,
                showBankAccounts: false,
                showPayments: false,
                showNotifications: false,
                showSubscription: false,
                showParentProfile: false,
                // Child Nav Menu
                showBankAccountsChild: false,
                showNotificationsChild: false,
                showSubscriptionChild: false,
                showChildProfile: false
            })
            this.props.handleShowChildProfile(false)
            this.props.handleShowParentProfile(false)
        }
    }

    handleShowChildProfile = (showChildProfile) => {
        this.props.handleShowChildProfile(showChildProfile)
    }

    handleShowParentProfile = (showParentProfile) => {
        this.props.handleShowParentProfile(showParentProfile)
    }

    onSignOut = async () => {
        try {
            await Auth.signOut()
            this.setState({
                redirectToSignIn: true
            })
        } catch (error) {
            console.log('Error signing out => ', error)
        }
    }

    handleSendChangedName = (parentName) => {
        this.setState({
            parentName: parentName
        })
    }

    render() {
        const { t } = this.props

        return (
            <>
                {
                    this.state.redirectToSignIn ?
                        <Redirect push to="/signin" /> :
                        <>
                            {/* Parent Nav Menu */}
                            {
                                this.state.showProfiles ?
                                    <ChildrenProfiles
                                        t={t}
                                        handleCloseModal={this.handleCloseModal}
                                        handleCloseModalOnAdd={this.handleCloseModalOnAdd}
                                        showProfiles={this.state.showProfiles}
                                        handleShowChildProfile={this.handleShowChildProfile}
                                    /> : null
                            }
                            {
                                this.state.showBankAccounts ?
                                    <BankAccounts
                                        t={t}
                                        handleCloseModal={this.handleCloseModal}
                                        showBankAccounts={this.state.showBankAccounts}
                                    /> : null
                            }
                            {
                                this.state.showPayments ?
                                    <Payments
                                        t={t}
                                        handleCloseModal={this.handleCloseModal}
                                        showPayments={this.state.showPayments}
                                    /> : null
                            }
                            {
                                this.state.showNotifications ?
                                    <Notifications
                                        t={t}
                                        handleCloseModal={this.handleCloseModal}
                                        showNotifications={this.state.showNotifications}
                                    /> : null
                            }
                            {
                                this.state.showParentProfile ?
                                    <Profile
                                        t={t}
                                        handleCloseModal={this.handleCloseModal}
                                        showParentProfile={this.state.showParentProfile}
                                        handleShowParentProfile={this.handleShowParentProfile}
                                        handleSendChangedName={this.handleSendChangedName}
                                        handleChangeProfilePhoto={this.handleChangeProfilePhoto}
                                    /> : null
                            }
                            {/* Child Nav Menu */}
                            {
                                this.state.showBankAccountsChild ?
                                    <BankAccountsChild
                                        t={t}
                                        handleCloseModal={this.handleCloseModal}
                                        showBankAccountsChild={this.state.showBankAccountsChild}
                                    /> : null
                            }
                            {
                                this.state.showNotificationsChild ?
                                    <NotificationsChild
                                        t={t}
                                        handleCloseModal={this.handleCloseModal}
                                        showNotificationsChild={this.state.showNotificationsChild}
                                    /> : null
                            }
                            {
                                this.state.showChildProfile ?
                                    <ChildProfile
                                        t={t}
                                        handleCloseModal={this.handleCloseModal}
                                        showChildProfile={this.state.showChildProfile}
                                        handleChangeProfilePhoto={this.handleChangeProfilePhoto}
                                    /> : null
                            }
                            {
                                this.state.showDashboard ?
                                    window.location.href = '/parent-dashboard' : null
                            }
                            <div className="yg-nav-bar">
                                <button onClick={this.onShowDashboard} className="navmenu-button">
                                    <img src={logo} alt="Logo" className="navbar-mb-logo" />
                                </button>
                                {
                                    this.props.role === "parent" ?
                                        <>
                                            <div className={window.innerWidth > 769 ? "navbar-top-desktop" : "navbar-top-mobile"}>
                                                <button onClick={this.onShowProfiles} className="navmenu-button">
                                                    <img className={this.state.showProfiles ? "svg-orange" : "navbar-icon"} src={profiles} alt="Profiles" />
                                                    <span className={this.state.showProfiles ? "yg-headline-label-light-xxs-orange pl-2" : "yg-headline-label-light-xs pl-2"}>{t('children')}</span>
                                                </button>
                                                <button onClick={this.onShowBankAccounts} className="navmenu-button">
                                                    <img className={this.state.showBankAccounts ? "svg-orange" : "navbar-icon"} src={card} alt="Card" />
                                                    <span className={this.state.showBankAccounts ? "yg-headline-label-light-xxs-orange pl-2" : "yg-headline-label-light-xs pl-2"}>{t('bank_account')}</span>
                                                </button>
                                                <button onClick={this.onShowPayments} className="navmenu-button">
                                                    <img className={this.state.showPayments ? "svg-orange" : "navbar-icon"} src={transaction} alt="Transaction" />
                                                    <span className={this.state.showPayments ? "yg-headline-label-light-xxs-orange pl-2" : "yg-headline-label-light-xs pl-2"}>{t('payments')}</span>
                                                </button>
                                                <button onClick={this.onShowNotifications} className="navmenu-button">
                                                    <img className={this.state.showNotifications ? "svg-orange" : "navbar-icon"} src={notification} alt="Notification" />
                                                    <span className={this.state.showNotifications ? "yg-headline-label-light-xxs-orange pl-2" : "yg-headline-label-light-xs pl-2"}>{t('notifications')}</span>
                                                </button>
                                                <button onClick={this.onShowSubscription} className="navmenu-button">
                                                    <img className={this.state.showSubscription ? "svg-orange" : "navbar-icon"} src={subscription} alt="Subscription" />
                                                    <span className={this.state.showSubscription ? "yg-headline-label-light-xxs-orange pl-2" : "yg-headline-label-light-xs pl-2"}>{t('subscription')}</span>
                                                </button>
                                            </div>
                                            <div className={window.innerWidth > 769 ? "navbar-top-desktop" : "navbar-top-mobile"}>
                                                <button onClick={this.onShowParentProfile} className="navmenu-button">
                                                    <img src={this.state.avatarUrl === undefined || this.state.avatarUrl.includes("public/undefined") ? profile_pic : this.state.avatarUrl} alt="Avatar" className="yg-navmenu-avatar" />
                                                    <span className={this.state.showParentProfile ? "yg-headline-label-light-xxs-orange pl-2" : "yg-headline-label-light-xs pl-2"}>{this.state.parentName}</span>
                                                </button>
                                                <button onClick={this.onSignOut} className="navmenu-button">
                                                    <img className="navbar-icon" src={logout} alt="Logout" />
                                                    <span className="yg-headline-label-light-xs pl-2">{t('log_out')}</span>
                                                </button>
                                            </div>
                                        </> :
                                        this.props.role === "child" ?
                                            <>
                                                <div className={window.innerWidth > 769 ? "navbar-top-desktop" : "navbar-top-mobile"}>
                                                    <button onClick={this.onShowBankAccountsChild} className="navmenu-button">
                                                        <img className={this.state.showBankAccountsChild ? "svg-orange" : "navbar-icon"} src={card} alt="Card" />
                                                        <span className={this.state.showBankAccountsChild ? "yg-headline-label-light-xxs-orange pl-2" : "yg-headline-label-light-xs pl-2"}>{t('bank_account')}</span>
                                                    </button>
                                                    <button onClick={this.onShowNotificationsChild} className="navmenu-button">
                                                        <img className={this.state.showNotificationsChild ? "svg-orange" : "navbar-icon"} src={notification} alt="Notification" />
                                                        <span className={this.state.showNotificationsChild ? "yg-headline-label-light-xxs-orange pl-2" : "yg-headline-label-light-xs pl-2"}>{t('notifications')}</span>
                                                    </button>
                                                </div>
                                                <div className={window.innerWidth > 769 ? "navbar-top-desktop" : "navbar-top-mobile"}>
                                                    <button onClick={this.onShowChildProfile} className="navmenu-button">
                                                        <img src={this.state.avatarUrl === undefined || this.state.avatarUrl.includes("public/undefined") ? profile_pic : this.state.avatarUrl} alt="Avatar" className="yg-navmenu-avatar" />
                                                        <span className={this.state.showChildProfile ? "yg-headline-label-light-xxs-orange pl-2" : "yg-headline-label-light-xs pl-2"}>{this.props.user?.userData?.attributes?.name}</span>
                                                    </button>
                                                    <button onClick={this.onSignOut} className="navmenu-button">
                                                        <img className="navbar-icon" src={logout} alt="Logout" />
                                                        <span className="yg-headline-label-light-xs pl-2">{t('log_out')}</span>
                                                    </button>
                                                </div>
                                            </> : null
                                }
                            </div>
                        </>
                }
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(UserActions.clearRedux()),
        getParentDashboardRequest: () => dispatch(ParentActions.getParentDashboardRequest())
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        parent: state.parent
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Navmenu)