import React, { Component } from 'react'
import Countdown from 'react-countdown'
import phone from '../../../svg/illustrations/phone.svg'
import email from '../../../svg/illustrations/email.svg'
import { AutoTabProvider } from 'react-auto-tab'
import { Auth } from 'aws-amplify'
import { ToastContainer, toast } from 'react-toastify'
import { connect } from 'react-redux'
import VerificationActions from '../../../reducers/VerificationRedux'

const countRenderer = ({ minutes, seconds }) => {
    return (
        <span className="yg-card-number-small-time">{minutes}:{seconds}</span>
    )
}
const CountdownWrapper = () => <Countdown date={Date.now() + 60000} zeroPadTime={2} renderer={countRenderer} />
const MemoCountdown = React.memo(CountdownWrapper)

class ChangeEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            mail_code_1: '',
            mail_code_2: '',
            mail_code_3: '',
            mail_code_4: '',
            mail_code_5: '',
            mail_code_6: '',
            phone_code_1: '',
            phone_code_2: '',
            phone_code_3: '',
            phone_code_4: '',
            phone_code_5: '',
            phone_code_6: '',
            showNewEmailModal: false,
            showChangeEmailModal: false,
            email: ''
        }
    }

    componentDidMount() {
        this.getPhoneCode()
    }

    getPhoneCode = async () => {
        const session = await Auth.currentSession();
        this.props.getCodeRequest({
            access_token: session.getAccessToken().getJwtToken(),
            type: "phone_number"
        })
    }

    // Mail Validation
    updateCode1ValueMail = (e) => {
        this.setState({
            mail_code_1: e.target.value
        });
    };
    updateCode2ValueMail = (e) => {
        this.setState({
            mail_code_2: e.target.value
        });
    };
    updateCode3ValueMail = (e) => {
        this.setState({
            mail_code_3: e.target.value
        });
    };
    updateCode4ValueMail = (e) => {
        this.setState({
            mail_code_4: e.target.value
        });
    };
    updateCode5ValueMail = (e) => {
        this.setState({
            mail_code_5: e.target.value
        });
    };
    updateCode6ValueMail = (e) => {
        this.setState({
            mail_code_6: e.target.value
        });
    };
    // Phone Validation
    updateCode1ValuePhone = (e) => {
        this.setState({
            phone_code_1: e.target.value
        });
    };
    updateCode2ValuePhone = (e) => {
        this.setState({
            phone_code_2: e.target.value
        });
    };
    updateCode3ValuePhone = (e) => {
        this.setState({
            phone_code_3: e.target.value
        });
    };
    updateCode4ValuePhone = (e) => {
        this.setState({
            phone_code_4: e.target.value
        });
    };
    updateCode5ValuePhone = (e) => {
        this.setState({
            phone_code_5: e.target.value
        });
    };
    updateCode6ValuePhone = (e) => {
        this.setState({
            phone_code_6: e.target.value
        });
    };

    // 2nd step
    onConfirmPhone = async () => {
        let finalCode = this.state.phone_code_1 + this.state.phone_code_2 + this.state.phone_code_3 +
            this.state.phone_code_4 + this.state.phone_code_5 + this.state.phone_code_6

        try {
            await Auth.verifyCurrentUserAttributeSubmit("phone_number", finalCode);
            toast.success('Phone successfully confirmed!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            setTimeout(() => {
                this.setState({
                    showNewEmailModal: true
                });
            }, 1500);
        } catch (error) {
            toast.error('Provided code is wrong!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    // 3rd step
    onChangeEmailCognito = async () => {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
            email: this.state.email
        });
        this.setState({
            showChangeEmailModal: true,
            showNewEmailModal: false
        });
    }

    onInputEmailChange = (event) => {
        this.setState({
            email: event.target.value
        })
    };

    // 4th step
    onConfirmNewEmail = async () => {
        const session = await Auth.currentSession();
        this.props.getCodeRequest({
            access_token: session.getAccessToken().getJwtToken(),
            type: "email"
        })

        let finalCode = this.state.mail_code_1 + this.state.mail_code_2 + this.state.mail_code_3 +
            this.state.mail_code_4 + this.state.mail_code_5 + this.state.mail_code_6

        try {
            await Auth.verifyCurrentUserAttributeSubmit("email", finalCode);
            toast.success('Email successfully confirmed!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            setTimeout(() => {
                this.props.handleCloseModal(true)
            }, 1500);
        } catch (error) {
            toast.error('Provided code is wrong!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    render() {
        const { t } = this.props;

        return (
            <>
                {
                    this.state.showNewEmailModal ?
                        <div className="w-80">
                            <span className="yg-greeting-parent-name">{t('new_email')}</span>
                            <div className="change-email-modal-form">
                                <img src={email} alt="Email" className="yg-parentdb-nochildimg w-80" />
                                <div className="form-group yg-form-100">
                                    <label htmlFor="yg-email" className="yg-headline-label-dark w-70">{t('new_email')}</label>
                                    <div className="yg-password-form">
                                        <input
                                            placeholder={t('placeholder_email')}
                                            name="email"
                                            type="text"
                                            className="form-control yg-form-input"
                                            id="yg-email"
                                            value={this.state.email}
                                            onChange={this.onInputEmailChange}
                                        />
                                    </div>
                                    <div className="yg-btn-form">
                                        <button
                                            type="button"
                                            onClick={this.onChangeEmailCognito}
                                            className="btn yg-btn yg-btn-black"
                                        >
                                            {t('change')}
                                        </button>
                                    </div>
                                    <div className="yg-notreceivedform">
                                        <span>{t('didnt_receive_the_code')}</span>
                                        <a href="/#" className="yg-codeform-link">{t('receive')}</a>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        this.state.showChangeEmailModal ?
                            <div className="w-80">
                                <span className="yg-greeting-parent-name">{t('change_email')}</span>
                                <div className="change-email-modal-form">
                                    <img src={email} alt="Email" className="yg-parentdb-nochildimg" />
                                    <div className="form-group yg-form-100">
                                        <span className="yg-codelogin-headline-main">{t('check_your_email')}</span>
                                        <div className="yg-codelogin-form">
                                            <label htmlFor="yg-password" className="yg-headline-label">{t('enter_six_digit_code_mail')}</label>
                                            <AutoTabProvider className="yg-code-partitioned-div-s">
                                                <input
                                                    className={this.state.mail_code_1.length > 0 ? "yg-code-partitioned-cem" : "yg-code-partitioned-cem-light"}
                                                    name="code"
                                                    type="text"
                                                    maxLength={1}
                                                    tabbable="true"
                                                    value={this.state.mail_code_1}
                                                    onChange={this.updateCode1ValueMail} />
                                                <input
                                                    className={this.state.mail_code_2.length > 0 ? "yg-code-partitioned-cem" : "yg-code-partitioned-cem-light"}
                                                    name="code"
                                                    type="text"
                                                    maxLength={1}
                                                    tabbable="true"
                                                    value={this.state.mail_code_2}
                                                    onChange={this.updateCode2ValueMail} />
                                                <input
                                                    className={this.state.mail_code_3.length > 0 ? "yg-code-partitioned-cem" : "yg-code-partitioned-cem-light"}
                                                    name="code"
                                                    type="text"
                                                    maxLength={1}
                                                    tabbable="true"
                                                    value={this.state.mail_code_3}
                                                    onChange={this.updateCode3ValueMail} />
                                                <input
                                                    className={this.state.mail_code_4.length > 0 ? "yg-code-partitioned-cem" : "yg-code-partitioned-cem-light"}
                                                    name="code"
                                                    type="text"
                                                    maxLength={1}
                                                    tabbable="true"
                                                    value={this.state.mail_code_4}
                                                    onChange={this.updateCode4ValueMail} />
                                                <input
                                                    className={this.state.mail_code_5.length > 0 ? "yg-code-partitioned-cem" : "yg-code-partitioned-cem-light"}
                                                    name="code"
                                                    type="text"
                                                    maxLength={1}
                                                    tabbable="true"
                                                    value={this.state.mail_code_5}
                                                    onChange={this.updateCode5ValueMail} />
                                                <input
                                                    className={this.state.mail_code_6.length > 0 ? "yg-code-partitioned-cem" : "yg-code-partitioned-cem-light"}
                                                    name="code"
                                                    type="text"
                                                    maxLength={1}
                                                    tabbable="true"
                                                    value={this.state.mail_code_6}
                                                    onChange={this.updateCode6ValueMail} />
                                            </AutoTabProvider>
                                            <MemoCountdown />
                                        </div>
                                        <div className="yg-btn-form">
                                            <button
                                                type="button"
                                                className="btn yg-btn yg-btn-black"
                                                disabled={this.state.mail_code_6.length === 0 ? true : false}
                                                onClick={this.onConfirmNewEmail}
                                            >
                                                {t('send')}
                                            </button>
                                        </div>
                                        <div className="yg-notreceivedform">
                                            <span>{t('didnt_receive_the_code')}</span>
                                            <a href="/#" className="yg-codeform-link">{t('receive')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div className="w-80">
                                <span className="yg-greeting-parent-name">{t('change_email')}</span>
                                <div className="change-email-modal-form">
                                    <img src={phone} alt="Phone" className="yg-parentdb-nochildimg" />
                                    <div className="form-group yg-form-100">
                                        <span className="yg-codelogin-headline-main">{t('check_your_phone')}</span>
                                        <div className="yg-codelogin-form">
                                            <label htmlFor="yg-password" className="yg-headline-label">{t('enter_six_digit_code_phone')}</label>
                                            <AutoTabProvider className="yg-code-partitioned-div-s">
                                                <input
                                                    className={this.state.phone_code_1.length > 0 ? "yg-code-partitioned-cem" : "yg-code-partitioned-cem-light"}
                                                    name="code"
                                                    type="text"
                                                    maxLength={1}
                                                    value={this.state.phone_code_1}
                                                    onChange={this.updateCode1ValuePhone}
                                                    tabbable="true"
                                                    onPaste={(e) => {
                                                        setTimeout(() => {
                                                            this.updateCode1ValuePhone(e)
                                                        }, 100);
                                                    }}
                                                />
                                                <input
                                                    className={this.state.phone_code_2.length > 0 ? "yg-code-partitioned-cem" : "yg-code-partitioned-cem-light"}
                                                    name="code"
                                                    type="text"
                                                    maxLength={1}
                                                    value={this.state.phone_code_2}
                                                    onChange={this.updateCode2ValuePhone}
                                                    tabbable="true"
                                                    onPaste={(e) => {
                                                        setTimeout(() => {
                                                            this.updateCode2ValuePhone(e)
                                                        }, 100);
                                                    }}
                                                />
                                                <input
                                                    className={this.state.phone_code_3.length > 0 ? "yg-code-partitioned-cem" : "yg-code-partitioned-cem-light"}
                                                    name="code"
                                                    type="text"
                                                    maxLength={1}
                                                    value={this.state.phone_code_3}
                                                    onChange={this.updateCode3ValuePhone}
                                                    tabbable="true"
                                                    onPaste={(e) => {
                                                        setTimeout(() => {
                                                            this.updateCode3ValuePhone(e)
                                                        }, 100);
                                                    }}
                                                />
                                                <input
                                                    className={this.state.phone_code_4.length > 0 ? "yg-code-partitioned-cem" : "yg-code-partitioned-cem-light"}
                                                    name="code"
                                                    type="text"
                                                    maxLength={1}
                                                    value={this.state.phone_code_4}
                                                    onChange={this.updateCode4ValuePhone}
                                                    tabbable="true"
                                                    onPaste={(e) => {
                                                        setTimeout(() => {
                                                            this.updateCode4ValuePhone(e)
                                                        }, 100);
                                                    }}
                                                />
                                                <input
                                                    className={this.state.phone_code_5.length > 0 ? "yg-code-partitioned-cem" : "yg-code-partitioned-cem-light"}
                                                    name="code"
                                                    type="text"
                                                    maxLength={1}
                                                    value={this.state.phone_code_5}
                                                    onChange={this.updateCode5ValuePhone}
                                                    tabbable="true"
                                                    onPaste={(e) => {
                                                        setTimeout(() => {
                                                            this.updateCode5ValuePhone(e)
                                                        }, 100);
                                                    }}
                                                />
                                                <input
                                                    className={this.state.phone_code_6.length > 0 ? "yg-code-partitioned-cem" : "yg-code-partitioned-cem-light"}
                                                    name="code"
                                                    type="text"
                                                    maxLength={1}
                                                    value={this.state.phone_code_6}
                                                    onChange={this.updateCode6ValuePhone}
                                                    tabbable="true"
                                                    onPaste={(e) => {
                                                        setTimeout(() => {
                                                            this.updateCode6ValuePhone(e)
                                                        }, 100);
                                                    }}
                                                />
                                            </AutoTabProvider>
                                            <MemoCountdown />
                                        </div>
                                        <div className="yg-btn-form">
                                            <button
                                                type="button"
                                                onClick={this.onConfirmPhone}
                                                className="btn yg-btn yg-btn-black"
                                                disabled={this.state.phone_code_6.length === 0 ? true : false}
                                            >
                                                {t('send')}
                                            </button>
                                        </div>
                                        <div className="yg-notreceivedform">
                                            <span>{t('didnt_receive_the_code')}</span>
                                            <a href="/#" className="yg-codeform-link">{t('receive')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                }
                <ToastContainer
                    position="bottom-left"
                    className="toast-container-mobile"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCodeRequest: (body) => dispatch(VerificationActions.getCodeRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        verification: state.verification,
        user: state.user
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail)