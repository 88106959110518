import { API } from 'aws-amplify'

const create = () => {

    // GET METHODS

    const getParentDashboard = (token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            response: true
        }
        return API.get("YounGoMoneyAPI", "/parent/dashboard", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    const getChildList = (token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            response: true
        }
        return API.get("YounGoMoneyAPI", "/parent/childList", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    // POST METHODS

    const addChild = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/addchild", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return { 'error': true }
            })
    }

    const setAllowance = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/setAllowance", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    // CHORES

    const editChore = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/editChores", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    const addChores = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/addChores", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    const deleteChore = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/deleteChore", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    // GOALS

    const addGoal = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/addGoal", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    const editGoal = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/editGoal", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    const deleteGoal = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/deleteGoal", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    // DELETE METHODS

    const deleteChild = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            //body: body,
            response: true
        }
        return API.del("YounGoMoneyAPI", "/parent/child/" + body.childId, requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    return {
        getParentDashboard,
        getChildList,
        addChild,
        setAllowance,
        addChores,
        editChore,
        deleteChore,
        addGoal,
        editGoal,
        deleteGoal,
        deleteChild
    }
}

export default {
    create
}