import { API } from 'aws-amplify'

const create = () => {

    const setDailyBudget = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/child/setDailyBudget", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error)
                return error
            })
    }

    const getDailyBudget = (token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            response: true
        };
        return API.get("YounGoMoneyAPI", "/child/getDailyBudget", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error)
                return error
            })
    }

    const getChildDashboard = (token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            response: true
        };
        return API.get("YounGoMoneyAPI", "/child/dashboard", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error)
                return error
            })
    }

    // CHORES

    const completeChores = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        };
        return API.post("YounGoMoneyAPI", "/child/completeChores", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error)
                return error
            })
    }

    return {
        completeChores,
        setDailyBudget,
        getDailyBudget,
        getChildDashboard
    }
}

export default {
    create
}