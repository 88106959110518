import React, { Component } from 'react'
import start from '../../../svg/illustrations/start.svg'
import { Redirect } from 'react-router'

export default class LetsStart extends Component {

    constructor(props) {
        super(props)
        this.state = {
            redirectToDashboard: false
        }
    }

    onAddOtherChild = () => {
        this.props.goToAddOtherChildStep(true)
    }

    onGoToDashboard = () => {
        this.setState({
            redirectToDashboard: true
        })
    }

    render() {
        const { t } = this.props

        return (
            <>
                {
                    this.state.redirectToDashboard ?
                        <Redirect push to="/parent-dashboard" /> :
                        <div className="code-form">
                            <img src={start} alt="Let's Start" className="yg-parentdb-nochildimg" />
                            <div className="form-group yg-form">
                                <span className="yg-codelogin-headline-main">{t('child_login_info')}</span>
                                <div className="child-info-block mt-3">
                                    <div>
                                        {t('email')}:&nbsp;<span className='yg-headline-label-light-s-blue'>{this.props.childData?.child?.email}</span>
                                    </div>
                                    <div>
                                        {t('password')}:&nbsp;<span className='yg-headline-label-light-s-blue'>{this.props.childData?.temporaryPassword}</span>
                                    </div>
                                </div>
                                <div className="yg-btn-form-lets-start">
                                    <button
                                        type="button"
                                        onClick={() => this.onGoToDashboard()}
                                        className="btn yg-btn-thin yg-btn-black"
                                    >
                                        {t('lets_start')}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={this.onAddOtherChild}
                                        className="btn yg-btn-thin yg-btn-white"
                                    >
                                        {t('add_other_child')}
                                    </button>
                                </div>
                            </div>
                        </div>
                }
            </>
        )
    }
}